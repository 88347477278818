import { WRITE_REVIEW } from "@/constants";
import { TravelAdvisorDetails } from "@/types/travel-advisor-details";
import { Datum, TravelAdvisorReviews } from "@/types/travel-advisor-reviews";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, CardBody } from "@nextui-org/react";
import clsx from "clsx";
import Image from "next/image";
import Link, { default as NextLink } from "next/link";
import { Key, useEffect, useState } from "react";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Rating } from "../rating/rating";
import { ReviewItem } from "../review-item/review-item";

interface TripAdvisor {
  source: "tripadvisor";
  title: string;
  showDetails?: boolean;
  details?: any;
  reviews?: any;
  style?: "default" | "tripadvisor";
}

interface TravelAdvisor {
  source: "travel-advisor";
  title: string;
  showDetails?: boolean;
  details?: TravelAdvisorDetails | any;
  reviews?: TravelAdvisorReviews | any;
  style?: "default" | "tripadvisor";
}

export type TripadvisorReviewsProps = TripAdvisor | TravelAdvisor;

export function TripadvisorReviews({
  source = "tripadvisor",
  title,
  showDetails,
  details,
  reviews,
  style = "tripadvisor",
}: TripadvisorReviewsProps) {
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [numReviews, setNumReviews] = useState<number>(0);
  const [tripadvisorUrl, setTripadvisorUrl] = useState<string>("");
  const [writeReviewUrl, setWriteReviewUrl] = useState<string>("");
  const [reviewsData, setReviewsData] = useState<Datum[] | any>([]);

  useEffect(() => {
    if (source === "travel-advisor") {
      setName(details?.name);
      setLocation(details?.location_string);
      setAddress(details?.address);
      setRating(details?.rating);
      setNumReviews(details?.num_reviews);
      setTripadvisorUrl(details?.web_url);
      setWriteReviewUrl(details?.write_review);
      setReviewsData(reviews);
    }
  }, [details, reviews, source]);

  return (
    <section id="reviews" className="relative overflow-hidden py-8">
      <div className="container relative z-10 mb-10">
        <h2
          className="mb-8 text-2xl font-medium text-primary sm:text-3xl"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
          {showDetails && (
            <div className="sm:col-span-2">
              <div className="mb-10">
                <div className="mb-6">
                  <h3 className="text-xl font-semibold text-black sm:text-2xl">
                    {name} - {location}
                  </h3>
                  <MapPinIcon className="mr-1 inline-block h-4 w-4" />
                  <span className="text-sm">{address}</span>
                </div>
                <div className="mb-6 flex items-center gap-3">
                  <span className="text-lg font-semibold">
                    {rating?.toFixed(1)}
                  </span>
                  <Rating
                    variant="tripadvisor"
                    rating={rating}
                    numReviews={numReviews}
                    link={tripadvisorUrl}
                  />
                </div>
                <Image
                  alt="Tripadvisor Logo"
                  src="/assets/images/tripadvisor-logo.svg"
                  width="200"
                  height="40"
                />
              </div>
              <Button
                as={NextLink}
                href={writeReviewUrl || "#"}
                target="_blank"
                rel="noreferrer"
                color="primary"
                size="lg"
              >
                {WRITE_REVIEW}
              </Button>
            </div>
          )}
          {reviewsData && (
            <div
              className={clsx({
                "sm:col-span-12": !showDetails,
                "sm:col-span-4": showDetails,
              })}
            >
              <div className="relative z-20 hidden md:block">
                <div className="relative mb-4 flex items-start justify-end">
                  <div className="flex gap-1">
                    <Button
                      radius="full"
                      variant="bordered"
                      className={"swiper-button-prev-reviews h-10 w-10 min-w-max"}
                      aria-label="Prev"
                      size="sm"
                    >
                      <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      radius="full"
                      variant="bordered"
                      className={"swiper-button-next-reviews h-10 w-10 min-w-max"}
                      aria-label="Next"
                      size="sm"
                    >
                      <ChevronRightIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
              <Swiper
                className="-mt-12 !py-12"
                navigation={{
                  nextEl: ".swiper-button-next-reviews",
                  prevEl: ".swiper-button-prev-reviews",
                }}
                modules={[Pagination, Navigation]}
                pagination
                spaceBetween={16}
                slidesPerView={1.25}
                breakpoints={{
                  768: {
                    slidesPerView: !showDetails ? 3.5 : 2.5,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                  },
                }}
                slidesOffsetBefore={15}
                slidesOffsetAfter={15}
              >
                {reviewsData.data?.map(
                  (review: { id: Key | null | undefined }) => (
                    <SwiperSlide key={review?.id}>
                      <ReviewItem variant={style} review={review} />
                    </SwiperSlide>
                  ),
                )}
                {details?.web_url && (
                  <SwiperSlide>
                    <Card className="h-full ">
                      <CardBody className="flex items-center justify-center p-4">
                        <Link
                          href={`${details.web_url}#REVIEWS`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="border-primary text-sm uppercase text-primary underline"
                        >
                          See more reviews
                        </Link>
                      </CardBody>
                    </Card>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          )}
        </div>
      </div>

      <div className="absolute left-0 top-0 z-0 h-full w-full">
        <Image
          src="/assets/images/background-map.svg"
          width="1920"
          height="1080"
          alt="background-map"
          className="h-full object-cover"
        />
      </div>
    </section>
  );
}

export default TripadvisorReviews;
