import { GetAllTransportsQuery } from "@/__generated__/graphql";
import {
  ProductItem,
  ProductItemLoader,
  ProductListItem,
  ProductListItemLoader,
} from "@/components/elements";
import { settings } from "@/config";
import { LOAD_MORE } from "@/constants";
import { queries } from "@/graphql/queries";
import { useGlobalStore } from "@/stores/global";
import { TSortby, TaxArray, TaxOperator } from "@/types";
import { useQuery } from "@apollo/client";
import { Button } from "@nextui-org/react";
import clsx from "clsx";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { ProductFilter } from "../product-filter";
import {
  NoMoreResults,
  NoResults,
  ProductList,
  handleSortby,
} from "../product-list";
import { ProductSorting } from "../product-sorting";

export function ProductTransportList({
  sortby,
  order,
  setOrder,
  setSortby,
  defaultDestination,
}: Readonly<ProductList>) {
  const searchParams = useSearchParams();
  const viewMode = useGlobalStore((state) => state.viewMode);

  const variables = {
    first: settings.postPerPage,
    after: "",
    before: "",
    search: "",
    taxArray: [] as TaxArray[],
    orderby: { field: sortby, order: order },
    priceRange: `${queries.transports.PRICE_RANGE}`,
  };

  if (defaultDestination) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "DESTINATIONCATEGORY",
      terms: [defaultDestination],
      operator: TaxOperator.IN,
    });
  }

  const destinations = searchParams.get("destinations");

  if (destinations) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "DESTINATIONCATEGORY",
      terms: destinations.split(","),
      operator: TaxOperator.IN,
    });
  }

  const prices = searchParams.get("prices");

  if (prices) {
    const [minPrice, maxPrice] = prices.split(",").map(Number);

    variables.priceRange = `${minPrice},${maxPrice}`;

    if (maxPrice >= 7000) {
      variables.priceRange = `${minPrice},${queries.tours.PRICE_RANGE[1]}`;
    }
  }

  const { loading, data, refetch, fetchMore } = useQuery<GetAllTransportsQuery>(
    queries.transports.QUERY_ALL_TRANSPORTS,
    {
      variables,
      notifyOnNetworkStatusChange: true,
    },
  );

  const { nodes: transports, pageInfo } = data?.transports ?? {};

  /**
   * Handles the "Load More" functionality in the product list.
   * Fetches more data from the server and updates the list with the new data.
   */
  function handleLoadMore() {
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.transports?.nodes?.length) {
          return prev;
        }

        const prevData = prev?.transports?.nodes ?? [];

        return {
          transports: {
            ...fetchMoreResult.transports,
            nodes: [...prevData, ...fetchMoreResult.transports.nodes],
          },
        };
      },
    });
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      useGlobalStore.setState({ viewMode: "grid" });
    }
  }, []);

  return (
    <div id="product-list" className="container my-10">
      <div className="grid md:grid-cols-12 md:gap-10">
        <div className="relative md:col-span-3">
          <ProductFilter
            type="transport"
            destinations={destinations?.split(",")}
            prices={prices?.split(",").map(Number) as [number, number]}
          />
        </div>
        <div className="md:col-span-9">
          <ProductSorting
            value={sortby}
            total={pageInfo?.total || 0}
            onChange={(e) =>
              handleSortby(
                e.target.value as TSortby,
                order,
                setOrder,
                setSortby,
                refetch,
              )
            }
          />

          <div
            className={clsx("mb-10 grid gap-6", {
              "md:grid-cols-3": viewMode === "grid",
            })}
          >
            {loading && !data
              ? Array.from({ length: 10 }).map((_, index) => {
                  if (viewMode === "grid") {
                    return <ProductItemLoader key={index} />;
                  }
                  return <ProductListItemLoader key={index} />;
                })
              : data &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                transports?.map((transport: TransportFieldsFragment) => {
                  if (viewMode === "grid") {
                    return (
                      <ProductItem
                        key={transport.id}
                        id={transport.id}
                        type="transport"
                        title={transport.title as string}
                        slug={transport.slug as string}
                        featuredImage={{
                          id: transport.featuredImage?.node.id ?? "",
                          altText: transport.featuredImage?.node.altText ?? "",
                          sourceUrl:
                            transport.featuredImage?.node.sourceUrl ?? "",
                        }}
                        price={Number(transport.fields?.price)}
                        discountPrice={Number(transport.fields?.discountPrice)}
                        rating={Number(transport.fields?.rating)}
                        numReviews={Number(transport.fields?.numReviews)}
                      />
                    );
                  }

                  return (
                    <ProductListItem
                      key={transport.id}
                      type="transport"
                      id={transport.id}
                      title={transport.title as string}
                      slug={transport.slug as string}
                      featuredImage={{
                        id: transport.featuredImage?.node.id ?? "",
                        altText: transport.featuredImage?.node.altText ?? "",
                        sourceUrl:
                          transport.featuredImage?.node.sourceUrl ?? "",
                      }}
                      price={Number(transport.fields?.price)}
                      discountPrice={Number(transport.fields?.discountPrice)}
                      rating={Number(transport.fields?.rating)}
                      numReviews={Number(transport.fields?.numReviews)}
                      excerpt={
                        (transport.fields?.shortDescription as string) || ""
                      }
                      features={{
                        age: transport?.fields?.feature?.age || "",
                        capacity: Number(transport?.fields?.feature?.capacity),
                        duration: transport?.fields?.feature?.duration || "",
                        hotelPickup:
                          transport?.fields?.feature?.hotelPickup || false,
                        mobileVoucher:
                          transport?.fields?.feature?.mobileVoucher || false,
                      }}
                    />
                  );
                })}
          </div>
          {pageInfo?.hasNextPage && (
            <div className="text-center">
              <Button
                color="primary"
                onClick={() => handleLoadMore()}
                isLoading={loading}
              >
                {LOAD_MORE}
              </Button>
            </div>
          )}
          {Number(pageInfo?.total) > 0 && !pageInfo?.hasNextPage && (
            <NoMoreResults />
          )}
          {!loading && data && transports?.length === 0 && <NoResults />}
        </div>
      </div>
    </div>
  );
}

export default ProductTransportList;
