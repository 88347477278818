/* eslint-disable react/no-unknown-property */
import CookieConsentPopup from "@/components/cookie-consent-popup/cookie-consent-popup";
import HydrationZustand from "@/components/hydration-zustand /hydration-zustand";
import SkipNavigationLink from "@/components/skip-navigation-link/skip-navigation-link";
import "@/faust.config";
import "@/globalStylesheet.css";
import "@/styles/blocks.scss";
import "@/styles/main.scss";
import blocks from "@/wp-blocks";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { WordPressBlocksProvider } from "@faustwp/blocks";
import { FaustProvider } from "@faustwp/core";
// import "@faustwp/core/dist/css/toolbar.css";
import { NextUIProvider } from "@nextui-org/react";
import "@smastrom/react-rating/style.css";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import NextNProgress from "nextjs-progressbar";
import { Toaster } from "react-hot-toast";

if (process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-inter",
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <>
      <FaustProvider pageProps={pageProps}>
        <WordPressBlocksProvider
          config={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            blocks,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            theme: null,
          }}
        >
          <HydrationZustand>
            <NextUIProvider navigate={router.push}>
              <Head>
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg"
                  color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
              </Head>
              <style jsx global>{`
                :root {
                  --font-inter: ${inter.style.fontFamily};
                }
              `}</style>
              {process.env.NODE_ENV === "production" && (
                <>
                  <Script id="ms_clarity">
                    {`
                      (function(c,l,a,r,i,t,y){
                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                      })(window, document, "clarity", "script", "h0l15we93l");
                    `}
                  </Script>
                  <Script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=G-7QVQHVXS2V"
                  />
                  <Script id="google-analytics">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-7QVQHVXS2V');
                    `}
                  </Script>
                  <Script id="facebook-chat">
                    {`
                      var chatbox = document.getElementById('fb-customer-chat'); chatbox.setAttribute("page_id", "390246361474299"); chatbox.setAttribute("attribution", "biz_inbox");
                      window.fbAsyncInit = function() { FB.init({ xfbml : true, version : 'v7.0' }); }; (function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));
                    `}
                  </Script>
                </>
              )}
              <div id="fb-root" />
              <div id="fb-customer-chat" className="fb-customerchat" />
              <SkipNavigationLink />
              <NextNProgress height={4} color={"#ff5425"} />
              <Component {...pageProps} key={router.asPath} />
              <Toaster />
              <CookieConsentPopup />
            </NextUIProvider>
          </HydrationZustand>
        </WordPressBlocksProvider>
      </FaustProvider>
    </>
  );
}
