import {
  EmailField as EmailFieldType,
  FieldError,
} from "@/__generated__/graphql";
import { Input } from "@nextui-org/react";
import { useFormContext } from "react-hook-form";

export interface EmailFieldProps {
  field: EmailFieldType;
  fieldErrors: FieldError[];
}

export function EmailField({ field, fieldErrors }: EmailFieldProps) {
  const { id, type, label, description, isRequired, placeholder } = field;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputId = `field_${type}_${id}`,
    inputName = `field_${id}`,
    error = errors[inputName];

  return (
    <Input
      id={inputId}
      {...register(inputName, {
        required:
          (isRequired && `Please enter the ${label?.toLocaleLowerCase()}`) ||
          false,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Please enter a valid email address",
        },
      })}
      type="email"
      label={label}
      description={description || ""}
      placeholder={placeholder || ""}
      required={isRequired || false}
      isInvalid={fieldErrors.length > 0 || error !== undefined}
      errorMessage={
        fieldErrors.map((error) => error.message).join(", ") ||
        (error?.message as string)
      }
    />
  );
}

export default EmailField;
