import { useGlobalStore } from "@/stores/global";
import { HeartIcon } from "@heroicons/react/24/solid";
import { Button } from "@nextui-org/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export interface FavoriteButtonProps {
  id: string;
}

export function FavoriteButton({ id }: FavoriteButtonProps) {
  const [active, setActive] = useState<boolean>(false);
  const favorites = useGlobalStore((state) => state.favorites);

  useEffect(() => {
    setActive(favorites.includes(id));
  }, [favorites, id]);

  const handleFavorite = () => {
    if (active) {
      useGlobalStore.setState((state) => ({
        favorites: state.favorites.filter((item) => item !== id),
      }));
      toast.success("Removed from favorites");
    } else {
      useGlobalStore.setState((state) => ({
        favorites: [...state.favorites, id],
      }));
      toast.success("Added to favorites");
    }
  };

  return (
    <Button
      isIconOnly
      color="default"
      aria-label="Favorite"
      radius="full"
      variant="solid"
      className={clsx({
        "bg-primary": active,
        "bg-white": !active,
      })}
      onClick={handleFavorite}
    >
      <HeartIcon
        className={clsx("h-5 w-5", {
          "text-white": active,
          "text-gray-300": !active,
        })}
      />
    </Button>
  );
}

export default FavoriteButton;
