import { GetTourTagTaxonomyQuery, PostTypeSeo } from "@/__generated__/graphql";
import { Banner } from "@/components/banner/banner";
import { Layout } from "@/components/layout";
import { ProductList } from "@/components/product-list/product-list";
import { fragments } from "@/graphql/fragments";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";

const Component: FaustTemplate<GetTourTagTaxonomyQuery> = ({ data }) => {
  const tag =
      data?.nodeByUri?.__typename === "TourTag" ? data?.nodeByUri : null,
    seo = tag?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || ""));

  return (
    <>
      <Head>
        <title>{tag?.name}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo?.metaRobotsNoindex}, ${seo?.metaRobotsNofollow}`}
        />
        {seo?.metaKeywords && (
          <meta name="keywords" content={seo?.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <Banner
          title={`Tag: ${tag?.name}`}
          description="Explore the best things to do in Thailand"
          size="sm"
          showSearchBox={false}
        />
        <ProductList type="tour" tag={tag?.slug as string} />
      </Layout>
    </>
  );
};

Component.variables = (seedQuery, ctx) => {
  return {
    uri: seedQuery.uri,
  };
};

Component.query = gql`
  ${fragments.seo.TAXONOMY_SEO}
  query GetTourTagTaxonomy($uri: String!) {
    nodeByUri(uri: $uri) {
      archiveType: __typename
      ... on TourTag {
        id
        name
        slug
        description
        seo {
          ...TaxonomySeoFields
        }
      }
    }
  }
`;

export default Component;
