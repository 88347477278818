import Image from "next/image";
import sanitizeHtml from "sanitize-html";

export interface SimpleBannerProps {
  title: string;
  subTitle: string;
}

export function SimpleBanner({ title, subTitle }: SimpleBannerProps) {
  const newTitle = sanitizeHtml(title);

  return (
    <div className="relative aspect-[4/3] w-full rounded-lg bg-primary sm:aspect-[16/6]">
      <Image
        src="/assets/images/background-banner.jpg"
        className="h-full w-full rounded-lg object-cover object-center"
        alt={newTitle}
        width={1500}
        height={600}
        priority
      />
      <div className="absolute bottom-0 left-0 p-6 lg:bottom-10 lg:left-16">
        <h1
          className="outline-title mb-6 text-5xl font-medium uppercase leading-none text-primary/100 lg:text-[7em]"
          dangerouslySetInnerHTML={{ __html: newTitle }}
        />
        <span className="text-xl font-medium text-white lg:text-4xl">
          | {subTitle}
        </span>
      </div>
    </div>
  );
}

export default SimpleBanner;
