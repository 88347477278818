import { useEffect, useRef } from "react";

export type MarkerProps = {
  map: google.maps.Map;
  position:
    | google.maps.LatLng
    | google.maps.LatLngLiteral
    | google.maps.LatLngAltitudeLiteral
    | null;
  children?: React.ReactNode;
  onClick?: (marker: google.maps.marker.AdvancedMarkerElement) => void;
};

export function Marker({ map, children, position, onClick }: MarkerProps) {
  const markerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function init() {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        "marker",
      )) as google.maps.MarkerLibrary;

      const advancedMarker = new AdvancedMarkerElement({
        map,
        content: markerRef.current,
        position,
        zIndex: 25,
      });

      if (onClick) {
        advancedMarker.addListener("mouseover", () => {
          onClick(advancedMarker);
        });
        advancedMarker.addListener("click", () => {
          onClick(advancedMarker);
        });
      }
    }
    init();
  }, [map, children, position, onClick]);

  return <div ref={markerRef}>{children}</div>;
}

export default Marker;
