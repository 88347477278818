import {
  GetJobVacanciesPageQuery,
  JobVacancyFieldsFragment,
  PostTypeSeo,
} from "@/__generated__/graphql";
import {
  BreadCrumb,
  BreadCrumbItem,
  JobVacancyItem,
  SimpleBanner,
} from "@/components/elements";
import { Layout } from "@/components/layout";
import { settings } from "@/config";
import { fragments } from "@/graphql/fragments";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import { Divider, Link } from "@nextui-org/react";
import parse from "html-react-parser";
import Head from "next/head";

const Component: FaustTemplate<GetJobVacanciesPageQuery> = ({
  data,
  loading,
}) => {
  if (loading) {
    return <>Loading...</>;
  }

  const seo = data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    page = data?.page,
    jobVacancies = data?.jobVacancies?.nodes as JobVacancyFieldsFragment[];

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: data?.page?.title as string,
      href: `/${data?.page?.slug}`,
    },
  ];

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <article className="container mb-10">
          <BreadCrumb items={breadcrumb} />
          <SimpleBanner
            title="JOB <br/>VACANCIES"
            subTitle={page?.title as string}
          />
          <h3 className="mb-4 mt-10 text-2xl font-semibold text-primary">
            Why work with us?
          </h3>
          <p>
            We are a full passionate team who are working on a solid startup
            idea. We always work on what we believe in and have a passion on.
            Each of us works as an owner and will succeed as a team.
          </p>
          <h2 className="mb-4 mt-10 text-3xl font-semibold text-primary">
            Now Hiring
          </h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
            {jobVacancies?.map((item) => (
                <JobVacancyItem
                  key={item.id}
                  title={item.title as string}
                  slug={item.slug as string}
                  date={new Date(item.date as string)}
                  featuredImage={{
                    id: item.featuredImage?.node?.id || "",
                    altText: item.featuredImage?.node?.altText || "",
                    sourceUrl: item.featuredImage?.node?.sourceUrl || "",
                  }}
                  shortDescription={item.fields?.shortDescription as string}
                  salary={{
                    maximum: item.fields?.salary?.maximum as number,
                    minimum: item.fields?.salary?.minimum as number,
                  }}
                />
              ))}
          </div>
          <Divider className="my-10" />
          <p>
            To apply for an open position, please send your resume by email to{" "}
            <Link href={`mailto:${settings.contact.email}`} underline="always">
              {settings.contact.email}
            </Link>{" "}
            Must include a cover letter and your academic records.
          </p>
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.jobVacancies.JOB_VACANCY_FIELDS}
  ${fragments.seo.POST_TYPE_SEO}
  query GetJobVacanciesPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      slug
      seo {
        ...SeoFields
      }
    }
    jobVacancies(first: 100, where: { status: PUBLISH, hasPassword: false }) {
      nodes {
        ...JobVacancyFields
      }
    }
  }
`;

export default Component;
