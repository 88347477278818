import { GetTourFiltersQuery } from "@/__generated__/graphql";
import { queries } from "@/graphql/queries";
import { ProductType } from "@/types";
import { useQuery } from "@apollo/client";
import { FunnelIcon } from "@heroicons/react/24/outline";

import {
  Button,
  Checkbox,
  CheckboxGroup,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Slider,
  SliderValue,
  useDisclosure,
} from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { CheckboxTree } from "../checkbox-tree/checkbox-tree";

import {
  CLEAR,
  CLEAR_SELECTION,
  DEFAULT_PRICE_RANGE,
  FILTER,
  PRICE_RANGE,
  RESET,
} from "@/constants";
import { priceFormat } from "@/utils";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { ProductFilterLoader } from "./product-filter-loader";
import { scrollToTop } from "./product-list";

export type FilterValues = {
  destinations?: string[];
  categories?: string[];
  priceRange?: [number, number];
};

export interface ProductFilterProps {
  type: ProductType;
  hideDestinations?: boolean;
  hideCategories?: boolean;
  keyword?: string;
  destinations?: string[];
  categories?: string[];
  prices?: [number, number];
}

export function ProductFilter({
  type,
  hideDestinations = false,
  hideCategories = false,
  keyword,
  destinations,
  categories,
  prices,
}: ProductFilterProps) {
  const router = useRouter();

  const [selectedDestinations, setSelectedDestinations] = useState<string[]>(
    destinations || [],
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    categories || [],
  );
  const [priceRange, setPriceRange] = useState<SliderValue>(
    prices || DEFAULT_PRICE_RANGE,
  );

  useEffect(() => {
    if (destinations) {
      setSelectedDestinations(destinations);
    }
  }, [destinations]);

  useEffect(() => {
    if (categories) {
      setSelectedCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (prices) {
      setPriceRange(prices);
    }
  }, [prices]);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const handleOnSubmit = useCallback(() => {
    const query: string | null | ParsedUrlQueryInput | undefined = {};

    if (keyword) {
      query.keyword = keyword;
    }

    if (selectedDestinations.length > 0) {
      query.destinations = selectedDestinations.join(",");
    }

    if (selectedCategories.length > 0) {
      query.categories = selectedCategories.join(",");
    }

    if (Array.isArray(priceRange)) {
      if (
        priceRange[0] !== DEFAULT_PRICE_RANGE[0] ||
        priceRange[1] !== DEFAULT_PRICE_RANGE[1]
      ) {
        query.prices = priceRange.join(",");
      }
    }

    const pathname = window.location.pathname; // prevent the dynamic route not found error

    router.push(
      {
        pathname: pathname,
        href: router.asPath,
        query: query,
      },
      undefined,
      { shallow: true },
    );
    onClose();
    scrollToTop();
  }, [
    keyword,
    onClose,
    priceRange,
    router,
    selectedCategories,
    selectedDestinations,
  ]);

  const clearFilters = useCallback(() => {
    setSelectedDestinations([]);
    setSelectedCategories([]);
    setPriceRange(DEFAULT_PRICE_RANGE);
  }, []);

  const query =
    type === "tour"
      ? queries.tours.QUERY_TOUR_FILTERS
      : queries.transports.QUERY_TRANSPORT_FILTERS;

  const { loading, error, data } = useQuery<GetTourFiltersQuery>(query);

  if (error)
    return (
      <span className="text-sm text-red-600">
        Somethings went wrong, please try again later
      </span>
    );

  const filteredTourCategories =
    data?.tourCategories &&
    data.tourCategories.nodes.filter((category) => category.parentId === null);

  return (
    <>
      <div className="sticky top-0 hidden md:block">
        <h2 className="mb-6 text-lg font-semibold text-black/60">Filters</h2>
        <FilterContent
          data={data}
          loading={loading}
          hideDestinations={hideDestinations}
          hideCategories={hideCategories}
          selectedDestinations={selectedDestinations}
          setSelectedDestinations={setSelectedDestinations}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
          filteredTourCategories={filteredTourCategories}
        />
        <div className="mt-10 flex justify-end gap-2">
          <Button color="danger" variant="bordered" onPress={clearFilters}>
            {CLEAR}
          </Button>
          <Button color="primary" onPress={handleOnSubmit}>
            {FILTER}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 z-20 block w-full bg-primary md:hidden">
        <Button className="w-full" color="primary" onPress={onOpen}>
          <FunnelIcon className="h-6 w-6" />
          Filters
        </Button>
        <Modal isOpen={isOpen} placement="bottom" onOpenChange={onOpenChange}>
          <ModalContent>
            <ModalHeader className="flex flex-col gap-1">Filters</ModalHeader>
            <ModalBody className="max-h-[60vh] overflow-auto">
              <FilterContent
                data={data}
                loading={loading}
                hideDestinations={hideDestinations}
                hideCategories={hideCategories}
                selectedDestinations={selectedDestinations}
                setSelectedDestinations={setSelectedDestinations}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                priceRange={priceRange}
                setPriceRange={(value) => setPriceRange(value)}
                filteredTourCategories={filteredTourCategories}
              />
            </ModalBody>
            <ModalFooter className="grid grid-cols-2 gap-2">
              <Button color="danger" variant="bordered" onPress={clearFilters}>
                {CLEAR}
              </Button>
              <Button color="primary" onPress={handleOnSubmit}>
                {FILTER}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}

const FilterContent = ({
  loading,
  data,
  hideDestinations,
  hideCategories,
  selectedDestinations,
  setSelectedDestinations,
  selectedCategories,
  setSelectedCategories,
  priceRange,
  setPriceRange,
  filteredTourCategories,
}: {
  loading: boolean;
  data: GetTourFiltersQuery | undefined;
  hideDestinations: boolean;
  hideCategories: boolean;
  selectedDestinations: string[];
  setSelectedDestinations: (value: string[]) => void;
  selectedCategories: string[];
  setSelectedCategories: (value: string[]) => void;
  priceRange: SliderValue;
  setPriceRange: (value: SliderValue) => void;
  filteredTourCategories: any;
}) => {
  if (loading && !data) {
    return <ProductFilterLoader />;
  }

  return (
    <div className="grid grid-cols-1 gap-8">
      {hideDestinations !== true && (
        <CheckboxGroup
          label="Destinations"
          value={selectedDestinations}
          onValueChange={setSelectedDestinations}
          className="relative z-10"
          classNames={{
            label: "mb-2 font-semibold",
          }}
        >
          {selectedDestinations && selectedDestinations.length > 0 && (
            <Link
              className="absolute right-0 top-0 text-sm"
              onPress={() => setSelectedDestinations([])}
            >
              {CLEAR_SELECTION}
            </Link>
          )}
          {data?.destinationCategories?.nodes?.map((destination) => (
            <Checkbox
              key={destination?.slug}
              value={destination?.slug as string}
            >
              {destination?.name}
            </Checkbox>
          ))}
        </CheckboxGroup>
      )}
      {filteredTourCategories && !hideCategories && (
        <CheckboxTree
          label="Categories"
          value={selectedCategories}
          onValueChange={setSelectedCategories}
          items={
            filteredTourCategories?.map(
              (category: {
                id: string;
                name: string;
                slug: string;
                children: { nodes: any[] };
              }) => ({
                key: category?.id as string,
                label: category?.name as string,
                value: category?.slug as string,
                children: category?.children?.nodes?.map((child) => ({
                  key: child?.id as string,
                  label: child?.name as string,
                  value: child?.slug as string,
                  disabled: Number(child?.count) <= 0 || child?.count === null,
                  children: [],
                })),
              }),
            ) as CheckboxTree[]
          }
        />
      )}
      <div className="relative">
        <label
          htmlFor="price-range"
          className="mb-5 block font-semibold text-foreground-500"
        >
          {PRICE_RANGE}
        </label>

        {priceRange && (
          <Link
            className="absolute right-0 top-0 text-sm"
            onPress={() => setPriceRange(DEFAULT_PRICE_RANGE)}
          >
            {RESET}
          </Link>
        )}
        <Slider
          aria-label="Price range"
          aria-labelledby="price-range"
          step={50}
          minValue={DEFAULT_PRICE_RANGE[0]}
          maxValue={DEFAULT_PRICE_RANGE[1]}
          formatOptions={{ style: "currency", currency: "THB" }}
          className="max-w-md"
          size="sm"
          showTooltip
          tooltipValueFormatOptions={{ style: "currency", currency: "THB" }}
          value={priceRange}
          onChange={setPriceRange}
        />
        <div className="mt-2 flex items-center gap-2 text-sm text-gray-600">
          <span>{Array.isArray(priceRange) && priceFormat(priceRange[0])}</span>
          <span>-</span>
          <span>
            {Array.isArray(priceRange) && priceFormat(priceRange[1])}
            {Array.isArray(priceRange) && priceRange[1] >= 7000 && (
              <span className="text-gray-600">+ </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
