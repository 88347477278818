/**
 * Parses a JSON string by replacing all occurrences of "\\u" with "u" before parsing.
 *
 * @param data - The input string that contains the JSON data.
 * @returns The parsed JSON object obtained from the input string.
 *
 * @example
 * const data = '{"name": "John", "age": 30}';
 * const parsedData = jsonParse(data);
 * console.log(parsedData);
 * // Output: { name: 'John', age: 30 }
 */

export function jsonParse(data: string) {
  let newData = "";

  try {
    if (data) {
      newData = data.replace(/\\u/g, "u");
      newData = JSON.parse(newData);
    }
  } catch (e) {
    console.log(e);
  }

  return newData;
}
