import { FeaturedImage } from "@/types/featured-image";
import Image from "next/image";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import { settings } from "@/config";
import { useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

export interface ProductGalleryProps {
  images: FeaturedImage[];
}
export function ProductGallery({ images }: ProductGalleryProps) {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

  if (!images) return <></>;

  return (
    <>
      <Swiper
        className="-my-8 !py-8"
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides
        loop={false}
        navigation
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {images.map((image, index) => (
          <SwiperSlide key={image.id} className="!h-auto">
            {image && (
              <Image
                src={image.sourceUrl || settings.imagePlaceholder.sourceUrl}
                alt={image.altText || settings.imagePlaceholder.altText}
                width={1000}
                height={251}
                className="relative m-0 aspect-[4/3] h-full w-full select-none overflow-hidden rounded-xl object-cover"
                priority={index == 0}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        className="mt-2"
        onSwiper={setThumbsSwiper}
        loop={false}
        spaceBetween={6}
        slidesPerView={4.25}
        modules={[FreeMode, Thumbs]}
        freeMode
        watchSlidesProgress
        breakpoints={{
          768: {
            slidesPerView: 8,
            spaceBetween: 10,
          },
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={image.id}
            className="cursor-pointer opacity-50 transition-all hover:opacity-100 [&.swiper-slide-thumb-active]:opacity-100"
          >
            <Image
              src={image.sourceUrl || settings.imagePlaceholder.sourceUrl}
              alt={image.altText || settings.imagePlaceholder.altText}
              width={80}
              height={60}
              className="relative m-0 aspect-[4/3] h-auto w-full select-none overflow-hidden rounded-md object-cover"
              priority={index <= 5}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
