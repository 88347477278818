import Image from "next/image";
export interface IconBoxProps {
  title: string;
  description?: string;
  icon: string;
}

export function IconBox({ title, description, icon }: IconBoxProps) {
  return (
    <div className="text-center">
      <span className="mx-auto mb-6 flex h-[80px] w-[80px] items-center justify-center rounded-lg bg-primary text-white shadow-md shadow-primary md:h-[100px] md:w-[100px]">
        <span className="scale-75 text-white sm:scale-100">
          <Image src={icon} alt={title} width={40} height={40} />
        </span>
      </span>
      <h3 className="mb-2 text-base font-semibold leading-snug text-primary md:text-lg">
        {title}
      </h3>
      <p className="text-sm font-light leading-tight text-gray-500">
        {description}
      </p>
    </div>
  );
}

export default IconBox;
