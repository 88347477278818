import { CookingMenuType } from "@/types";

export interface ProductCookingMenuProps {
  cookingMenu: CookingMenuType[];
}

export function ProductCookingMenu(props: ProductCookingMenuProps) {
  if (!props.cookingMenu) return <></>;

  return (
    <section className="prose prose-headings:text-primary">
      <h2 className="heading-md">Sample Menu</h2>
      {props.cookingMenu.map((menu, index) => {
        return (
          <div key={index}>
            <h3 className="mb-0">{menu.name}</h3>
            <p>{menu.description}</p>
          </div>
        );
      })}
    </section>
  );
}

export default ProductCookingMenu;
