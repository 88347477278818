import Link from "next/link";

export type TagType = {
  id: string;
  name: string;
  link: string;
  slug: string;
};

export interface ProductTagsProps {
  tags: TagType[];
}

export function ProductTags({ tags }: ProductTagsProps) {
  if (!tags) return <></>;

  return (
    <div className="flex flex-wrap items-center gap-2">
      {tags.map((item) => (
        <Link
          key={item.id}
          href={item.link}
          className="block rounded-md bg-gray-100 p-2 px-4 text-xs text-gray-600 no-underline"
        >
          <span>{item.name}</span>
        </Link>
      ))}
    </div>
  );
}

export default ProductTags;
