import { settings } from "@/config";
import { NO_MORE_RESULTS } from "@/constants";
import { queries } from "@/graphql/queries";
import { Order, ProductType, TSortby, TaxArray, TaxOperator } from "@/types";
import Image from "next/image";
import { useState } from "react";
import { FilterValues } from "./product-filter";
import { ProductTourList } from "./product-type/product-tour-list";
import { ProductTransportList } from "./product-type/product-transport-list";

export type ProductList = {
  tag?: string;
  category?: string;
  sortby: TSortby;
  order: Order;
  setSortby: (value: TSortby) => void;
  setOrder: (order: Order) => void;
  defaultDestination?: string;
};

export interface ProductListProps {
  type: ProductType;
  tag?: string;
  category?: string;
  defaultDestination?: string;
}

export function ProductList({
  type,
  tag,
  category,
  defaultDestination,
}: ProductListProps) {
  const [sortby, setSortby] = useState<TSortby>("REVIEW");
  const [order, setOrder] = useState<Order>(Order.DESC);

  if (type === "tour") {
    return (
      <ProductTourList
        tag={tag}
        category={category}
        sortby={sortby}
        order={order}
        setSortby={setSortby}
        setOrder={setOrder}
        defaultDestination={defaultDestination}
      />
    );
  }

  if (type === "transport") {
    return (
      <ProductTransportList
        sortby={sortby}
        order={order}
        setSortby={setSortby}
        setOrder={setOrder}
        defaultDestination={defaultDestination}
      />
    );
  }

  return <></>;
}

/**
 * Scrolls the page to the top of the "product-list" element.
 */
export function scrollToTop() {
  let offset = 80;

  if (window.innerWidth > 768) {
    offset = 160;
  }

  const productList = document.getElementById("product-list");
  if (productList) {
    const topPosition =
      productList.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({ top: topPosition, behavior: "smooth" });
  }
}

/**
 * Handles the sorting of products based on the selected value and order.
 * It updates the state variables for order and sortby, scrolls to the top of the page,
 * and refetches the data with the updated sorting parameters.
 *
 * @param value - The selected sorting value.
 * @param order - The sorting order.
 * @param setOrder - A function to update the order state variable.
 * @param setSortby - A function to update the sortby state variable.
 * @param refetch - A function to refetch the data.
 */
export async function handleSortby(
  value: TSortby,
  order: Order,
  setOrder: (order: Order) => void,
  setSortby: (value: TSortby) => void,
  refetch: (query: any) => void,
) {
  if (!value) return;

  const query = { field: value, order };

  if (["TITLE", "PRICE_LOW_TO_HIGH"].includes(value)) {
    setOrder(Order.ASC);
    query.order = Order.ASC;
  } else {
    setOrder(Order.DESC);
    query.order = Order.DESC;
  }

  setSortby(value);
  scrollToTop();

  await refetch({
    orderby: query,
  });
}

/**
 * Handles the filters for fetching filtered data.
 * @param values - An object containing filter values such as destinations, categories, and price range.
 * @param sortby - A string representing the sorting field.
 * @param order - A string representing the sorting order.
 * @param refetch - A function used to refetch data with the updated query.
 */
export const handleFilters = async (
  values: FilterValues,
  sortby: TSortby,
  order: Order,
  refetch: (query: any) => void,
) => {
  const query = {
    first: settings.postPerPage,
    after: "",
    before: "",
    search: "",
    taxArray: [] as TaxArray[],
    orderby: { field: sortby, order: order },
    priceRange: `${queries.tours.PRICE_RANGE}`,
  };

  if (values.priceRange) {
    const [minPrice, maxPrice] = values.priceRange;
    query.priceRange = `${minPrice},${maxPrice}`;

    if (maxPrice >= 7000) {
      query.priceRange = `${minPrice},${queries.tours.PRICE_RANGE[1]}`;
    }
  }

  if (values.destinations?.length) {
    query.taxArray.push({
      field: "SLUG",
      taxonomy: "DESTINATIONCATEGORY",
      terms: values.destinations,
      operator: TaxOperator.IN,
    });
  }

  if (values.categories?.length) {
    query.taxArray.push({
      field: "SLUG",
      taxonomy: "TOURCATEGORY",
      terms: values.categories,
      operator: TaxOperator.IN,
    });
  }

  scrollToTop();
  await refetch(query);
};

export function NoMoreResults() {
  return (
    <span className="block w-full border-t border-dashed pt-5 text-center text-sm text-gray-400">
      {NO_MORE_RESULTS}
    </span>
  );
}

export function NoResults() {
  return (
    <div className="flex justify-center py-16">
      <div className="prose text-center">
        <figure className="text-center">
          <Image
            src={"/assets/images/favorites.svg"}
            width={800}
            height={400}
            alt="No favorites yet"
            className="mx-auto"
          />
        </figure>
        <p className="mt-16 text-xl font-medium text-gray-500">
          Sorry, we couldn`t find anything for your search.
        </p>
      </div>
    </div>
  );
}
