import {
  GetConactUsPageQuery,
  GfFormFieldsFragment,
  PostTypeSeo,
} from "@/__generated__/graphql";
import {
  BreadCrumb,
  BreadCrumbItem,
  SimpleBanner,
} from "@/components/elements";
import { GravityForm } from "@/components/gravity-form/gravity-form";
import { Layout } from "@/components/layout";
import { settings } from "@/config";
import { fragments } from "@/graphql/fragments";
import { GF_FORM_FIELDS } from "@/graphql/fragments/gravity-form";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import { Divider, Link } from "@nextui-org/react";
import parse from "html-react-parser";
import Head from "next/head";

const Component: FaustTemplate<GetConactUsPageQuery> = ({ data, loading }) => {
  if (loading) {
    return <>Loading...</>;
  }

  const seo = data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    page = data?.page,
    gfForm = data?.gfForm as GfFormFieldsFragment;

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: data?.page?.title as string,
      href: `/${data?.page?.slug}`,
    },
  ];

  return (
    <>
      <Head>
        <title>{page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <article className="container mb-10">
          <BreadCrumb items={breadcrumb} />
          <SimpleBanner
            title={`Contact <br />
            Us`}
            subTitle={page?.title as string}
          />
          <GravityForm {...gfForm} />
          <Divider className="my-10" />
          <p>
            You can also contact us using the email address{" "}
            <Link href={`mailto:${settings.contact.email}`} underline="always">
              {settings.contact.email}
            </Link>
          </p>
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  ${GF_FORM_FIELDS}
  query GetConactUsPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      slug
      seo {
        ...SeoFields
      }
    }
    gfForm(id: 1, idType: ID) {
      ...GfFormFields
    }
  }
`;

export default Component;
