import {
  GetHomePageQuery,
  PostFieldsFragment,
  PostTypeSeo,
  TourFieldsFragment,
} from "@/__generated__/graphql";
import { Banner } from "@/components/banner/banner";
import {
  DestinationItem,
  IconBox,
  Section,
  TripadvisorReviews,
} from "@/components/elements";
import { Layout } from "@/components/layout";
import {
  AboutUsBanner,
  ArticleSlider,
  FindUsOn,
  ProductSlider,
  RecentlyViewed,
} from "@/components/shared";
import { settings } from "@/config";
import { fragments } from "@/graphql/fragments";
import { jsonParse } from "@/utils";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";
import "swiper/css";

const Component: FaustTemplate<GetHomePageQuery> = ({ data }) => {
  const frontendSettings = data?.frontendSettings?.generalSettings,
    pageSettings = data?.page?.pageSettings,
    seo = data?.page?.seo as PostTypeSeo,
    tours = data?.tours?.nodes as TourFieldsFragment[],
    posts = data?.posts?.nodes,
    destinationCategories = data?.destinationCategories?.nodes;

  const fullHead = parse(seoStringParser(seo?.fullHead || ""));

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <Banner
          title={pageSettings?.bannerTitle ?? ""}
          description={pageSettings?.bannerDescription ?? ""}
          backgroundImage={pageSettings?.bannerImage?.sourceUrl ?? ""}
          size="lg"
        />
        <Section title="Why travel with us?" titlePosition="center">
          <div className="container">
            <div className="grid grid-cols-2 gap-x-4 gap-y-6 md:mx-auto md:max-w-screen-lg md:grid-cols-4">
              {settings.services.map((item, index) => (
                <IconBox key={index} {...item} />
              ))}
            </div>
          </div>
        </Section>
        <RecentlyViewed />
        {tours && (
          <ProductSlider type="tour" data={tours} seeMoreLink="/things-to-do" />
        )}
        {posts && (
          <ArticleSlider
            posts={posts as PostFieldsFragment[]}
            seeMoreLink="/articles"
          />
        )}
        <Section title="Top Destinations">
          <div className="container">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
              {destinationCategories?.map((item) => (
                <DestinationItem
                  key={item.id}
                  name={item.name as string}
                  slug={item.slug as string}
                  count={Number(item.count)}
                  featuredImage={{
                    id: item.destinationExtra?.featuredImage?.id ?? "",
                    altText:
                      item.destinationExtra?.featuredImage?.altText ?? "",
                    sourceUrl:
                      item.destinationExtra?.featuredImage?.sourceUrl ?? "",
                  }}
                />
              ))}
            </div>
          </div>
        </Section>
        <AboutUsBanner />
        <FindUsOn />
        <TripadvisorReviews
          title="What do people say <br> about us?"
          source="travel-advisor"
          details={jsonParse(
            frontendSettings?.tripadvisorBusinessDetails ?? "",
          )}
          reviews={jsonParse(
            frontendSettings?.tripadvisorBusinessReviews ?? "",
          )}
          showDetails
        />
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  ${fragments.tours.TOUR_FIELDS}
  ${fragments.posts.POST_FIELDS}
  query GetHomePage($databaseId: ID!) {
    page(id: $databaseId, idType: DATABASE_ID) {
      id
      title
      pageSettings {
        bannerTitle
        bannerDescription
        bannerImage {
          id
          altText
          sourceUrl
        }
      }
      seo {
        ...SeoFields
      }
    }
    tours(
      first: 10
      where: {
        status: PUBLISH
        hasPassword: false
        orderby: [{ field: REVIEW, order: DESC }]
      }
    ) {
      nodes {
        ...TourFields
      }
    }
    posts(
      first: 10
      where: {
        status: PUBLISH
        hasPassword: false
        orderby: [{ field: DATE, order: DESC }]
      }
    ) {
      nodes {
        ...PostFields
      }
    }
    destinationCategories(first: 100, where: { orderby: NAME }) {
      nodes {
        id
        name
        slug
        count
        destinationExtra {
          featuredImage {
            id
            altText
            sourceUrl
          }
        }
      }
    }
    frontendSettings {
      generalSettings {
        tripadvisorBusinessReviews
        tripadvisorBusinessDetails
      }
    }
  }
`;

export default Component;
