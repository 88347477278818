import {
  GetTourCategoryTaxonomyQuery,
  PostTypeSeo,
} from "@/__generated__/graphql";
import { Banner } from "@/components/banner/banner";
import { Layout } from "@/components/layout";
import { ProductList } from "@/components/product-list/product-list";
import { fragments } from "@/graphql/fragments";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";

const Component: FaustTemplate<GetTourCategoryTaxonomyQuery> = ({ data }) => {
  const category =
      data?.nodeByUri?.__typename === "TourCategory" ? data?.nodeByUri : null,
    seo = category?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || ""));

  return (
    <>
      <Head>
        <title>{category?.name}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo?.metaRobotsNoindex}, ${seo?.metaRobotsNofollow}`}
        />
        {seo?.metaKeywords && (
          <meta name="keywords" content={seo?.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <Banner
          title={`Category: ${category?.name}`}
          description="Explore the best things to do in Thailand"
          size="sm"
          showSearchBox={false}
        />
        <ProductList type="tour" category={category?.slug as string} />
      </Layout>
    </>
  );
};

Component.variables = (seedQuery, ctx) => {
  return {
    uri: seedQuery.uri,
  };
};

Component.query = gql`
  ${fragments.seo.TAXONOMY_SEO}
  query GetTourCategoryTaxonomy($uri: String!) {
    nodeByUri(uri: $uri) {
      archiveType: __typename
      ... on TourCategory {
        id
        name
        slug
        description
        seo {
          ...TaxonomySeoFields
        }
      }
    }
  }
`;

export default Component;
