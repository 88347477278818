import { GetThingsToDoPageQuery, PostTypeSeo } from "@/__generated__/graphql";
import { Banner } from "@/components/banner/banner";
import { Layout } from "@/components/layout";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";

import { ProductList } from "@/components/product-list/product-list";
import { fragments } from "@/graphql/fragments";

const Component: FaustTemplate<GetThingsToDoPageQuery> = (props) => {
  const pageSettings = props.data?.page?.pageSettings,
    seo = props.data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || ""));

  return (
    <>
      <Head>
        <title>{props.data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <Banner
          title={pageSettings?.bannerTitle}
          description={pageSettings?.bannerDescription}
          backgroundImage={pageSettings?.bannerImage?.sourceUrl}
          size="md"
        />
        <ProductList type="tour" />
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  query GetThingsToDoPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      pageSettings {
        bannerTitle
        bannerDescription
        bannerImage {
          id
          altText
          sourceUrl
        }
      }
      seo {
        ...SeoFields
      }
    }
  }
`;

export default Component;
