import { MenuItems } from "@/types";
import { Bars3Icon } from "@heroicons/react/24/outline";
import {
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/react";
import Image from "next/image";
import NextLink from "next/link";
import { LanguageSwitcher } from "../language-switcher/language-switcher";

export interface MobileMenuProps {
  logo: string;
  menuItems: MenuItems[];
}

export function MobileMenu({ logo, menuItems }: MobileMenuProps) {
  return (
    <Navbar className="sm:hidden" isBlurred={false}>
      <NavbarBrand>
        <Link as={NextLink} href="/" className="text-2xl">
          <Image src={logo} width={120} height={38} alt="Logo" priority />
        </Link>
      </NavbarBrand>
      <NavbarContent justify="end">
        <NavbarItem>
          <NavbarMenuToggle
            className="h-10 w-10 bg-primary text-white"
            icon={<Bars3Icon className="h-8 w-8" />}
          />
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className="gap-5">
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              as={NextLink}
              className="w-full font-medium"
              color={"foreground"}
              href={item.link}
            >
              {item.icon && <span className="mr-2">{item.icon}</span>}
              {item.title}
            </Link>
          </NavbarMenuItem>
        ))}
        <NavbarMenuItem>
          <LanguageSwitcher />
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}

export default MobileMenu;
