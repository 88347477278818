import { ReadMoreButton } from "@/components/button";
import clsx from "clsx";
import { useState } from "react";

export interface ProductInclusiveProps {
  includes: string;
  excludes: string;
}

export function ProductInclusive({
  includes,
  excludes,
}: ProductInclusiveProps) {
  const [readMore, setReadMore] = useState<boolean>(false);

  return (
    <section>
      <h2 className="heading-md">What’s Included</h2>
      <div
        className={clsx(
          "prose relative grid min-w-full overflow-hidden sm:grid-cols-2",
          {
            "h-auto": readMore,
            "h-32": !readMore,
          },
        )}
      >
        {includes && (
          <div
            className="[&>ul]:list-image-[url(/assets/icons/check.svg)]"
            dangerouslySetInnerHTML={{ __html: includes }}
          />
        )}
        {excludes && (
          <div
            className="[&>ul]:list-image-[url(/assets/icons/cross.svg)]"
            dangerouslySetInnerHTML={{ __html: excludes }}
          />
        )}
        <div
          className={clsx(
            "absolute bottom-0 left-0 h-20 w-full bg-gradient-to-t from-white to-transparent",
            {
              hidden: readMore,
            },
          )}
        />
      </div>
      <div className="mt-6 text-center">
        <ReadMoreButton readMore={readMore} setReadMore={setReadMore} />
      </div>
    </section>
  );
}

export default ProductInclusive;
