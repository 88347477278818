import { GetSingleJobVacancyQuery, PostTypeSeo } from "@/__generated__/graphql";
import { BreadCrumb, BreadCrumbItem } from "@/components/elements";
import { Layout } from "@/components/layout";
import { settings } from "@/config";
import { fragments } from "@/graphql/fragments";
import { formatDate } from "@/utils";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import { Divider, Link } from "@nextui-org/react";
import parse from "html-react-parser";
import Head from "next/head";
import Image from "next/image";
import sanitizeHtml from "sanitize-html";

const Component: FaustTemplate<GetSingleJobVacancyQuery> = ({
  data,
  loading,
}) => {
  // Loading state for previews
  if (loading) {
    return <>Loading...</>;
  }

  const seo = data?.jobVacancy?.seo as PostTypeSeo;
  const fullHead = parse(seoStringParser(seo?.fullHead || ""));
  const post = data?.jobVacancy;

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Job Vacancies",
      href: "/job-vacancies",
    },
    {
      label: data?.jobVacancy?.title as string,
      href: `/job-vacancy/${data?.jobVacancy?.slug as string}`,
    },
  ];

  const sanitized = sanitizeHtml(post?.content || "", {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });

  return (
    <>
      <Head>
        <title>{post?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout>
        <BreadCrumb items={breadcrumb} />
        <article className="prose mb-10 min-w-full prose-headings:text-primary">
          <figure>
            <Image
              className="w-full rounded-lg object-cover"
              src={
                post?.fields?.banner?.sourceUrl ||
                settings.imagePlaceholder.sourceUrl
              }
              alt={
                post?.fields?.banner?.altText ||
                settings.imagePlaceholder.altText
              }
              width={1000}
              height={400}
              priority
            />
          </figure>
          <time
            className="mb-2 block text-base text-gray-500"
            dateTime={post?.date as string}
          >
            {formatDate(post?.date as string)}
          </time>
          <h1 className="font-semibold">{post?.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: sanitized }} />
          <div className="mb-4 font-medium">
            <span className="text-lg">Salary:</span>{" "}
            <span className="text-lg">
              {post?.fields?.salary?.minimum?.toLocaleString()} -{" "}
              {post?.fields?.salary?.maximum?.toLocaleString()} THB
            </span>
          </div>
          <Divider className="my-10" />
          <p>
            To apply for an open position, please send your resume by email to{" "}
            <Link href={`mailto:${settings.contact.email}`} underline="always">
              {settings.contact.email}
            </Link>{" "}
            Must include a cover letter and your academic records.
          </p>
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  query GetSingleJobVacancy($databaseId: ID!, $asPreview: Boolean = false) {
    jobVacancy(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      id
      databaseId
      date
      slug
      title
      featuredImage {
        node {
          id
          altText
          sourceUrl
        }
      }
      content
      fields: jobVacancyExtra {
        banner {
          id
          altText
          sourceUrl
        }
        shortDescription
        salary {
          maximum
          minimum
        }
      }
      seo {
        ...SeoFields
      }
    }
  }
`;

export default Component;
