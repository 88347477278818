import { gql } from "@apollo/client";

const JOB_VACANCY_FIELDS = gql`
  fragment JobVacancyFields on JobVacancy {
    id
    databaseId
    date
    slug
    title
    featuredImage {
      node {
        id
        altText
        sourceUrl
      }
    }
    fields: jobVacancyExtra {
      shortDescription
      salary {
        maximum
        minimum
      }
    }
  }
`;

export const jobVacancies = {
  JOB_VACANCY_FIELDS,
};

export default jobVacancies;
