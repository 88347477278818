import { format } from "date-fns";

const toDate = (value: Date | string | number): Date => {
  return typeof value === "string" || typeof value === "number"
    ? new Date(value)
    : value;
};

export const formatDate = (
  value: Date | string | number,
  formatOptions = "MMMM dd, y",
): string => format(toDate(value), formatOptions);
