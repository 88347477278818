import { WordPressTemplate } from "@faustwp/core";

export type WordPressTemplateProps = Parameters<typeof WordPressTemplate>[0];

export type MenuItems = {
  title: string;
  link: string;
  icon?: React.ReactNode;
};

export type ProductType = "tour" | "transport";

export type TourType = "attraction" | "cooking";

export type TSortby =
  | "DATE"
  | "PRICE_LOW_TO_HIGH"
  | "PRICE_HIGH_TO_LOW"
  | "TITLE"
  | "REVIEW"
  | "POPULARITY";

export enum TaxOperator {
  AND = "AND",
  EXISTS = "EXISTS",
  NOT_EXISTS = "NOT_EXISTS",
  IN = "IN",
  NOT_IN = "NOT_IN",
}

export type TaxArray = {
  field: string;
  taxonomy: string;
  terms: string[] | string;
  operator: TaxOperator;
};

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export type PackageOptionPriceType = {
  name: string;
  price: number;
  unit: string;
};

export type PackageOptionType = {
  name: string;
  prices: PackageOptionPriceType[];
  duration: string;
  durationUnit: string;
  departureTime: string;
  pickUpTime: string;
  return: string;
  description: string;
};

export type Address = {
  latitude: number;
  longitude: number;
  city: string;
  country: string;
  countryShort: string;
  placeId: string;
  postCode: string;
  state: string;
  stateShort: string;
  streetAddress: string;
  streetName: string;
  streetNumber: string;
  zoom: number;
};

export type MeetingPointType = {
  name: string;
  address: Address;
};

export type ItineraryType = {
  name: string;
  description: string;
  address: Address;
};

export type CookingMenuType = {
  name: string;
  description: string;
};
