import {
  ItemStyles,
  Rating as SmastromRating,
  ThinRoundedStar,
} from "@smastrom/react-rating";
import Link from "next/link";
import { HTMLAttributeAnchorTarget } from "react";

const CircleRating = (
  <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2z" />
);

const tripadvisorStyle: ItemStyles = {
  itemShapes: CircleRating,
  activeFillColor: "#00aa6c",
  inactiveFillColor: "#e9e9e9",
};

const defaultStyle: ItemStyles = {
  itemShapes: ThinRoundedStar,
  activeFillColor: "#ff5425",
  inactiveFillColor: "#e9e9e9",
};

export interface RatingProps {
  variant: "default" | "tripadvisor";
  rating: number;
  numReviews: number;
  link: string;
  linkTarget?: HTMLAttributeAnchorTarget;
  size?: number;
}

export function Rating({
  variant = "default",
  rating,
  numReviews,
  link,
  linkTarget = "_blank",
  size = 120,
}: RatingProps) {
  return (
    <div className="flex items-center gap-2">
      <SmastromRating
        style={{ maxWidth: size }}
        value={rating}
        halfFillMode="svg"
        readOnly
        itemStyles={variant === "tripadvisor" ? tripadvisorStyle : defaultStyle}
      />
      {link && (
        <Link
          href={link}
          target={linkTarget}
          className="text-xs no-underline hover:underline lg:text-sm"
        >
          {numReviews?.toLocaleString("en-US")} reviews
        </Link>
      )}
    </div>
  );
}
