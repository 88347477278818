import { gql } from "@apollo/client";

const POST_FIELDS = gql`
  fragment PostFields on Post {
    id
    databaseId
    title
    slug
    date
    excerpt
    featuredImage {
      node {
        id
        altText
        sourceUrl
      }
    }
  }
`;

export const posts = {
  POST_FIELDS,
};

export default posts;
