import { FieldError, FormField } from "@/__generated__/graphql";
import { CaptchaField } from "./captcha-field";
import { EmailField } from "./email-field";
import { SelectField } from "./select-field";
import { TextField } from "./text-field";
import { TextareaField } from "./textarea-field";

// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
  field: FormField;
  fieldErrors: FieldError[];
  recaptchaRef?: ReCAPTCHA | any;
}

export default function GravityFormsField({
  field,
  fieldErrors,
  recaptchaRef,
}: Props) {
  switch (field.type) {
    // case "address":
    //   return <AddressField field={field} fieldErrors={fieldErrors} />;
    // case "checkbox":
    //   return <CheckboxField field={field} fieldErrors={fieldErrors} />;
    // case "date":
    //   return <DateField field={field} fieldErrors={fieldErrors} />;
    case "EMAIL":
      return <EmailField field={field} fieldErrors={fieldErrors} />;
    // case "multiselect":
    //   return <MultiSelectField field={field} fieldErrors={fieldErrors} />;
    // case "NAME":
    //   return <NameField field={field} fieldErrors={fieldErrors} />;
    // case "phone":
    //   return <PhoneField field={field} fieldErrors={fieldErrors} />;
    // case "radio":
    //   return <RadioField field={field} fieldErrors={fieldErrors} />;
    case "SELECT":
      return <SelectField field={field} fieldErrors={fieldErrors} />;
    case "TEXT":
      return <TextField field={field} fieldErrors={fieldErrors} />;
    case "TEXTAREA":
      return <TextareaField field={field} fieldErrors={fieldErrors} />;
    // case "time":
    //   return <TimeField field={field} fieldErrors={fieldErrors} />;
    // case "website":
    //   return <WebsiteField field={field} fieldErrors={fieldErrors} />;
    case "CAPTCHA":
      return (
        <CaptchaField
          recaptchaRef={recaptchaRef}
          field={field}
          fieldErrors={fieldErrors}
        />
      );
    default:
      return <p>{`Field type not supported: ${field.type}.`}</p>;
  }
}
