import {
  FieldError,
  FormField,
  FormFieldValuesInput,
  GfFormFieldsFragment,
} from "@/__generated__/graphql";
import { GF_FORM_FIELDS } from "@/graphql/fragments/gravity-form";
import { gql, useMutation } from "@apollo/client";
import { Button } from "@nextui-org/react";
import { useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import GravityFormsField from "./gf-form-fields";

// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";

const SUBMIT_FORM = gql`
  mutation submitForm($id: ID!, $fieldValues: [FormFieldValuesInput]!) {
    submitGfForm(input: { id: $id, fieldValues: $fieldValues }) {
      entry {
        id
      }
      confirmation {
        message
      }
      errors {
        id
        message
      }
    }
  }
`;

export function GravityForm(props: GfFormFieldsFragment) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [haveFieldErrors, setHaveFieldErrors] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const formMethods = useForm();
  const { handleSubmit, reset } = formMethods;

  const formFields = props?.formFields?.edges?.map((field) => field?.node);

  const [submitForm, { loading }] = useMutation(SUBMIT_FORM);

  const onSubmit: SubmitHandler<any> = async (values) => {
    const response = await submitForm({
      variables: {
        id: props?.formId,
        fieldValues: formattedFormData(values),
      },
    });

    if (!response.errors) {
      toast.success(
        (t) => (
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: response.data?.submitGfForm?.confirmation?.message,
            }}
          />
        ),
        {
          duration: 8000,
        },
      );
      recaptchaRef?.current?.reset();
      reset();
    }
  };

  function getFieldErrors(id: number): FieldError[] {
    if (!haveFieldErrors) return [];
    return errors.filter((error: FieldError) => error.id === id);
  }

  return (
    <FormProvider {...formMethods}>
      <Toaster />
      <form
        className="my-4 grid gap-4"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {formFields?.map((field) => {
          return (
            <GravityFormsField
              key={field?.id}
              field={field as FormField}
              fieldErrors={getFieldErrors(Number(field?.id))}
              recaptchaRef={recaptchaRef}
            />
          );
        })}
        <Button type="submit" color="primary" isLoading={loading}>
          Submit
        </Button>
      </form>
    </FormProvider>
  );
}

GravityForm.fragments = {
  gravityFormFragment: GF_FORM_FIELDS,
};

export default GravityForm;

function formattedFormData(values: { [key: string]: string }) {
  return Object.keys(values).map((key) => {
    const id = key.replace("field_", "");

    // check if is email field
    if (values[key]?.includes("@")) {
      return {
        id: Number(id),
        emailValues: {
          value: values[key],
        },
      };
    }
    return {
      id: Number(id),
      value: values[key],
    };
  }) as Array<FormFieldValuesInput>;
}
