import { GetPostQuery, PostTypeSeo } from "@/__generated__/graphql";
import { BreadCrumb, BreadCrumbItem } from "@/components/elements";
import { Layout } from "@/components/layout";
import { settings } from "@/config";
import { fragments } from "@/graphql/fragments";
import { formatDate } from "@/utils";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";
import Image from "next/image";
import sanitizeHtml from "sanitize-html";

const Component: FaustTemplate<GetPostQuery> = ({ data, loading }) => {
  // Loading state for previews
  if (loading) {
    return <>Loading...</>;
  }

  const seo = data?.post?.seo as PostTypeSeo;
  const fullHead = parse(seoStringParser(seo?.fullHead || ""));
  const post = data?.post;

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Articles",
      href: "/articles",
    },
    {
      label: data?.post?.title as string,
      href: `/articles/${data?.post?.slug}`,
    },
  ];

  const sanitized = sanitizeHtml(post?.content || "", {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });

  return (
    <>
      <Head>
        <title>{data?.post?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout>
        <BreadCrumb items={breadcrumb} />
        <article className="prose mx-auto my-16 min-w-full">
          <div className="mx-auto max-w-screen-lg">
            <h1 className="font-semibold">{post?.title}</h1>
            <time
              className="mb-2 block text-base text-gray-500"
              dateTime={post?.date as string}
            >
              {formatDate(post?.date as string)}
            </time>
            <figure>
              <Image
                className="w-full rounded-lg object-cover"
                src={
                  post?.featuredImage?.node?.sourceUrl ||
                  settings.imagePlaceholder.sourceUrl
                }
                alt={
                  post?.featuredImage?.node?.altText ||
                  settings.imagePlaceholder.altText
                }
                width={1000}
                height={400}
                priority
              />
            </figure>
            <div dangerouslySetInnerHTML={{ __html: sanitized }} />
          </div>
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  query GetPost($databaseId: ID!, $asPreview: Boolean = false) {
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      slug
      content
      date
      featuredImage {
        node {
          id
          altText
          sourceUrl
        }
      }
      seo {
        ...SeoFields
      }
    }
  }
`;

export default Component;
