import { Button } from "@nextui-org/react";
import Image from "next/image";
import NextLink from "next/link";

export interface AboutUsBannerProps {}

export function AboutUsBanner(props: AboutUsBannerProps) {
  return (
    <section className="mt-16 bg-black/90 md:mt-40">
      <div className="relative lg:flex">
        <div className="container grid grid-cols-1 lg:grid-cols-2">
          <div className="py-16 text-white">
            <h2 className="mb-6 text-3xl font-normal text-primary sm:text-4xl">
              Thailand&apos; s Travel <br /> Expert
            </h2>
            <p className="mb-8 max-w-xl text-base font-thin">
              Siam Scape Journeys Co.,Ltd is a prominent travel agency
              and tour distributor based in Thailand, proudly serving travelers
              from around the globe for over two decades. With a wealth of
              experience in the country&apos; s vibrant tourism industry, we
              have emerged as a trusted name, offering top-notch services and
              unforgettable travel experiences.
            </p>
            <Button
              as={NextLink}
              href={"/about-us"}
              title="About US"
              color="primary"
              variant="bordered"
            >
              Read More
            </Button>
          </div>
          <div />
        </div>
        <div
          style={{
            backgroundImage: "url(/assets/images/about-us-banner-backgroud.jpg)",
          }}
          className="absolute right-0 top-0 hidden h-full w-[50vw] bg-cover bg-top bg-no-repeat object-cover lg:block"
        >
          <Image
            src="/assets/images/model02.png"
            alt=""
            width={600}
            height={800}
            className="absolute bottom-0 left-0 right-0 mx-auto"
          />
        </div>
        <div className="relative lg:hidden">
          <Image
            src="/assets/images/about-us-banner.png"
            alt=""
            width={600}
            height={800}
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </section>
  );
}
