import { BannerProps } from "@/components/banner/banner";
import { IconBoxProps } from "@/components/elements";
import { MenuItems } from "@/types";
import { FeaturedImage } from "@/types/featured-image";
import { MapPinIcon } from "@heroicons/react/24/outline";

const year = new Date().getFullYear();

export interface Settings {
  frontendUrl: string;
  wpUrl: string;
  postPerPage: number;
  logo: string;
  imagePlaceholder: FeaturedImage;
  socials: {
    facebook: string;
    line: string;
    tripadvisor: string;
  };
  banner: {
    default: BannerProps;
  };
  services: IconBoxProps[];
  findUsOn: {
    src: string;
    alt: string;
  }[];
  header: {
    topMenu: MenuItems[];
    mainMenu: MenuItems[];
    mobileMenu: MenuItems[];
  };
  contact: {
    email: string;
    phones: {
      numbers: string[];
      availability: string;
    }[];
  };
  footer: {
    address: string;
    openHours: string;
    menus: {
      title: string;
      links: MenuItems[];
    }[];
    copyRight: string;
    footerLinks: MenuItems[];
  };
}

export const settings: Settings = {
  frontendUrl: "https://www.oh-hoo.com",
  wpUrl: "https://content.oh-hoo.com",
  postPerPage: 9,
  logo: "/assets/images/logo.svg",
  imagePlaceholder: {
    id: "1",
    altText: "Placeholder",
    sourceUrl: "/assets/images/placeholder.png",
  },
  socials: {
    facebook: "https://www.facebook.com/OhHooTravel",
    line: "https://line.me/R/ti/p/%40cnn5314y",
    tripadvisor:
      "https://www.tripadvisor.com/Attraction_Review-g297927-d15618582-Reviews-Oh_Hoo-Krabi_Town_Krabi_Province.html",
  },
  banner: {
    default: {
      title: "Explore the Wonders of Thailand",
      description:
        "Discover Local Escapes and Far-Flung Adventures Anytime, Anywhere",
      backgroundImage: "/assets/images/home-banner.jpg",
      showSearchBox: true,
      size: "md",
    },
  },
  services: [
    {
      icon: "/assets/icons/thumbs-up-solid.svg",
      title: "World Class Service",
      description: "Popular from customers around the world",
    },
    {
      icon: "/assets/icons/hand-holding-dollar-solid.svg",
      title: "Best Price Guarantee",
      description: "By choosing the most popular tours",
    },
    {
      icon: "/assets/icons/hand-holding-heart-solid.svg",
      title: "Handpicked by You",
      description: "Experience the Freedom of Self-Planned Travel",
    },
    {
      icon: "/assets/icons/user-group-solid.svg",
      title: "Local Entrepreneurs",
      description: "Local Entrepreneur more over 10 years",
    },
  ],
  findUsOn: [
    {
      src: "/assets/images/tripadvisor-logo.svg",
      alt: "Trip Advisor",
    },
    {
      src: "/assets/images/viator-logo.svg",
      alt: "Viator",
    },
    {
      src: "/assets/images/expedia-logo.svg",
      alt: "Expedia",
    },
    {
      src: "/assets/images/klook-logo.svg",
      alt: "Klook",
    },
    {
      src: "/assets/images/civitatis-logo.svg",
      alt: "Civitatis",
    },
    {
      src: "/assets/images/tuigroup-logo.svg",
      alt: "TUI Group",
    },
    {
      src: "/assets/images/agoda-logo.svg",
      alt: "Agoda",
    },
    {
      src: "/assets/images/musement-logo.svg",
      alt: "Musement",
    },
    {
      src: "/assets/images/toristy-logo.svg",
      alt: "Toristy",
    },
    {
      src: "/assets/images/bokun-logo.svg",
      alt: "Bokun",
    },
    {
      src: "/assets/images/get-your-guide-logo.svg",
      alt: "Get Your Guide",
    },
  ],

  header: {
    topMenu: [
      {
        title: "About us",
        link: "/about-us",
      },
      {
        title: "Contact us",
        link: "/contact-us",
      },
      {
        title: "FAQ",
        link: "/faq",
      },
      {
        title: "My Favorites",
        link: "/my-favorites",
      },
    ],
    mainMenu: [
      {
        title: "Destination",
        link: "/destination",
        icon: <MapPinIcon className="h-4 w-4" />,
      },
      {
        title: "Things to do",
        link: "/things-to-do",
      },
      {
        title: "Transports",
        link: "/transports",
      },
      {
        title: "Articles & Tips",
        link: "/articles",
      },
    ],
    mobileMenu: [
      {
        title: "Destination",
        link: "/destination",
        icon: <MapPinIcon className="h-4 w-4" />,
      },
      {
        title: "Things to do",
        link: "/things-to-do",
      },
      {
        title: "Transports",
        link: "/transports",
      },
      {
        title: "Articles & Tips",
        link: "/articles",
      },
      {
        title: "About us",
        link: "/about-us",
      },
      {
        title: "Contact us",
        link: "/contact-us",
      },
      {
        title: "FAQ",
        link: "/faq",
      },
      {
        title: "My Favorites",
        link: "/my-favorites",
      },
    ],
  },
  contact: {
    email: "ohhootravel@gmail.com",
    phones: [
      {
        numbers: ["+669-9672-9337", "+669-2716-8007", "+667-563-0618"],
        availability: "(06.00 AM. - 06.00 PM.)",
      },
      {
        numbers: ["+66(0)75-630-618"],
        availability: "(06.00 AM. - 08.00 PM.)",
      },
      {
        numbers: ["+66638524348"],
        availability: " (06.00 PM. - 12.00 AM.)",
      },
    ],
  },
  footer: {
    address:
      "<b>Siam Scape Journeys Co.,Ltd</b> <br /> 73/1 Uttarakit Road, Pak Nam Subdistrict, <br /> Mueang Krabi District, Krabi Province 81000",
    openHours: "Monday - Sunday: 08:00 - 22:00",
    menus: [
      {
        title: "Our Service",
        links: [
          { title: "Top Destinations", link: "/destination" },
          { title: "Transports", link: "/transports" },
        ],
      },
      {
        title: "Work With Us",
        links: [
          {
            title: "Supplier or Tour Operator",
            link: "/supplier-or-tour-operator",
          },
          { title: "Job Vacancies", link: "/job-vacancies" },
          {
            title: "Bloggers/Influencer Program",
            link: "/bloggers-influencer-program",
          },
        ],
      },
      {
        title: "Help",
        links: [
          { title: "FAQ", link: "/faq" },
          { title: "Payment Options", link: "/faq#payment" },
          { title: "Bank Account", link: "/faq#payment" },
          {
            title: "Cancellation Policy",
            link: "/terms-and-conditions#cancellation",
          },
        ],
      },
    ],
    copyRight: `© ${year} Oh-Hoo. All Rights Reserved.`,
    footerLinks: [
      {
        link: "/privacy-policy",
        title: "Privacy Policy",
      },
      {
        link: "/terms-and-conditions",
        title: "Terms And Conditions",
      },
      {
        link: "/about-us",
        title: "About Us",
      },
      {
        link: "/contact-us",
        title: "Contact Us",
      },
    ],
  },
};
