import { SKIIP_LINK_SELECTORS } from "@/constants";

export interface FullWidthLayoutProps {
  children: React.ReactNode;
}

export function FullWidthLayout({ children }: FullWidthLayoutProps) {
  return <main id={SKIIP_LINK_SELECTORS}>{children}</main>;
}

export default FullWidthLayoutProps;
