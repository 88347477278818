import { fragments } from "@/graphql/fragments";
import { gql } from "@apollo/client";

export const PRICE_RANGE: [number, number] = [0, 100000];

const QUERY_ALL_TRANSPORTS = gql`
  ${fragments.transports.TRANSPORT_FIELDS}
  query GetAllTransports(
    $first: Int = 10
    $orderby: PostObjectsConnectionOrderbyInput = { field: DATE, order: DESC }
    $after: String = ""
    $before: String = ""
    $search: String = ""
    $taxArray: [TaxArray] = []
    $priceRange: String = "[0, 100000]"
  ) {
    transports(
      first: $first
      where: {
        hasPassword: false
        orderby: [$orderby]
        search: $search
        taxQuery: { relation: AND, taxArray: $taxArray }
        metaQuery: {
          relation: AND
          metaArray: [
            {
              value: $priceRange
              type: NUMERIC
              key: "price"
              compare: BETWEEN
            }
          ]
        }
      }
      after: $after
      before: $before
    ) {
      nodes {
        ...TransportFields
        transportExtra {
          price
          discountPrice
        }
      }
      pageInfo {
        total
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
`;

const QUERY_RELATED_TRANSPORTS = gql`
  ${fragments.transports.TRANSPORT_FIELDS}
  query RelatedTransports(
    $first: Int = 10
    $notIn: [ID] = []
    $orderby: PostObjectsConnectionOrderbyInput = { field: DATE, order: DESC }
    $taxArray: [TaxArray] = []
  ) {
    transports(
      first: $first
      where: {
        hasPassword: false
        orderby: [$orderby]
        notIn: $notIn
        taxQuery: { relation: AND, taxArray: $taxArray }
      }
    ) {
      edges {
        node {
          ...TransportFields
          transportExtra {
            price
            discountPrice
          }
        }
      }
    }
  }
`;

const QUERY_TRANSPORT_BY_SLUG = gql`
  ${fragments.transports.TRANSPORT_FULL_FIELDS}
  ${fragments.acf.ADDRESS_FIELDS}
  query TransportBySlug($slug: ID!) {
    transport(id: $slug, idType: URI) {
      ...TransportFullFields
      content
      excerpt
      transportExtra {
        price
        discountPrice
        bookingHtml
        feature {
          age
          capacity
          duration
          hotelPickup
          mobileVoucher
        }
        shortDescription
        gallery {
          altText
          caption
          sourceUrl
          srcSet
          sizes
          id
        }
        packageOptions {
          name
          prices {
            name
            price
            unit
          }
          duration
          durationUnit
          departureTime
          pickUpTime
          return
          description
        }
        included
        notIncluded
        meetingPoint {
          name
          address {
            ...AddressFields
          }
        }
        meetingPointDescription
        itinerary {
          name
          description
          address {
            ...AddressFields
          }
        }
        whatToExpect
        additionalInformation
        tripadvisorDetails
        tripadvisorReviews
        rating
        numReviews
      }
      tourTags(first: 100) {
        nodes {
          id
          name
          link
          slug
        }
      }
      transportCategories(first: 100) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

const QUERY_TRANSPORT_PER_PAGE = gql`
  query TransportPerPage {
    allSettings {
      readingSettingsPostsPerPage
    }
  }
`;

const QUERY_ALL_TRANSPORT_CAREGORIES = gql`
  query TransportCategories {
    transportCategories(first: 1000, where: { orderby: NAME }) {
      edges {
        node {
          id
          link
          name
          slug
          count
          parentId
          children {
            edges {
              node {
                id
                link
                name
                slug
                count
                parentId
              }
            }
          }
        }
      }
    }
  }
`;

const SEARCH_ALL_TRANSPORTS = gql`
  ${fragments.transports.TRANSPORT_FIELDS}
  query SearchTransports(
    $first: Int = 10
    $where: RootQueryToTransportConnectionWhereArgs
  ) {
    transports(first: $first, where: $where) {
      edges {
        node {
          ...TransportFields
        }
      }
    }
  }
`;

const QUERY_TRANSPORT_FILTERS = gql`
  query GetTransportFilters {
    destinationCategories(first: 100, where: { orderby: NAME }) {
      nodes {
        id
        name
        slug
        count
      }
    }
  }
`;

export const transports = {
  PRICE_RANGE,
  QUERY_ALL_TRANSPORTS,
  QUERY_RELATED_TRANSPORTS,
  QUERY_TRANSPORT_BY_SLUG,
  QUERY_TRANSPORT_PER_PAGE,
  QUERY_ALL_TRANSPORT_CAREGORIES,
  SEARCH_ALL_TRANSPORTS,
  QUERY_TRANSPORT_FILTERS,
};

export default transports;
