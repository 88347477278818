import clsx from "clsx";
import { useState } from "react";
import sanitizeHtml from "sanitize-html";

import { ReadMoreButton } from "@/components/button";

export interface ContentBlockProps {
  heading: string;
  content: string;
}

export function ContentBlock({ heading, content = "" }: ContentBlockProps) {
  const [readMore, setReadMore] = useState<boolean>(false);

  const sanitized = sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });

  return (
    <section className="overflow-hidden">
      <h2 className="heading-md">{heading}</h2>
      <div
        className={clsx("prose relative min-w-full overflow-hidden", {
          "h-auto": readMore,
          "h-32": !readMore,
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: sanitized }} />
        <div
          className={clsx(
            "absolute bottom-0 left-0 h-20 w-full bg-gradient-to-t from-white to-transparent",
            {
              hidden: readMore,
            },
          )}
        />
      </div>
      <div className="mt-6 text-center">
        <ReadMoreButton readMore={readMore} setReadMore={setReadMore} />
      </div>
    </section>
  );
}

export default ContentBlock;
