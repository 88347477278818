import { CLEAR_SELECTION } from "@/constants";
import {
  Accordion,
  AccordionItem,
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  Link,
} from "@nextui-org/react";
import { useCallback } from "react";

export type CheckboxTree = {
  key: string;
  label: string;
  value: string;
  disabled?: boolean;
  children: CheckboxTree[];
};

export interface CheckboxTreeProps extends CheckboxGroupProps {
  items: CheckboxTree[];
}

export function CheckboxTree(props: CheckboxTreeProps) {
  const childIsSelected = useCallback(
    (parentKey: string) => {
      const parent = props.items.find((item) => item.key === parentKey);
      const children = parent?.children.map((child) => child.value);

      if (children?.every((child) => props?.value?.includes(child))) {
        return false;
      }

      if (children?.some((child) => props?.value?.includes(child))) {
        return true;
      }

      return false;
    },
    [props.items, props?.value],
  );

  const handleCheckAllChildren = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      const parent = props.items.find((item) => item.value === value);
      const children = parent?.children.map((child) => child.value);

      if (checked) {
        const newValues: string[] = [
          ...(props.value as string[]),
          ...(children as string[]),
          parent?.value as string,
        ];

        props.onValueChange?.(Array.from(new Set(newValues)));
      }

      if (!checked) {
        const newValues = [
          ...(props.value as string[]).filter(
            (value) => value !== parent?.value && !children?.includes(value),
          ),
        ];
        props.onValueChange?.(newValues);
      }
    },
    [props],
  );

  return (
    <CheckboxGroup
      label={props.label}
      defaultValue={props.defaultValue}
      value={props.value}
      onValueChange={props.onValueChange}
      className="relative z-10"
      classNames={{
        label: "mb-2 font-semibold",
      }}
    >
      {props.value && props.value.length > 0 && (
        <Link
          className="absolute right-0 top-0 text-sm"
          onPress={() => props.onValueChange?.([])}
        >
          {CLEAR_SELECTION}
        </Link>
      )}
      {props?.items?.map((item) => (
        <Accordion
          isCompact
          key={item.key}
          className="px-0"
          itemClasses={{
            trigger: "p-0",
          }}
        >
          <AccordionItem
            key={item.key}
            aria-label={item.label}
            title={
              <Checkbox
                key={item.key}
                value={item.value}
                isIndeterminate={childIsSelected(item.key)}
                isDisabled={item.disabled}
                onChange={handleCheckAllChildren}
                className="block"
              >
                {item.label}
              </Checkbox>
            }
          >
            <div className="grid grid-cols-1 gap-2">
              {item.children.map((child) => (
                <Checkbox
                  key={child.key}
                  value={child.value}
                  isDisabled={child.disabled}
                  className="ml-4"
                >
                  {child.label}
                </Checkbox>
              ))}
            </div>
          </AccordionItem>
        </Accordion>
      ))}
    </CheckboxGroup>
  );
}

export default CheckboxTree;
