import {
  GetAllToursQuery,
  GetMyFavoritesPageQuery,
  PostTypeSeo,
  TourFieldsFragment,
} from "@/__generated__/graphql";
import { ProductItem, ProductItemLoader } from "@/components/elements";
import { Layout } from "@/components/layout";
import { fragments } from "@/graphql/fragments";
import { queries } from "@/graphql/queries";
import { useGlobalStore } from "@/stores/global";
import seoStringParser from "@/utils/seo-string-parser";
import { gql, useLazyQuery } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";
import Image from "next/image";
import { useEffect } from "react";

const Component: FaustTemplate<GetMyFavoritesPageQuery> = ({
  data,
  loading,
}) => {
  const favorites = useGlobalStore.getState().favorites;

  const [getTours, { loading: tourLoading, error, data: tourData }] =
    useLazyQuery<GetAllToursQuery>(queries.tours.QUERY_ALL_TOURS, {
      variables: {
        first: 10,
        orderby: { field: "IN", order: "DESC" },
        in: favorites,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (favorites.length) {
      getTours();
    }
  }, [favorites.length, getTours]);

  if (loading) {
    return <>Loading...</>;
  }

  const tours = (tourData?.tours?.nodes as TourFieldsFragment[]) ?? [];

  const seo = data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    page = data?.page;

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout>
        <article className="my-10">
          <h1 className="text-2xl font-semibold text-primary">My Favorites</h1>
          <div className="mt-16">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {tourLoading &&
                Array.from({ length: 9 }).map((_, index) => (
                  <ProductItemLoader key={index} />
                ))}
              {!tourLoading &&
                tours?.map((item) => (
                  <ProductItem
                    key={item.id}
                    type="tour"
                    id={item.id}
                    title={item.title as string}
                    slug={item.slug as string}
                    featuredImage={{
                      id: item.featuredImage?.node.id ?? "",
                      altText: item.featuredImage?.node.altText ?? "",
                      sourceUrl: item.featuredImage?.node.sourceUrl ?? "",
                    }}
                    price={Number(item.fields?.price)}
                    discountPrice={Number(item.fields?.discountPrice)}
                    rating={Number(item.fields?.rating)}
                    numReviews={Number(item.fields?.numReviews)}
                  />
                ))}
            </div>
            {!tourLoading && !tours.length && (
              <div className="mt-8 flex justify-center">
                <div className="prose text-center">
                  <p className="mb-16 text-2xl font-medium">
                    No favorites yet.
                  </p>
                  <figure className="text-center">
                    <Image
                      src={"/assets/images/favorites.svg"}
                      width={800}
                      height={400}
                      alt="No favorites yet"
                      className="mx-auto"
                    />
                  </figure>
                  <span className="block leading-normal">
                    Just click on the heart icon on any tour card or page and
                    you’ll save it here for easy reference later.
                  </span>
                </div>
              </div>
            )}
          </div>
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  query GetMyFavoritesPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      slug
      seo {
        ...SeoFields
      }
    }
  }
`;

export default Component;
