import { settings } from "@/config";
import { FeaturedImage } from "@/types/featured-image";
import { formatDate } from "@/utils";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { ItemStyles, ThinRoundedStar } from "@smastrom/react-rating";
import Image from "next/image";
import Link from "next/link";

export interface JobVacancyItemProps {
  title: string;
  slug: string;
  date: Date;
  shortDescription: string;
  salary: {
    maximum: number;
    minimum: number;
  };
  featuredImage?: FeaturedImage;
}

const styles: ItemStyles = {
  itemShapes: ThinRoundedStar,
  activeFillColor: "#ff5425",
  inactiveFillColor: "#e9e9e9",
};

export function JobVacancyItem({
  title,
  slug,
  date,
  featuredImage,
  salary,
  shortDescription,
}: JobVacancyItemProps) {
  const url = `/job-vacancy/${slug}`;

  return (
    <Card>
      <CardHeader className="aspect-[4/2] w-full flex-col items-start bg-gray-50 p-0">
        <Link href={url} title={title} className="h-full w-full">
          <Image
            src={
              featuredImage?.sourceUrl || settings.imagePlaceholder.sourceUrl
            }
            alt={featuredImage?.altText || settings.imagePlaceholder.altText}
            width={400}
            height={300}
            className="aspect-[4/2] h-full w-full object-cover"
          />
        </Link>
      </CardHeader>
      <CardBody className="p-6">
        <small className="mb-1 text-gray-500">{formatDate(date)}</small>
        <Link href={url} title={title}>
          <h3 className="mb-4 line-clamp-2 font-semibold leading-snug">
            {title}
          </h3>
        </Link>
        <div className="mb-4 text-gray-500">
          <span className="text-sm">Salary:</span>{" "}
          <span className="text-sm">
            {salary.minimum.toLocaleString()} -{" "}
            {salary.maximum.toLocaleString()} THB
          </span>
        </div>
        <p className="mb-4 line-clamp-3 text-sm">{shortDescription}</p>
      </CardBody>
    </Card>
  );
}

export default JobVacancyItem;
