import {
  GetArticlesPageQuery,
  PostFieldsFragment,
  PostTypeSeo,
} from "@/__generated__/graphql";
import { Banner } from "@/components/banner/banner";
import { BlogItem, BreadCrumb, BreadCrumbItem } from "@/components/elements";
import { Layout } from "@/components/layout";
import { fragments } from "@/graphql/fragments";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";

const breadcrumb: BreadCrumbItem[] = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Articles",
    href: "/articles",
  },
];

const Component: FaustTemplate<GetArticlesPageQuery> = ({ data }) => {
  const pageSettings = data?.page?.pageSettings,
    seo = data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    posts = data?.posts?.nodes;

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <Banner
          title={pageSettings?.bannerTitle}
          description={pageSettings?.bannerDescription}
          backgroundImage={pageSettings?.bannerImage?.sourceUrl}
          size="md"
          showSearchBox={false}
        />
        <div className="container mb-10">
          <BreadCrumb items={breadcrumb} />
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              posts?.map((item: PostFieldsFragment) => (
                <BlogItem
                  key={item.id}
                  title={item.title as string}
                  slug={item.slug as string}
                  featuredImage={{
                    id: item.featuredImage?.node?.id ?? "",
                    altText: item.featuredImage?.node?.altText ?? "",
                    sourceUrl: item.featuredImage?.node?.sourceUrl ?? "",
                  }}
                  date={new Date(item.date as string)}
                  excerpt={item.excerpt as string}
                />
              ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  ${fragments.posts.POST_FIELDS}
  query GetArticlesPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      pageSettings {
        bannerTitle
        bannerDescription
        bannerImage {
          id
          altText
          sourceUrl
        }
      }
      seo {
        ...SeoFields
      }
    }
    posts(
      first: 100
      where: {
        status: PUBLISH
        hasPassword: false
        orderby: [{ field: DATE, order: DESC }]
      }
    ) {
      nodes {
        ...PostFields
      }
    }
  }
`;

export default Component;
