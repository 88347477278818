import { settings } from "@/config";

export default function seoStringParser(data: any) {
  data = data.replaceAll(settings.wpUrl, () => settings.frontendUrl);
  data = data.replaceAll(
    `${settings.frontendUrl}/app`,
    () => `${settings.wpUrl}/app`,
  );
  return data;
}
