import { Footer } from "../footer/footer";
import { Header, HeaderProps } from "../header/header";
import { DefaultLayout } from "./default-layout";
import { FullWidthLayout } from "./full-width-layout";

export interface LayoutProps {
  header?: HeaderProps;
  template?: "default" | "full-width";
  children: React.ReactNode;
}

export function Layout({ header, template, children }: LayoutProps) {
  switch (template) {
    case "full-width":
      return (
        <>
          <Header {...header} />
          <FullWidthLayout>{children}</FullWidthLayout>
          <Footer />
        </>
      );
    case "default":
    default:
      return (
        <>
          <Header {...header} />
          <DefaultLayout>{children}</DefaultLayout>
          <Footer />
        </>
      );
  }
}

export default Layout;
