import { GetAllToursQuery, TourFieldsFragment } from "@/__generated__/graphql";
import { queries } from "@/graphql/queries";
import { useGlobalStore } from "@/stores/global";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { ProductSlider } from "../product-slider/product-slider";
import { RecentlyViewedLoader } from "./recently-viewed-loader";

export function RecentlyViewed() {
  const recentlyViewed = useGlobalStore.getState().recentlyViewed;

  const [getTours, { loading, error, data }] = useLazyQuery<GetAllToursQuery>(
    queries.tours.QUERY_ALL_TOURS,
    {
      variables: {
        first: 10,
        orderby: { field: "IN", order: "DESC" },
        in: recentlyViewed,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    if (recentlyViewed.length) {
      getTours();
    }
  }, [getTours, recentlyViewed]);

  if (!recentlyViewed.length) return <></>;

  if (loading) return <RecentlyViewedLoader />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <ProductSlider
      title="Recently Viewed"
      type="tour"
      data={data?.tours?.nodes as TourFieldsFragment[]}
    />
  );
}

export default RecentlyViewed;
