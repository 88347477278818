import {
  TourFieldsFragment,
  TransportFieldsFragment,
} from "@/__generated__/graphql";
import { ProductItem, Section } from "@/components/elements";
import { SEE_MORE } from "@/constants";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";
import NextLink from "next/link";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface ProductSliderTour {
  title?: string;
  type: "tour";
  data: TourFieldsFragment[];
  seeMoreLink?: string;
}

interface ProductSliderTransport {
  title?: string;
  type: "transport";
  data: TransportFieldsFragment[];
  seeMoreLink?: string;
}

export type ProductSliderProps = ProductSliderTour | ProductSliderTransport;

export function ProductSlider({
  title = "Popular Activities",
  type,
  data,
  seeMoreLink,
}: ProductSliderProps) {
  return (
    <Section title={title}>
      <div className="container absolute left-0 right-0 top-0 hidden md:block">
        <div className="relative mb-4 flex items-start justify-end">
          <div className="flex gap-1">
            <Button
              radius="full"
              variant="bordered"
              className={"swiper-button-prev-popular-activities h-10 w-10 min-w-max"}
              aria-label="Prev"
              size="sm"
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              radius="full"
              variant="bordered"
              className={"swiper-button-next-popular-activities h-10 w-10 min-w-max"}
              aria-label="Next"
              size="sm"
            >
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
      <div className="md:container">
        <Swiper
          className="-mt-8 !py-8"
          navigation={{
            nextEl: ".swiper-button-next-popular-activities",
            prevEl: ".swiper-button-prev-popular-activities",
          }}
          modules={[Navigation]}
          spaceBetween={15}
          slidesPerView={1.25}
          slidesOffsetBefore={15}
          slidesOffsetAfter={15}
          breakpoints={{
            480: {
              slidesPerView: 2.25,
            },
            768: {
              slidesPerView: 3.25,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1024: {
              slidesPerView: 4.25,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          {data?.map((item) => (
              <SwiperSlide key={item.id}>
                <ProductItem
                  type={type}
                  id={item.id}
                  title={item.title as string}
                  slug={item.slug as string}
                  featuredImage={{
                    id: item.featuredImage?.node.id ?? "",
                    altText: item.featuredImage?.node.altText ?? "",
                    sourceUrl: item.featuredImage?.node.sourceUrl ?? "",
                  }}
                  price={Number(item.fields?.price)}
                  discountPrice={Number(item.fields?.discountPrice)}
                  rating={Number(item.fields?.rating)}
                  numReviews={Number(item.fields?.numReviews)}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        {seeMoreLink && (
          <div className="container flex justify-end">
            <Button
              as={NextLink}
              href={seeMoreLink}
              color="primary"
              variant="bordered"
            >
              {SEE_MORE}
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}

export default ProductSlider;
