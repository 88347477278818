import {
  GetSignleTourQuery,
  PostTypeSeo,
  TourFieldsFragment,
  TourFullFieldsFragment,
} from "@/__generated__/graphql";
import {
  BreadCrumb,
  BreadCrumbItem,
  ContentBlock,
  FavoriteButton,
  ProductCookingMenu,
  ProductFeatures,
  ProductGallery,
  ProductInclusive,
  ProductItinerary,
  ProductMeetingPoint,
  ProductPackageOption,
  ProductPrice,
  ProductTags,
  Rating,
  TripadvisorReviews,
} from "@/components/elements";
import {
  Button,
  Chip,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";

import { Layout } from "@/components/layout";
import { ProductSlider, RecentlyViewed } from "@/components/shared";
import { settings } from "@/config";
import { CHECK_AVAILABILITY } from "@/constants";
import { fragments } from "@/graphql/fragments";
import { useGlobalStore } from "@/stores/global";
import { CookingMenuType, TourType } from "@/types";
import { jsonParse } from "@/utils";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import parse from "html-react-parser";
import Head from "next/head";
import Image from "next/image";
import Script from "next/script";
import { useEffect } from "react";
import sanitizeHtml from "sanitize-html";

const Component: FaustTemplate<GetSignleTourQuery> = ({ data, loading }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const recentlyViewed = useGlobalStore.getState().recentlyViewed;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const tourId = data?.tour?.id as string;

    if (recentlyViewed.includes(tourId)) {
      const index = recentlyViewed.indexOf(tourId);
      recentlyViewed.splice(index, 1);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const newRecentlyViewed = [...new Set(recentlyViewed), tourId];
    newRecentlyViewed.unshift(tourId);
    newRecentlyViewed.splice(10); // max 10 items

    useGlobalStore.setState({
      recentlyViewed: newRecentlyViewed,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
  }, [data?.tour?.id, recentlyViewed]);

  // Loading state for previews
  if (loading) {
    return <>Loading...</>;
  }

  const seo = data?.tour?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    tour = data?.tour as TourFullFieldsFragment,
    relatedTours = tour?.relatedTours?.nodes as TourFieldsFragment[],
    destination = tour?.destinations?.nodes?.[0],
    frontendSettings = data?.frontendSettings?.generalSettings;

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: (destination?.name as string) || "Things to do",
      href: `/destination/${destination?.slug}` || "/things-to-do",
    },
    {
      label: tour?.title as string,
      href: `/articles/${tour?.slug}`,
    },
  ];

  const bookingForm = sanitizeHtml(tour?.fields?.bookingHtml || "", {
    allowedTags: ["div", "noscript"],
    allowedClasses: {
      div: ["bokunWidget"],
    },
    allowedAttributes: {
      div: ["data-src"],
    },
  });

  const ProductDetails = () => {
    return (
      <div className="my-6 md:sticky md:top-10">
        <div className="mb-4 flex flex-wrap items-center gap-2">
          {Number(tour.fields?.discountPrice) > 0 && (
            <Chip
              startContent={<CurrencyDollarIcon className="h-5 w-5" />}
              variant="flat"
              color="secondary"
            >
              Special Offer
            </Chip>
          )}
          {Number(tour.fields?.rating) >= 4.5 &&
            Number(tour.fields?.numReviews) > 15 && (
              <Chip
                startContent={
                  <Image
                    src="/assets/icons/badge.png"
                    className="m-0"
                    width={24}
                    height={24}
                    alt="Badge"
                  />
                }
                variant="flat"
                color="warning"
              >
                Badge of Excellence
              </Chip>
            )}
        </div>
        <h1 className="text-2xl font-semibold">{tour?.title}</h1>
        <div className="my-4">
          <Rating
            rating={Number(tour.fields?.rating) || 0}
            numReviews={Number(tour.fields?.numReviews || 0)}
            link={"#reviews"}
            linkTarget="_self"
            variant="default"
          />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(tour?.fields?.shortDescription || ""),
          }}
          className="text-gray-600"
        />
        <div className="my-4 flex items-center justify-between gap-2">
          <ProductPrice
            price={Number(tour?.fields?.price || 0)}
            discountPrice={Number(tour?.fields?.discountPrice || 0)}
            size="lg"
          />
          <Button
            color="primary"
            onPress={onOpen}
            className="fixed bottom-0 left-0 z-30 w-full rounded-none md:relative md:w-auto md:rounded-md"
          >
            {CHECK_AVAILABILITY}
          </Button>
          <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            shouldBlockScroll
          >
            <ModalContent>
              <ModalHeader className="flex flex-col gap-1">
                <span className="text-xl text-primary">
                  {CHECK_AVAILABILITY}
                </span>
              </ModalHeader>
              <ModalBody className="max-h-[70vh]">
                <div
                  dangerouslySetInnerHTML={{
                    __html: bookingForm,
                  }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
        <div className="mt-10">
          <ProductFeatures
            age={tour?.fields?.feature?.age || ""}
            capacity={Number(tour?.fields?.feature?.capacity || 0)}
            duration={tour?.fields?.feature?.duration || ""}
            hotelPickup={tour?.fields?.feature?.hotelPickup || false}
            mobileVoucher={tour?.fields?.feature?.mobileVoucher || false}
          />
        </div>
        <div className="mt-10">
          <ProductTags
            tags={
              tour.tags?.nodes.map((tag) => ({
                id: tag.id,
                name: tag.name || "",
                link: `/tourtag/${tag.slug}`,
                slug: tag.slug || "",
              })) || []
            }
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Head>
        <title>{tour?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo?.metaRobotsNoindex}, ${seo?.metaRobotsNofollow}`}
        />
        {seo?.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <div>
        <div itemType="https://schema.org/Product" itemScope>
          <meta itemProp="name" content={tour.title as string} />
          <link
            itemProp="image"
            href={
              tour?.featuredImage?.node?.sourceUrl ||
              settings.imagePlaceholder.sourceUrl
            }
          />
          <meta
            itemProp="description"
            content={(tour?.fields?.shortDescription as string) || ""}
          />
          <div itemProp="offers" itemType="https://schema.org/Offer" itemScope>
            <link
              itemProp="url"
              href={`https://www.oh-hoo.com/tour/${tour.slug}`}
            />
            <meta
              itemProp="availability"
              content="https://schema.org/InStock"
            />
            <meta itemProp="priceCurrency" content="THB" />
            <meta
              itemProp="itemCondition"
              content="https://schema.org/NewCondition"
            />
            <meta
              itemProp="price"
              content={(tour?.fields?.price as unknown as string) || "0"}
            />
          </div>
          {Number(tour?.fields?.numReviews) > 0 &&
            Number(tour?.fields?.rating) > 0 && (
              <div
                itemProp="aggregateRating"
                itemType="https://schema.org/AggregateRating"
                itemScope
              >
                <meta
                  itemProp="reviewCount"
                  content={
                    (tour?.fields?.numReviews as unknown as string) || "0"
                  }
                />
                <meta
                  itemProp="ratingValue"
                  content={(tour?.fields?.rating as unknown as string) || "0"}
                />
              </div>
            )}
          <meta
            itemProp="sku"
            content={tour?.databaseId as unknown as string}
          />
        </div>
      </div>
      <Layout
        header={{
          sticky: false,
        }}
        template="full-width"
      >
        <article className="container">
          <BreadCrumb items={breadcrumb} />
          <div className="md:grid md:grid-cols-12 md:gap-8">
            <div className="md:col-span-7">
              <div className="relative">
                {tour?.fields?.gallery && (
                  <ProductGallery
                    images={tour?.fields?.gallery?.map((item) => ({
                      id: item?.id as string,
                      sourceUrl: item?.sourceUrl as string,
                      altText: item?.altText as string,
                    }))}
                  />
                )}
                <div className="absolute right-4 top-12 z-10">
                  <FavoriteButton id={tour.id} />
                </div>
              </div>
              <div className="block md:hidden">
                <ProductDetails />
              </div>
              <div className="my-10 grid gap-10">
                <ContentBlock
                  heading="Overview"
                  content={tour.content as string}
                />
                <ProductPackageOption
                  options={tour.fields?.packageOptions as []}
                />
                <ProductInclusive
                  includes={tour.fields?.included as string}
                  excludes={tour.fields?.notIncluded as string}
                />
                <ProductMeetingPoint
                  tourType={tour.fields?.tourType as TourType}
                  meetingPoints={tour.fields?.meetingPoint as []}
                  itineraries={tour.fields?.itinerary as []}
                  description={tour.fields?.meetingPointDescription as string}
                />
                <ProductItinerary
                  meetingPoints={(tour.fields?.meetingPoint as []) || []}
                  itineraries={(tour.fields?.itinerary as []) || []}
                />
                <ProductCookingMenu
                  cookingMenu={tour.fields?.cookingMenu as CookingMenuType[]}
                />
                <ContentBlock
                  heading="What To Expect"
                  content={tour.fields?.whatToExpect as string}
                />
                <ContentBlock
                  heading="Additional Information"
                  content={tour.fields?.additionalInformation as string}
                />
                <ContentBlock
                  heading="Cancellation Policy"
                  content={frontendSettings?.cancellationPolicy as string}
                />
                <ContentBlock
                  heading="Notification Confirmation"
                  content={frontendSettings?.notificationConfirmation as string}
                />
              </div>
            </div>
            <div className="hidden md:col-span-5 md:block">
              <ProductDetails />
            </div>
          </div>
        </article>
        {relatedTours && (
          <ProductSlider
            title="Similar Things To Do"
            type="tour"
            data={relatedTours}
          />
        )}
        <RecentlyViewed />
        <TripadvisorReviews
          title="Reviews"
          source="travel-advisor"
          style="default"
          details={jsonParse(tour?.fields?.tripadvisorDetails as string)}
          reviews={jsonParse(tour?.fields?.tripadvisorReviews as string)}
          showDetails={false}
        />
      </Layout>
      <Script src="https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=c60a3174-16a5-4e1b-8bbf-43eae1401b3c" />
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  ${fragments.tours.TOUR_FULL_FIELDS}
  query GetSignleTour($databaseId: ID!, $asPreview: Boolean = false) {
    tour(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      seo {
        ...SeoFields
      }
      ...TourFullFields
    }
    frontendSettings {
      generalSettings {
        cancellationPolicy
        notificationConfirmation
      }
    }
  }
`;

export default Component;
