import { settings } from "@/config";
import { MainMenu } from "./main-menu/main-menu";
import { MobileMenu } from "./mobile-menu/mobile-menu";
import { TopMenu } from "./top-menu/top-menu";

export interface HeaderProps {
  sticky?: boolean;
}

export function Header({ sticky = true }: HeaderProps) {
  console.count("Header");
  return (
    <div className={`bg-white shadow-lg ${sticky ? "sticky top-0 z-30" : ""}`}>
      <TopMenu logo={settings.logo} menuItems={settings.header.topMenu} />
      <MainMenu menuItems={settings.header.mainMenu} />
      <MobileMenu logo={settings.logo} menuItems={settings.header.mobileMenu} />
    </div>
  );
}

export default Header;
