import { SKIIP_LINK_SELECTORS } from "@/constants";

export interface DefaultLayoutProps {
  children: React.ReactNode;
}

export function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <main id={SKIIP_LINK_SELECTORS} className="container">
      {children}
    </main>
  );
}

export default DefaultLayout;
