import {
  GetDestinationPageQuery,
  PostTypeSeo,
  TourFieldsFragment,
} from "@/__generated__/graphql";
import { Banner } from "@/components/banner/banner";
import { ProductItem } from "@/components/elements";
import { Layout } from "@/components/layout";
import { settings } from "@/config";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button, Card, CardFooter } from "@nextui-org/react";
import parse from "html-react-parser";
import Head from "next/head";

import { fragments } from "@/graphql/fragments";
import Image from "next/image";
import Link from "next/link";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Component: FaustTemplate<GetDestinationPageQuery> = ({ data }) => {
  const pageSettings = data?.page?.pageSettings,
    seo = data?.page?.seo as PostTypeSeo,
    destinationCategories = data?.destinationCategories?.nodes,
    fullHead = parse(seoStringParser(seo?.fullHead ?? ""));

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <Banner
          title={pageSettings?.bannerTitle ?? ""}
          description={pageSettings?.bannerDescription ?? ""}
          backgroundImage={pageSettings?.bannerImage?.sourceUrl ?? ""}
          size="md"
        />
        <div className="md:container">
          {destinationCategories?.map((item, index) => (
            <section key={item.id} className="my-10">
              <div className="container">
                <div className="relative mb-4 flex items-start justify-end">
                  <div className="flex gap-1">
                    <Button
                      radius="full"
                      variant="bordered"
                      className={`swiper-button-prev-${index} h-10 w-10 min-w-max`}
                      aria-label="Prev"
                      size="sm"
                    >
                      <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                      radius="full"
                      variant="bordered"
                      className={`swiper-button-next-${index} h-10 w-10 min-w-max`}
                      aria-label="Next"
                      size="sm"
                    >
                      <ChevronRightIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
              <Swiper
                className="-my-8 !py-8"
                spaceBetween={15}
                slidesPerView={1.25}
                navigation={{
                  nextEl: `.swiper-button-next-${index}`,
                  prevEl: `.swiper-button-prev-${index}`,
                }}
                modules={[Navigation]}
                slidesOffsetBefore={15}
                slidesOffsetAfter={15}
                breakpoints={{
                  768: {
                    slidesPerView: 3.25,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                  },
                  1024: {
                    slidesPerView: 4.25,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                  },
                }}
              >
                <SwiperSlide className="!h-auto">
                  <Link
                    href={`/destination/${item.slug}`}
                    className="block h-full"
                  >
                    <Card className="relative z-0 h-full">
                      <Image
                        src={
                          item.destinationExtra?.featuredImage?.sourceUrl ||
                          settings.imagePlaceholder.sourceUrl
                        }
                        alt={
                          item.destinationExtra?.featuredImage?.altText ||
                          settings.imagePlaceholder.altText
                        }
                        width={330}
                        height={420}
                        className="h-full w-full object-cover"
                      />
                      <div className="absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-b from-transparent to-gray-900/40" />
                      <CardFooter className="absolute bottom-0 left-0 z-10 block p-6">
                        <h4 className="wrapper line-2 mb-2 text-2xl font-medium capitalize leading-tight text-white sm:text-2xl sm:leading-tight">
                          {item.name}
                        </h4>
                        <span className="text-xl font-light text-white">
                          Explore{" "}
                          <ChevronRightIcon className="inline-block h-4 w-4" />
                        </span>
                      </CardFooter>
                    </Card>
                  </Link>
                </SwiperSlide>
                {// eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                item?.tours?.nodes?.map((tour: TourFieldsFragment) => (
                  <SwiperSlide key={tour.id}>
                    <ProductItem
                      type="tour"
                      id={tour.id}
                      title={tour.title as string}
                      slug={tour.slug as string}
                      featuredImage={{
                        id: tour.featuredImage?.node.id ?? "",
                        altText: tour.featuredImage?.node?.altText ?? "",
                        sourceUrl: tour.featuredImage?.node?.sourceUrl ?? "",
                      }}
                      price={Number(tour.fields?.price)}
                      discountPrice={Number(tour.fields?.discountPrice)}
                      rating={Number(tour.fields?.rating)}
                      numReviews={Number(tour.fields?.numReviews)}
                    />
                  </SwiperSlide>
                ))}
                <SwiperSlide className="!h-auto">
                  <Link
                    href={`/destination/${item.slug}`}
                    className="block h-full"
                  >
                    <Card className="relative z-0 h-full">
                      <Image
                        src={
                          item.destinationExtra?.featuredImage?.sourceUrl ||
                          settings.imagePlaceholder.sourceUrl
                        }
                        alt={
                          item.destinationExtra?.featuredImage?.altText ||
                          settings.imagePlaceholder.altText
                        }
                        width={330}
                        height={420}
                        className="h-full w-full object-cover"
                      />
                      <div className="absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-b from-transparent to-gray-900/40 backdrop-blur-sm" />
                      <CardFooter className="absolute bottom-0 left-0 z-10 h-full w-full items-center justify-center p-6 text-center">
                        <div>
                          <h4 className="wrapper line-2 mb-4 text-2xl font-medium capitalize leading-tight text-white sm:text-2xl sm:leading-tight">
                            {item.name}
                          </h4>
                          <Button
                            variant="bordered"
                            className="px-6"
                            size="sm"
                            radius="full"
                          >
                            <span className="text-white">
                              Explore{" "}
                              <ChevronRightIcon className="inline-block h-4 w-4" />
                            </span>
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </section>
          ))}
        </div>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  ${fragments.tours.TOUR_FIELDS}
  query GetDestinationPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      pageSettings {
        bannerTitle
        bannerDescription
        bannerImage {
          id
          altText
          sourceUrl
        }
      }
      seo {
        ...SeoFields
      }
    }
    destinationCategories(first: 100, where: { orderby: NAME }) {
      nodes {
        id
        name
        slug
        destinationExtra {
          featuredImage {
            id
            altText
            sourceUrl
          }
        }
        tours(
          first: 12
          where: { hasPassword: false, orderby: { field: REVIEW, order: DESC } }
        ) {
          nodes {
            ...TourFields
          }
        }
      }
    }
  }
`;

export default Component;
