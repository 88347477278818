export function scrollToSection(id: string, offset = 20) {
  const isBrowser = () => typeof window !== "undefined";

  if (!isBrowser()) return;

  const section = document.querySelector("#" + id);

  if (section) {
    const offsetTop = section.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: offsetTop - offset,
      behavior: "smooth",
    });
  }
}
