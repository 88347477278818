import { settings } from "@/config";
import { ACTIVITIES, ACTIVITY } from "@/constants";
import { FeaturedImage } from "@/types/featured-image";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import Image from "next/image";
import Link from "next/link";

export interface DestinationItemProps {
  name: string;
  slug: string;
  count: number;
  featuredImage?: FeaturedImage;
}

export function DestinationItem({
  name,
  slug,
  count,
  featuredImage,
}: DestinationItemProps) {
  const url = `/destination/${slug}`;

  return (
    <Card shadow="none" radius="none">
      <CardHeader className="w-full flex-col items-start p-0">
        <Link href={url} title={name}>
          <Image
            src={
              featuredImage?.sourceUrl || settings.imagePlaceholder.sourceUrl
            }
            alt={featuredImage?.altText || settings.imagePlaceholder.altText}
            width={400}
            height={300}
            className="aspect-square w-full rounded-lg object-cover"
          />
        </Link>
      </CardHeader>
      <CardBody className="mt-3 p-0">
        <Link href={url} title={name}>
          <h3 className="text-xl font-medium leading-snug text-primary">
            {name}
          </h3>
        </Link>
        <span className="text-sm text-gray-400">
          {count > 1 ? count + " " + ACTIVITIES : count + " " + ACTIVITY}
        </span>
      </CardBody>
    </Card>
  );
}
