import { GetAllToursQuery, TourFieldsFragment } from "@/__generated__/graphql";
import {
  ProductItem,
  ProductItemLoader,
  ProductListItem,
  ProductListItemLoader,
} from "@/components/elements";
import { settings } from "@/config";
import { LOAD_MORE } from "@/constants";
import { queries } from "@/graphql/queries";
import { TSortby, TaxArray, TaxOperator } from "@/types";
import { useQuery } from "@apollo/client";
import { Button } from "@nextui-org/react";
import { ProductFilter } from "../product-filter";
import {
  NoMoreResults,
  NoResults,
  ProductList,
  handleSortby,
} from "../product-list";
import { ProductSorting } from "../product-sorting";

import { useGlobalStore } from "@/stores/global";
import clsx from "clsx";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export function ProductTourList({
  tag,
  category,
  sortby,
  order,
  setOrder,
  setSortby,
  defaultDestination,
}: ProductList) {
  const searchParams = useSearchParams();
  const viewMode = useGlobalStore((state) => state.viewMode);

  const variables = {
    first: settings.postPerPage,
    after: "",
    before: "",
    search: "",
    taxArray: [] as TaxArray[],
    orderby: { field: sortby, order: order },
    priceRange: `${queries.tours.PRICE_RANGE}`,
  };

  if (defaultDestination) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "DESTINATIONCATEGORY",
      terms: [defaultDestination],
      operator: TaxOperator.IN,
    });
  }

  const keyword = searchParams.get("keyword");
  const destinations = searchParams.get("destinations");
  const categories = searchParams.get("categories");
  const prices = searchParams.get("prices");

  if (tag) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "TOURTAG",
      terms: [tag],
      operator: TaxOperator.IN,
    });
  }

  if (category) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "TOURCATEGORY",
      terms: [category],
      operator: TaxOperator.IN,
    });
  }

  if (keyword) {
    variables.search = keyword;
  }

  if (destinations) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "DESTINATIONCATEGORY",
      terms: destinations.split(","),
      operator: TaxOperator.IN,
    });
  }

  if (categories) {
    variables.taxArray.push({
      field: "SLUG",
      taxonomy: "TOURCATEGORY",
      terms: categories.split(","),
      operator: TaxOperator.IN,
    });
  }

  if (prices) {
    const [minPrice, maxPrice] = prices.split(",").map(Number);

    variables.priceRange = `${minPrice},${maxPrice}`;

    if (maxPrice >= 7000) {
      variables.priceRange = `${minPrice},${queries.tours.PRICE_RANGE[1]}`;
    }
  }

  const { loading, error, data, refetch, fetchMore } =
    useQuery<GetAllToursQuery>(queries.tours.QUERY_ALL_TOURS, {
      variables,
      notifyOnNetworkStatusChange: true,
    });

  const { nodes: tours, pageInfo } = data?.tours || {};

  /**
   * Handles the "Load More" functionality in the product list.
   * Fetches more data from the server and updates the list with the new data.
   */
  function handleLoadMore() {
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.tours?.nodes?.length) {
          return prev;
        }

        const prevData = prev?.tours?.nodes || [];

        return {
          tours: {
            ...fetchMoreResult.tours,
            nodes: [...prevData, ...fetchMoreResult.tours.nodes],
          },
        };
      },
    });
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      useGlobalStore.setState({ viewMode: "grid" });
    }
  }, []);

  return (
    <div id="product-list" className="container my-10">
      <div className="grid md:grid-cols-12 md:gap-10">
        <div className="relative md:col-span-3">
          <ProductFilter
            type="tour"
            hideDestinations={defaultDestination ? true : false}
            hideCategories={category ? true : false}
            keyword={keyword || ""}
            destinations={destinations?.split(",")}
            categories={categories?.split(",")}
            prices={prices?.split(",").map(Number) as [number, number]}
          />
        </div>
        <div className="md:col-span-9">
          <ProductSorting
            value={sortby}
            total={pageInfo?.total || 0}
            onChange={(e) =>
              handleSortby(
                e.target.value as TSortby,
                order,
                setOrder,
                setSortby,
                refetch,
              )
            }
          />
          <div
            className={clsx("mb-10 grid gap-6", {
              "md:grid-cols-3": viewMode === "grid",
            })}
          >
            {loading && !data
              ? Array.from({ length: 10 }).map((_, index) => {
                  if (viewMode === "grid") {
                    return <ProductItemLoader key={index} />;
                  }
                  return <ProductListItemLoader key={index} />;
                })
              : data &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                tours?.map((tour: TourFieldsFragment) => {
                  if (viewMode === "grid") {
                    return (
                      <ProductItem
                        key={tour.id}
                        id={tour.id}
                        type="tour"
                        title={tour.title as string}
                        slug={tour.slug as string}
                        featuredImage={{
                          id: tour.featuredImage?.node.id ?? "",
                          altText: tour.featuredImage?.node.altText ?? "",
                          sourceUrl: tour.featuredImage?.node.sourceUrl ?? "",
                        }}
                        price={Number(tour.fields?.price)}
                        discountPrice={Number(tour.fields?.discountPrice)}
                        rating={Number(tour.fields?.rating)}
                        numReviews={Number(tour.fields?.numReviews)}
                      />
                    );
                  }
                  return (
                    <ProductListItem
                      key={tour.id}
                      type="tour"
                      id={tour.id}
                      title={tour.title as string}
                      slug={tour.slug as string}
                      featuredImage={{
                        id: tour.featuredImage?.node.id || "",
                        altText: tour.featuredImage?.node.altText || "",
                        sourceUrl: tour.featuredImage?.node.sourceUrl || "",
                      }}
                      price={Number(tour.fields?.price)}
                      discountPrice={Number(tour.fields?.discountPrice)}
                      rating={Number(tour.fields?.rating)}
                      numReviews={Number(tour.fields?.numReviews)}
                      excerpt={(tour.fields?.shortDescription as string) || ""}
                      features={{
                        age: tour?.fields?.feature?.age || "",
                        capacity: Number(tour?.fields?.feature?.capacity),
                        duration: tour?.fields?.feature?.duration || "",
                        hotelPickup:
                          tour?.fields?.feature?.hotelPickup || false,
                        mobileVoucher:
                          tour?.fields?.feature?.mobileVoucher || false,
                      }}
                    />
                  );
                })}
          </div>
          {pageInfo?.hasNextPage && (
            <div className="text-center">
              <Button
                color="primary"
                onClick={() => handleLoadMore()}
                isLoading={loading}
              >
                {LOAD_MORE}
              </Button>
            </div>
          )}
          {Number(pageInfo?.total) > 0 && !pageInfo?.hasNextPage && (
            <NoMoreResults />
          )}
          {!loading && data && tours?.length === 0 && <NoResults />}
        </div>
      </div>
    </div>
  );
}

export default ProductTourList;
