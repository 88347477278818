import { useGlobalStore } from "@/stores/global";
import { ListBulletIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";

export interface ProductSwitchViewModeButtonProps
  extends React.HTMLProps<HTMLDivElement> {}

function switchViewMode(viewMode: "grid" | "list") {
  useGlobalStore.setState({ viewMode });
}

export function ProductSwitchViewModeButton(
  props: ProductSwitchViewModeButtonProps,
) {
  const viewMode = useGlobalStore((state) => state.viewMode);
  return (
    <div {...props}>
      <Button
        isIconOnly
        color="primary"
        aria-label="list"
        variant={viewMode === "list" ? "solid" : "bordered"}
        className="rounded-r-none"
        onPress={() => switchViewMode("list")}
      >
        <ListBulletIcon className="h-6 w-6" />
      </Button>
      <Button
        isIconOnly
        color="primary"
        aria-label="grid"
        variant={viewMode === "grid" ? "solid" : "bordered"}
        className="rounded-l-none"
        onPress={() => switchViewMode("grid")}
      >
        <Squares2X2Icon className="h-6 w-6" />
      </Button>
    </div>
  );
}

export default ProductSwitchViewModeButton;
