import { useGlobalStore } from "@/stores/global";
import { Select, SelectItem, SelectedItems } from "@nextui-org/react";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";
import Image from "next/image";
import { ChangeEvent, useEffect } from "react";

declare global {
  interface Window {
    google: any;
    googleTranslateElementInit: any;
  }
}

type Language = {
  id: number;
  title: string;
  slug: "en" | "th" | "zh-CN";
  code: string;
  icon: string;
};

const languages: Language[] = [
  {
    id: 1,
    title: "English",
    slug: "en",
    code: "/auto/en",
    icon: "/assets/icons/us-flag.svg",
  },
  {
    id: 2,
    title: "ภาษาไทย",
    slug: "th",
    code: "/auto/th",
    icon: "/assets/icons/th-flag.svg",
  },
  {
    id: 3,
    title: "简体中文",
    slug: "zh-CN",
    code: "/auto/zh-CN",
    icon: "/assets/icons/cn-flag.svg",
  },
];

export function LanguageSwitcher() {
  const currentLanguage = useGlobalStore((state) => state.currentLanguage);

  const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (hasCookie("googtrans")) {
      useGlobalStore.setState({
        currentLanguage: e.target.value as "en" | "th" | "zh-CN",
      });

      // get site domain
      const hostname = window.location.hostname;

      // get main domain name
      const domain = hostname.split(".").slice(-2).join(".");

      deleteCookie("googtrans");
      deleteCookie("googtrans", {
        domain: domain,
      });
      deleteCookie("googtrans", {
        domain: "." + domain,
      });
      setCookie("googtrans", "/auto/" + e.target.value);
    }
    window.location.reload();
  };

  const googtrans = hasCookie("googtrans");

  useEffect(() => {
    if (hasCookie("googtrans")) {
      if (getCookie("googtrans") === "/auto/en") {
        useGlobalStore.setState({
          currentLanguage: "en",
        });
      } else if (getCookie("googtrans") === "/auto/th") {
        useGlobalStore.setState({
          currentLanguage: "th",
        });
      } else if (getCookie("googtrans") === "/auto/zh-CN") {
        useGlobalStore.setState({
          currentLanguage: "zh-CN",
        });
      }
    } else {
      setCookie("googtrans", "/auto/en");
    }
  }, [googtrans]);

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        includedLanguages: "en,th,zh-CN", // If you remove it, by default all google supported language will be included
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element",
    );
  };

  return (
    <>
      <Select
        aria-label={"Select language"}
        items={languages}
        selectedKeys={new Set([currentLanguage])}
        onChange={handleSelectionChange}
        labelPlacement="outside"
        classNames={{
          base: "max-w-full",
          mainWrapper: "w-[8.6rem]",
          trigger: "bg-transparent shadow-none",
        }}
        renderValue={(items: SelectedItems<Language>) => {
          return items.map((item) => (
            <div key={item.data?.slug} className="flex items-center gap-2">
              <Image
                src={item.data?.icon as string}
                alt={item.data?.title as string}
                width={20}
                height={20}
              />
              <div className="flex flex-col">
                <span className="notranslate font-medium">
                  {item.data?.title}
                </span>
              </div>
            </div>
          ));
        }}
      >
        {(language) => (
          <SelectItem key={language.slug} textValue={language.title}>
            <div className="flex items-center gap-2">
              <Image
                src={language.icon}
                alt={language.title}
                width={20}
                height={20}
              />
              <div className="flex flex-col">
                <span className="notranslate text-small font-medium">
                  {language.title}
                </span>
              </div>
            </div>
          </SelectItem>
        )}
      </Select>
    </>
  );
}

export default LanguageSwitcher;
