import { settings } from "@/config";
import { ProductType } from "@/types";
import { FeaturedImage } from "@/types/featured-image";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { ItemStyles, Rating, ThinRoundedStar } from "@smastrom/react-rating";
import Image from "next/image";
import Link from "next/link";
import { FavoriteButton } from "../favorite-button/favorite-button";
import { ProductPrice } from "../product-price/product-price";

export interface ProductItemProps {
  type: ProductType;
  id: string;
  title: string;
  slug: string;
  price: number;
  discountPrice?: number;
  featuredImage?: FeaturedImage;
  rating?: number;
  numReviews?: number;
}

const styles: ItemStyles = {
  itemShapes: ThinRoundedStar,
  activeFillColor: "#ff5425",
  inactiveFillColor: "#e9e9e9",
};

export function ProductItem({
  type,
  id,
  title,
  slug,
  price,
  discountPrice,
  featuredImage,
  rating,
  numReviews,
}: ProductItemProps) {
  const url = type === "tour" ? `/tour/${slug}` : `/transport/${slug}`;

  return (
    <Card>
      <CardHeader className="aspect-[4/3] w-full flex-col items-start bg-gray-50 p-0">
        <Link href={url} title={title} className="h-full w-full">
          <Image
            src={
              featuredImage?.sourceUrl || settings.imagePlaceholder.sourceUrl
            }
            alt={featuredImage?.altText || settings.imagePlaceholder.altText}
            width={400}
            height={300}
            className="aspect-[4/3] h-full w-full object-cover"
          />
        </Link>
        {type === "tour" && (
          <div className="absolute right-2 top-2">
            <FavoriteButton id={id} />
          </div>
        )}
      </CardHeader>
      <CardBody className="p-6">
        <Link href={url} title={title}>
          <h3 className="mb-4 line-clamp-2 h-11 font-semibold leading-snug">
            {title}
          </h3>
        </Link>
        <div className="mb-4 flex h-4 items-center gap-2">
          <Rating
            style={{ maxWidth: 120 }}
            value={rating || 0}
            halfFillMode="svg"
            readOnly
            itemStyles={styles}
          />
          <Link
            href={url}
            title={title}
            className="text-xs leading-none no-underline hover:underline lg:text-sm"
          >
            {numReviews}
          </Link>
        </div>
        <ProductPrice price={price} discountPrice={discountPrice} />
      </CardBody>
    </Card>
  );
}

export default ProductItem;
