import { settings } from "@/config";
import Image from "next/image";

export interface FindUsOnProps {}

export function FindUsOn(props: FindUsOnProps) {
  return (
    <section className="container my-16">
      <h2 className="mb-6 text-center text-2xl font-medium text-primary sm:text-3xl">
        Find us on
      </h2>
      <div className="flex flex-wrap justify-center [&>*]:w-4/12 sm:[&>*]:w-1/6">
        {settings.findUsOn.map((logo, key) => (
          <div key={key} className="p-3 text-center">
            <Image
              src={logo.src}
              alt={logo.alt}
              width={180}
              height={100}
              className="aspect-w-video mx-auto h-16 object-contain"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default FindUsOn;
