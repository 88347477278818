import { SKIIP_LINK_SELECTORS } from "@/constants";

export default function SkipNavigationLink() {
  return (
    <a
      href={`#${SKIIP_LINK_SELECTORS}`}
      className="text-primary-content absolute left-0 m-3 -translate-y-16 bg-primary p-3 transition focus:translate-y-0"
    >
      Skip To Main Content
    </a>
  );
}
