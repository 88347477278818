import { settings } from "@/config";
import { FeaturedImage } from "@/types/featured-image";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import Image from "next/image";
import Link from "next/link";
import sanitizeHtml from "sanitize-html";
import { formatDate } from "../../../utils";

export interface BlogItemProps {
  title: string;
  slug: string;
  date: Date;
  featuredImage?: FeaturedImage;
  excerpt: string;
}

export function BlogItem({
  title,
  slug,
  date,
  excerpt,
  featuredImage,
}: BlogItemProps) {
  const url = `/articles/${slug}`;
  const sanitizeExcerpt = sanitizeHtml(excerpt);

  return (
    <article>
      <Card>
        <CardHeader className="aspect-video w-full flex-col items-start bg-gray-50 p-0">
          <Link href={url} title={title}>
            <figure>
              <Image
                src={
                  featuredImage?.sourceUrl ||
                  settings.imagePlaceholder.sourceUrl
                }
                alt={
                  featuredImage?.altText || settings.imagePlaceholder.altText
                }
                width={400}
                height={300}
                className="aspect-video w-full object-cover"
              />
            </figure>
          </Link>
        </CardHeader>
        <CardBody className="p-6">
          <time
            className="mb-2 block text-sm text-gray-500"
            dateTime={date.toString()}
          >
            {formatDate(date)}
          </time>
          <Link href={url} title={title}>
            <h3 className="mb-3 line-clamp-2 h-11 font-semibold leading-snug">
              {title}
            </h3>
          </Link>
          <p
            className="wrapper line-clamp-2 text-sm text-gray-500"
            dangerouslySetInnerHTML={{ __html: sanitizeExcerpt }}
          />
        </CardBody>
      </Card>
    </article>
  );
}

export default BlogItem;
