import { gql } from "@apollo/client";

export const GF_FORM_FIELDS = gql`
  fragment GfFormFields on GfForm {
    id
    formId
    title
    isActive
    version
    hasHoneypot
    formFields {
      edges {
        node {
          id
          type
          ... on NameField {
            id
            type
            label
            isRequired
            description
            errorMessage
            inputs {
              id
              label
              ... on NameInputProperty {
                id
                name
                key
                placeholder
                isHidden
                choices {
                  text
                  value
                }
              }
            }
          }
          ... on EmailField {
            id
            type
            label
            value
            placeholder
            isRequired
            description
            errorMessage
          }
          ... on TextField {
            id
            type
            label
            value
            defaultValue
            placeholder
            maxLength
            isRequired
            description
            errorMessage
          }
          ... on TextAreaField {
            id
            type
            label
            value
            defaultValue
            placeholder
            maxLength
            isRequired
            description
            errorMessage
          }
          ... on SelectField {
            id
            type
            label
            value
            defaultValue
            placeholder
            isRequired
            description
            errorMessage
            choices {
              text
              value
            }
          }
          ... on CaptchaField {
            id
            type
            label
            value
            description
            errorMessage
            captchaBadgePosition
            captchaLanguage
            captchaTheme
            captchaType
            simpleCaptchaBackgroundColor
            simpleCaptchaFontColor
            simpleCaptchaSize
          }
        }
      }
    }
    submitButton {
      type
      text
    }
    confirmations {
      name
      message
      isDefault
      isAutoformatted
      isActive
      type
    }
  }
`;
