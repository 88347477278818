import { SearchToursQuery } from "@/__generated__/graphql";
import { settings } from "@/config";
import { queries } from "@/graphql/queries";
import { FeaturedImage } from "@/types/featured-image";
import { useLazyQuery } from "@apollo/client";
import { MagnifyingGlassIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Autocomplete, AutocompleteItem, Button } from "@nextui-org/react";
import clsx from "clsx";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export interface BannerProps {
  title: string | null | undefined;
  description?: string | null;
  backgroundImage?: string | null;
  showSearchBox?: boolean;
  size?: "sm" | "md" | "lg";
}

export function Banner({
  title,
  description,
  backgroundImage,
  showSearchBox = settings.banner.default.showSearchBox,
  size = settings.banner.default.size,
}: BannerProps) {
  return (
    <div
      className={clsx("relative overflow-hidden", {
        "h-[250px]": size === "sm",
        "h-[400px]": size === "md",
        "h-[600px]": size === "lg",
      })}
    >
      <Image
        className="absolute left-0 top-0 z-0 h-full w-full object-cover"
        src={
          backgroundImage || (settings.banner.default.backgroundImage as string)
        }
        alt={title || (settings.banner.default.title as string)}
        width={2000}
        height={600}
        priority
      />
      <div className="relative z-10 grid h-full w-full content-center items-center">
        <div className="container text-center">
          <h2 className="mb-4 text-3xl font-medium text-white sm:text-5xl">
            {title || settings.banner.default.title}
          </h2>
          <p
            className={clsx(" text-base leading-tight text-white sm:text-xl", {
              "mb-0": !showSearchBox,
              "mb-10": showSearchBox,
            })}
          >
            {description || settings.banner.default.description}
          </p>
          {showSearchBox && <SearchBox />}
        </div>
      </div>
      <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-b from-gray-900/80 to-transparent" />
    </div>
  );
}

interface ResultType {
  type: "tour" | "destination";
  title: string;
  slug: string;
  destination?: string;
  featuredImage?: FeaturedImage;
}

const PLACEHOLDER = "Destination, City";

function SearchBox() {
  const searchParams = useSearchParams();
  const rounter = useRouter();
  const [search, setSearch] = useState<string>("");
  const [results, setResults] = useState<ResultType[]>([]);
  const [searchTour, { loading, data, error }] = useLazyQuery<SearchToursQuery>(
    queries.tours.SEARCH_ALL_TOURS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

  const keyword = searchParams.get("keyword") || "";

  useEffect(() => {
    if (keyword) {
      setSearch(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    if (data) {
      const tours = data?.tours?.nodes.map((tour) => ({
        type: "tour",
        title: tour.title,
        slug: tour.slug,
        destination: tour?.destinations?.nodes[0]?.name,
        featuredImage: tour?.featuredImage?.node,
      })) as ResultType[] | [];

      const destinations = data?.destinationCategories?.nodes.map(
        (destination) => ({
          type: "destination",
          title: destination.name,
          slug: destination.slug,
        }),
      ) as ResultType[] | [];

      setResults([...destinations, ...tours]);
    }
  }, [data]);

  const handleOnChange = async (value: string) => {
    setSearch(value);
    if (value.length < 3) return;
    await new Promise((resolve) => setTimeout(resolve, 500));
    await searchTour({
      variables: {
        first: 10,
        where: {
          search: value,
          orderby: [{ field: "REVIEW", order: "DESC" }],
        },
        where2: {
          search: value,
        },
      },
    });
  };

  const handleToSearchResults = () => {
    rounter.push(`/search-results?keyword=${search}`);
  };

  return (
    <div className="relative mx-auto flex max-w-2xl justify-center gap-2 rounded-lg bg-white p-1">
      <Autocomplete
        classNames={{
          base: "w-full",
          listboxWrapper: "max-h-[320px]",
          selectorButton: "text-default-500",
        }}
        allowsCustomValue
        inputValue={search}
        isLoading={loading}
        onInputChange={handleOnChange}
        items={results}
        inputProps={{
          classNames: {
            input: "md:ml-1",
            inputWrapper: "h-[48px] bg-white shadow-none md:pr-36 pr-16",
          },
        }}
        listboxProps={{
          hideSelectedIcon: true,
          itemClasses: {
            base: [
              "text-default-500",
              "transition-opacity",
              "data-[hover=true]:text-foreground",
              "dark:data-[hover=true]:bg-default-50",
              "data-[pressed=true]:opacity-70",
              "data-[hover=true]:bg-default-200",
              "data-[selectable=true]:focus:bg-default-100",
              "data-[focus-visible=true]:ring-default-500",
            ],
          },
        }}
        aria-label={PLACEHOLDER}
        placeholder={PLACEHOLDER}
        popoverProps={{
          offset: 10,
          classNames: {
            content: "p-1 border-small border-default-100 bg-background",
          },
        }}
        startContent={
          <MagnifyingGlassIcon className="hidden h-5 w-5 text-primary md:block" />
        }
        variant="flat"
      >
        {(item) => (
          <AutocompleteItem
            key={item.slug}
            textValue={item.title}
            onPress={() =>
              rounter.push(
                item.type === "tour"
                  ? `/tour/${item.slug}`
                  : `/destination/${item.slug}`,
              )
            }
          >
            <div className="flex items-center justify-between">
              <div className="flex min-h-[2rem] items-center gap-2">
                {item.type === "tour" && (
                  <>
                    <Image
                      src={
                        item?.featuredImage?.sourceUrl ||
                        settings.imagePlaceholder.sourceUrl
                      }
                      width={40}
                      height={40}
                      alt={item.title}
                      className="aspect-square rounded-medium object-cover"
                    />
                    <div className="flex flex-col">
                      <span className="text-small">{item.title}</span>
                      <span className="text-tiny text-default-400">
                        {item.destination}
                      </span>
                    </div>
                  </>
                )}
                {item.type === "destination" && (
                  <>
                    <MapPinIcon className="h-4 w-4" />
                    <div className="flex flex-col">
                      <span className="text-small">{item.title}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </AutocompleteItem>
        )}
      </Autocomplete>
      <Button
        aria-label="Search"
        color="primary"
        className="absolute right-1 top-1 h-12 w-12 min-w-0 text-base font-semibold sm:block md:w-auto md:px-8"
        onClick={handleToSearchResults}
      >
        <span className="hidden md:inline-block">Search</span>{" "}
        <MagnifyingGlassIcon className="inline-block h-5 w-5 text-white md:hidden" />
      </Button>
    </div>
  );
}
