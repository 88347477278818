import {
  ClockIcon,
  DevicePhoneMobileIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";

import Image from "next/image";

export interface ProductFeaturesProps {
  age: string;
  capacity: number;
  duration: string;
  hotelPickup: boolean;
  mobileVoucher: boolean;
}

export function ProductFeatures({
  age,
  capacity,
  duration,
  hotelPickup,
  mobileVoucher,
}: ProductFeaturesProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {(age || capacity > 0) && (
        <div className="flex items-center gap-2">
          <span className="text-primary">
            <UsersIcon className="h-4 w-4" />
          </span>
          <span className="text-xs text-gray-400">
            Ages {age}, max of {capacity} per group
          </span>
        </div>
      )}
      {duration && (
        <div className="flex items-center gap-2">
          <span className="text-primary">
            <ClockIcon className="h-4 w-4" />
          </span>
          <span className="text-xs text-gray-400">Durations: {duration}</span>
        </div>
      )}
      {mobileVoucher && (
        <div className="flex items-center gap-2">
          <span className="text-primary">
            <DevicePhoneMobileIcon className="h-4 w-4" />
          </span>
          <span className="text-xs text-gray-400">Mobile voucher</span>
        </div>
      )}
      {hotelPickup && (
        <div className="flex items-center gap-2">
          <span className="text-primary">
            <Image
              src="/assets/icons/car.svg"
              alt="hotel pickup"
              width={18}
              height={18}
            />
          </span>
          <span className="text-xs text-gray-400">Hotel pickup offered</span>
        </div>
      )}
    </div>
  );
}

export default ProductFeatures;
