import clsx from "clsx";

export interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  titlePosition?: "left" | "center" | "right";
  children: React.ReactNode;
}

export function Section({
  title = "Section Title",
  titlePosition = "left",
  children,
  ...props
}: SectionProps) {
  return (
    <section
      className={clsx(`relative my-16 md:my-24 ${props.className || ""}`)}
    >
      <div className="container">
        <h2
          className={clsx("heading-md mb-8", {
            "text-left": titlePosition === "left",
            "text-center": titlePosition === "center",
            "text-right": titlePosition === "right",
          })}
        >
          {title}
        </h2>
      </div>
      {children}
    </section>
  );
}

export default Section;
