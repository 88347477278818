import { gql } from "@apollo/client";

const POST_TYPE_SEO = gql`
  fragment SeoFields on PostTypeSEO {
    title
    metaDesc
    canonical
    metaRobotsNoindex
    metaRobotsNofollow
    metaKeywords
    fullHead
  }
`;

const TAXONOMY_SEO = gql`
  fragment TaxonomySeoFields on TaxonomySEO {
    title
    metaDesc
    canonical
    metaRobotsNoindex
    metaRobotsNofollow
    metaKeywords
    fullHead
  }
`;

export const seo = {
  POST_TYPE_SEO,
  TAXONOMY_SEO,
};

export default seo;
