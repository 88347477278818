import { gql } from "@apollo/client";

const GET_PAGE_BY_ID = gql`
  query GetPageById($databaseId: ID!) {
    frontendSettings {
      generalSettings {
        logo {
          altText
          sourceUrl(size: LARGE)
        }
        favicon {
          altText
          sourceUrl(size: LARGE)
        }
      }
    }
    primaryMenuItems: menuItems(where: { location: PRIMARY }) {
      nodes {
        id
        uri
        path
        label
        parentId
        cssClasses
        menu {
          node {
            name
          }
        }
      }
    }
    page(id: $databaseId, idType: DATABASE_ID) {
      id
      pageSettings {
        bannerTitle
        bannerDescription
        bannerImage {
          altText
          sourceUrl(size: LARGE)
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
        schema {
          articleType
          pageType
          raw
        }
        opengraphImage {
          altText
          sourceUrl(size: MEDIUM)
        }
        twitterImage {
          altText
          sourceUrl(size: MEDIUM)
        }
      }
    }
  }
`;

export const pages = {
  GET_PAGE_BY_ID,
};

export default pages;
