import { priceFormat } from "@/utils";
import clsx from "clsx";

export interface ProductPriceProps {
  price: number;
  discountPrice?: number;
  discountPriceNewLine?: boolean;
  size?: "sm" | "md" | "lg";
}

export function ProductPrice({
  price,
  discountPrice,
  size = "md",
  discountPriceNewLine = false,
}: ProductPriceProps) {
  return (
    <div>
      <span
        className={clsx("font-medium leading-none", {
          "text-xs": size === "sm",
          "text-sm": size === "md",
          "text-base": size === "lg",
        })}
      >
        from
      </span>
      <span
        className={clsx("block font-medium leading-none", {
          "text-base": size === "sm",
          "text-lg": size === "md",
          "text-xl": size === "lg",
        })}
      >
        {discountPrice && discountPrice > 0 ? (
          <>
            {priceFormat(discountPrice)}
            <span
              className={clsx("ml-1 font-light text-primary line-through", {
                "text-xs": size === "sm",
                "text-sm": size === "md",
                "text-base": size === "lg",
                block: discountPriceNewLine,
              })}
            >
              {priceFormat(price)}
            </span>
          </>
        ) : (
          priceFormat(price)
        )}
      </span>
    </div>
  );
}

export default ProductPrice;
