import { ProductItemLoader, Section } from "@/components/elements";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

export type RecentlyViewedLoaderProps = {
  title?: string;
};

export function RecentlyViewedLoader({
  title = "Recently Viewed",
}: RecentlyViewedLoaderProps) {
  return (
    <Section title={title}>
      <div className="md:container">
        <Swiper
          className="-mt-8 !py-8"
          spaceBetween={15}
          slidesPerView={1.25}
          slidesOffsetBefore={15}
          slidesOffsetAfter={15}
          breakpoints={{
            480: {
              slidesPerView: 2.25,
            },
            768: {
              slidesPerView: 3.25,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1024: {
              slidesPerView: 4.25,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <SwiperSlide key={index}>
              <ProductItemLoader />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
}

export default RecentlyViewedLoader;
