/* eslint-disable react/no-unknown-property */

import { useEffect } from "react";

import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

const config: CookieConsent.CookieConsentConfig = {
  // root: 'body',
  // autoShow: true,
  // disablePageInteraction: true,
  // hideFromBots: true,
  // mode: 'opt-in',
  // revision: 0,

  cookie: {
    name: "cc_cookie",
    // domain: location.hostname,
    // path: '/',
    // sameSite: "Lax",
    // expiresAfterDays: 365,
  },

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom left",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: ({ cookie }) => {
    console.log("onFirstConsent fired", cookie);
  },

  onConsent: ({ cookie }) => {
    console.log("onConsent fired!", cookie);
  },

  onChange: ({ changedCategories, changedServices }) => {
    console.log("onChange fired!", changedCategories, changedServices);
  },

  onModalReady: ({ modalName }) => {
    console.log("ready:", modalName);
  },

  onModalShow: ({ modalName }) => {
    console.log("visible:", modalName);
  },

  onModalHide: ({ modalName }) => {
    console.log("hidden:", modalName);
  },

  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^_ga/, // regex: match all cookies starting with '_ga'
          },
          {
            name: "_gid", // string: exact cookie name
          },
          {
            name: /^_clck/,
          },
          {
            name: "_clck",
          },
        ],
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
      services: {
        ga: {
          label: "Google Analytics",
          onAccept: () => {},
          onReject: () => {},
        },
        clarity: {
          label: "Microsoft Clarity",
          onAccept: () => {},
          onReject: () => {},
        },
      },
    },
    ads: {},
  },

  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "Welcome to our website!",
          description:
            "To ensure the best possible experience, we use essential cookies to keep our website running smoothly and tracking cookies to understand how you interact with it. These tracking cookies will only be set after you have provided your consent.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage preferences",
          // closeIconLabel: 'Reject all and close modal',
          footer: `
                    <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                    <a href="/terms-and-conditions" target="_blank">Terms And Conditions</a>
                    `,
        },
        preferencesModal: {
          title: "Manage cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Accept current selection",
          closeIconLabel: "Close modal",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              title: "Cookie usage",
              description: 'We use cookies to improve your browsing experience and personalize your content. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc-link">privacy policy</a>.',
            },
            {
              title: "Functionality cookies",
              description:
                "These cookies remember your preferences and settings, such as your language preferences or login details. This can help you to personalize your browsing experience and make it more convenient.",

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: "necessary",
            },
            {
              title: "Performance and Analytics",
              description:
                "These cookies collect information about how you use the website, such as which pages you visit and how long you spend on each page. This information is used to improve the website's performance and usability.",
              linkedCategory: "analytics",
            },
            {
              title: "More information",
              description:
                'For any questions or concerns regarding our cookie policy and your cookie choices, please do not hesitate to <a class="cc-link" href="/contact-us">contact us</a>.',
            },
          ],
        },
      },
    },
  },
};

export default function CookieConsentPopup() {
  useEffect(() => {
    CookieConsent.run(config);
  }, []);

  return (
    <>
      <style jsx global>{`
        :root {
          --cc-primary-color: #ff5425;
          --cc-btn-primary-bg: #ff5425;
        }
      `}</style>
    </>
  );
}
