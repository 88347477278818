import archive from "./archive";
import frontPage from "./front-page";
import page from "./page";
import pageAboutUs from "./page-about-us";
import pageArticles from "./page-articles";
import pageContactUs from "./page-contact-us";
import pageDestination from "./page-destination";
import pageFaq from "./page-faq";
import pageJobVacancies from "./page-job-vacancies";
import pageMyFavorites from "./page-my-favorites";
import pageSearchResults from "./page-search-results";
import pageThingsToDo from "./page-things-to-do";
import pageTransports from "./page-transports";
import single from "./single";
import signleJobVacancy from "./single-job-vacancy";
import singleTour from "./single-tour";
import singleTransport from "./single-transport";
import taxonomyDestination from "./taxonomy-destination";
import taxonomyTourCat from "./taxonomy-tourcat";
import taxonomyTourTag from "./taxonomy-tourtag";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "front-page": frontPage,
  page,
  single,
  archive,
  "page-destination": pageDestination,
  "page-things-to-do": pageThingsToDo,
  "page-transports": pageTransports,
  "page-articles": pageArticles,
  "page-about-us": pageAboutUs,
  "page-contact-us": pageContactUs,
  "page-faq": pageFaq,
  "page-my-favorites": pageMyFavorites,
  "page-job-vacancies": pageJobVacancies,
  "page-search-results": pageSearchResults,
  "single-tour": singleTour,
  "single-job-vacancy": signleJobVacancy,
  "single-transport": singleTransport,
  "taxonomy-destination": taxonomyDestination,
  "taxonomy-tourtag": taxonomyTourTag,
  "taxonomy-tourcat": taxonomyTourCat,
};
