import { ACTIVITIES, ACTIVITY } from "@/constants";
import { TSortby } from "@/types";
import { Select, SelectItem } from "@nextui-org/react";
import { ProductSwitchViewModeButton } from "./product-switch-view-mode-button";

export interface ProductSortingProps {
  value: TSortby;
  total: number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface Sortby {
  label: string;
  value: TSortby;
}

const sortby: Sortby[] = [
  { label: "Review", value: "REVIEW" },
  { label: "Popularity", value: "POPULARITY" },
  { label: "Name", value: "TITLE" },
  { label: "Date", value: "DATE" },
  { label: "Price (Low to High)", value: "PRICE_LOW_TO_HIGH" },
  { label: "Price (High to Low)", value: "PRICE_HIGH_TO_LOW" },
];

export function ProductSorting({
  value,
  total,
  onChange,
}: ProductSortingProps) {
  return (
    <div className="mb-6 grid grid-cols-2 items-center">
      <span className="text-sm text-gray-500">
        {total > 1 ? total + " " + ACTIVITIES : total + " " + ACTIVITY}
      </span>
      <div className="flex items-end justify-end gap-6">
        <ProductSwitchViewModeButton className="hidden sm:block" />
        <Select
          label="Sort by"
          items={sortby}
          variant="underlined"
          defaultSelectedKeys={[value]}
          size="sm"
          className="max-w-[200px]"
          onChange={onChange}
          value={value}
        >
          {(sortby) => (
            <SelectItem key={sortby.value}>{sortby.label}</SelectItem>
          )}
        </Select>
      </div>
    </div>
  );
}
