import { READ_LESS, READ_MORE } from "@/constants";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";

export interface ReadMoreButtonProps {
  readMore: boolean;
  setReadMore: (value: boolean) => void;
}

export function ReadMoreButton({ readMore, setReadMore }: ReadMoreButtonProps) {
  return (
    <Button
      color="primary"
      variant="light"
      onClick={(event) => {
        event.preventDefault();
        setReadMore(!readMore);
      }}
    >
      {readMore ? (
        <>
          {READ_LESS}
          <ChevronUpIcon className="h-4 w-4" />
        </>
      ) : (
        <>
          {READ_MORE}
          <ChevronDownIcon className="h-4 w-4" />
        </>
      )}
    </Button>
  );
}

export default ReadMoreButton;
