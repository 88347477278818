import { gql } from "@apollo/client";

const PAGE_FIELDS = gql`
  fragment PageFields on Page {
    id
    pageSettings {
      bannerTitle
      bannerDescription
      bannerImage {
        altText
        sourceUrl(size: LARGE)
      }
    }
    seo {
      breadcrumbs {
        text
        url
      }
      canonical
      cornerstone
      focuskw
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      title
      twitterDescription
      twitterTitle
      schema {
        articleType
        pageType
        raw
      }
      opengraphImage {
        altText
        sourceUrl(size: MEDIUM)
      }
      twitterImage {
        altText
        sourceUrl(size: MEDIUM)
      }
    }
  }
`;

export const pages = {
  PAGE_FIELDS,
};

export default pages;
