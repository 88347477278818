import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface GlobalState {
  currentLanguage: "en" | "th" | "zh-CN";
  viewMode: "grid" | "list";
  recentlyViewed: string[];
  favorites: string[];
  reset: () => void;
}

const initialState: GlobalState = {
  currentLanguage: "en",
  viewMode: "list",
  recentlyViewed: [],
  favorites: [],
  reset: () => {
    return initialState;
  },
};

export const useGlobalStore = create<GlobalState>()(
  persist(
    (set) => ({
      ...initialState,
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: "global",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
