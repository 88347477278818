import { gql } from "@apollo/client";
import { acf } from "../acf";

const TRANSPORT_FIELDS = gql`
  fragment TransportFields on Transport {
    id
    databaseId
    slug
    title
    featuredImage {
      node {
        id
        altText
        sourceUrl
      }
    }
    fields: transportExtra {
      price
      discountPrice
      rating
      numReviews
      feature {
        age
        capacity
        duration
        hotelPickup
        mobileVoucher
      }
      shortDescription
    }
  }
`;

const TRANSPORT_FULL_FIELDS = gql`
  ${TRANSPORT_FIELDS}
  ${acf.ADDRESS_FIELDS}
  fragment TransportFullFields on Transport {
    ...TransportFields
    id
    databaseId
    date
    slug
    title
    featuredImage {
      node {
        id
        altText
        sourceUrl
      }
    }
    content
    fields: transportExtra {
      price
      discountPrice
      bookingHtml
      feature {
        age
        capacity
        duration
        hotelPickup
        mobileVoucher
      }
      shortDescription
      gallery {
        id
        altText
        sourceUrl
      }
      packageOptions {
        name
        prices {
          name
          price
          unit
        }
        duration
        durationUnit
        departureTime
        pickUpTime
        return
        description
      }
      included
      notIncluded
      meetingPoint {
        name
        address {
          ...AddressFields
        }
      }
      meetingPointDescription
      itinerary {
        name
        description
        address {
          ...AddressFields
        }
      }
      whatToExpect
      additionalInformation
      tripadvisorDetails
      tripadvisorReviews
      rating
      numReviews
    }
    destinations: destinationCategories {
      nodes {
        id
        name
        slug
      }
    }
    categories: transportCategories(first: 100) {
      nodes {
        id
        name
        slug
      }
    }
    tags: tourTags(first: 100) {
      nodes {
        id
        name
        link
        slug
      }
    }
    relatedTransports {
      nodes {
        ...TransportFields
      }
    }
  }
`;

export const transports = {
  TRANSPORT_FIELDS,
  TRANSPORT_FULL_FIELDS,
};

export default transports;
