import { gql } from "@apollo/client";
import { acf } from "../acf";

const TOUR_FIELDS = gql`
  fragment TourFields on Tour {
    id
    databaseId
    slug
    title(format: RENDERED)
    featuredImage {
      node {
        id
        altText
        sourceUrl
      }
    }
    fields: tourExtra {
      price
      discountPrice
      rating
      numReviews
      feature {
        age
        capacity
        duration
        hotelPickup
        mobileVoucher
      }
      shortDescription
    }
  }
`;

const TOUR_FULL_FIELDS = gql`
  ${TOUR_FIELDS}
  ${acf.ADDRESS_FIELDS}
  fragment TourFullFields on Tour {
    ...TourFields
    id
    databaseId
    slug
    title(format: RENDERED)
    featuredImage {
      node {
        id
        altText
        sourceUrl
      }
    }
    date
    content
    excerpt
    fields: tourExtra {
      tourType
      price
      discountPrice
      bookingHtml
      feature {
        age
        capacity
        duration
        hotelPickup
        mobileVoucher
      }
      shortDescription
      gallery {
        id
        altText
        sourceUrl
      }
      packageOptions {
        name
        prices {
          name
          price
          unit
        }
        duration
        durationUnit
        departureTime
        pickUpTime
        return
        description
      }
      included
      notIncluded
      meetingPoint {
        name
        address {
          ...AddressFields
        }
      }
      meetingPointDescription
      itinerary {
        name
        description
        address {
          ...AddressFields
        }
      }
      cookingMenu {
        name
        description
      }
      whatToExpect
      additionalInformation
      tripadvisorDetails
      tripadvisorReviews
      rating
      numReviews
    }
    destinations: destinationCategories {
      nodes {
        id
        name
        slug
      }
    }
    categories: tourCategories(first: 100) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    tags: tourTags(first: 100) {
      nodes {
        id
        name
        link
        slug
      }
    }
    relatedTours {
      nodes {
        ...TourFields
      }
    }
  }
`;

export const tours = {
  TOUR_FIELDS,
  TOUR_FULL_FIELDS,
};

export default tours;
