import {
  CaptchaField as CaptchaFieldType,
  FieldError,
} from "@/__generated__/graphql";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import { useFormContext } from "react-hook-form";

export interface CaptchaFieldProps {
  recaptchaRef: ReCAPTCHA | any;
  field: CaptchaFieldType;
  fieldErrors: FieldError[];
}

export function CaptchaField({
  recaptchaRef,
  field,
  fieldErrors,
}: CaptchaFieldProps) {
  if (!process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY)
    throw new Error(
      "Missing NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY environment variable",
    );

  const { id, type } = field;

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const inputId = `field_${type}_${id}`,
    inputName = `field_${id}`,
    error = errors[inputName];

  const theme = (field?.captchaTheme === "DARK" && "dark") || "light";

  function onChange(token: string) {
    setValue(`field_${field?.id}`, token);
  }

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY as string}
        theme={theme}
        onChange={(token) => onChange(token as string)}
      />
      <input
        id={inputId}
        {...register(inputName, {
          required: "Please complete the captcha",
        })}
        type="hidden"
      />
      {error !== undefined && (
        <span className="text-xs text-red-400">
          {fieldErrors.map((error) => error.message).join(", ") ||
            (error?.message as string)}
        </span>
      )}
    </>
  );
}

export default CaptchaField;
