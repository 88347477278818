import { Button } from "@nextui-org/react";
import React from "react";

export interface LocationPinProps {
  active?: boolean;
  icon: React.ReactNode;
  label: string;
}

export function LocationPin({ active, icon, label }: LocationPinProps) {
  return (
    <>
      {active ? (
        <div className="relative flex items-center justify-center gap-2 rounded-full border border-primary bg-white pr-4 shadow-md">
          <Button
            isIconOnly
            color="primary"
            aria-label="Location Pin"
            className="rounded-full border-2 border-white"
          >
            {icon}
          </Button>
          {label}
          <div className="absolute bottom-0 left-0 right-0 mx-auto h-3 w-3 -translate-x-1/2 translate-y-1/2 rotate-45 transform border-b border-r border-primary bg-white" />
        </div>
      ) : (
        <Button
          isIconOnly
          color="primary"
          aria-label="Location Pin"
          className="rounded-full border-2 border-white"
        >
          {icon}
        </Button>
      )}
    </>
  );
}

export default LocationPin;
