import { Card, CardBody, CardHeader, Skeleton } from "@nextui-org/react";

export interface ProductItemLoaderProps {}

export function ProductItemLoader(props: ProductItemLoaderProps) {
  return (
    <Card>
      <CardHeader className="aspect-[4/3] w-full p-0">
        <Skeleton className="h-full w-full rounded-lg">
          <div className="h-full w-full rounded-lg bg-default-300" />
        </Skeleton>
      </CardHeader>
      <CardBody className="p-6">
        <div className="mb-6 space-y-2">
          <Skeleton className="w-5/5 rounded-lg">
            <div className="w-5/5 h-4 rounded-lg bg-default-200" />
          </Skeleton>
          <Skeleton className="w-4/5 rounded-lg">
            <div className="h-4 w-4/5 rounded-lg bg-default-200" />
          </Skeleton>
        </div>
        <div className="mb-6 flex gap-1">
          <Skeleton className="h-5 w-5 rounded-full">
            <div className="h-5 w-5 rounded-full bg-default-200" />
          </Skeleton>
          <Skeleton className="h-5 w-5 rounded-full">
            <div className="h-5 w-5 rounded-full bg-default-200" />
          </Skeleton>
          <Skeleton className="h-5 w-5 rounded-full">
            <div className="h-5 w-5 rounded-full bg-default-200" />
          </Skeleton>
          <Skeleton className="h-5 w-5 rounded-full">
            <div className="h-5 w-5 rounded-full bg-default-200" />
          </Skeleton>
          <Skeleton className="h-5 w-5 rounded-full">
            <div className="h-5 w-5 rounded-full bg-default-200" />
          </Skeleton>
        </div>
        <div className="space-y-2">
          <Skeleton className="w-10 rounded-lg">
            <div className="h-3 w-10 rounded-lg bg-default-200" />
          </Skeleton>
          <Skeleton className="w-2/5 rounded-lg">
            <div className="h-5 w-2/5 rounded-lg bg-default-200" />
          </Skeleton>
        </div>
      </CardBody>
    </Card>
  );
}

export default ProductItemLoader;
