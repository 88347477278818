import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";

export type BreadCrumbItem = {
  label: string;
  href: string;
};

export interface BreadCrumbProps {
  items: BreadCrumbItem[];
}

export function BreadCrumb({ items }: BreadCrumbProps) {
  return (
    <Breadcrumbs
      className="my-6"
      separator="/"
      itemClasses={{
        separator: "px-2",
        item: "data-[current=true]:text-primary whitespace-normal",
      }}
    >
      {items?.map((item, index) => (
          <BreadcrumbItem key={index} href={item.href}>
            {item.label}
          </BreadcrumbItem>
        ))}
      )
    </Breadcrumbs>
  );
}

export default BreadCrumb;
