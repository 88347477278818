import { Card, CardBody, CardHeader, Skeleton } from "@nextui-org/react";

export interface ProductListItemLoaderProps {}

export function ProductListItemLoader(props: ProductListItemLoaderProps) {
  return (
    <Card className="grid grid-cols-12">
      <CardHeader className="col-span-4 aspect-[4/3] w-full flex-col items-start p-4">
        <Skeleton className="h-full w-full rounded-lg">
          <div className="h-full w-full rounded-lg bg-default-300" />
        </Skeleton>
      </CardHeader>
      <CardBody className="col-span-8 p-4 pl-0">
        <div className="grid grid-cols-8">
          <div className="col-span-6">
            <div className="mb-6 space-y-2">
              <Skeleton className="w-5/5 rounded-lg">
                <div className="w-5/5 h-4 rounded-lg bg-default-200" />
              </Skeleton>
              <Skeleton className="w-4/5 rounded-lg">
                <div className="h-4 w-4/5 rounded-lg bg-default-200" />
              </Skeleton>
            </div>
            <div className="mb-6 space-y-2">
              <Skeleton className="w-5/5 rounded-lg">
                <div className="w-5/5 h-3 rounded-lg bg-default-200" />
              </Skeleton>
              <Skeleton className="w-5/5 rounded-lg">
                <div className="w-5/5 h-3 rounded-lg bg-default-200" />
              </Skeleton>
              <Skeleton className="w-4/5 rounded-lg">
                <div className="h-3 w-4/5 rounded-lg bg-default-200" />
              </Skeleton>
            </div>
            <div className="mb-6 flex gap-1">
              <Skeleton className="h-5 w-5 rounded-full">
                <div className="h-5 w-5 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-5 w-5 rounded-full">
                <div className="h-5 w-5 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-5 w-5 rounded-full">
                <div className="h-5 w-5 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-5 w-5 rounded-full">
                <div className="h-5 w-5 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-5 w-5 rounded-full">
                <div className="h-5 w-5 rounded-full bg-default-200" />
              </Skeleton>
            </div>
            <div className="mb-6 flex flex-wrap gap-1">
              <Skeleton className="h-3 w-3/6 rounded-full">
                <div className="w-2/65 h-3 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-3 w-1/6 rounded-full">
                <div className="w-2/65 h-3 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-3 w-2/6 rounded-full">
                <div className="w-2/65 h-3 rounded-full bg-default-200" />
              </Skeleton>
              <Skeleton className="h-3 w-3/6 rounded-full">
                <div className="w-2/65 h-3 rounded-full bg-default-200" />
              </Skeleton>
            </div>
          </div>
          <div className="col-span-2">
            <div className="space-y-2">
              <Skeleton className="ml-auto w-10 rounded-lg">
                <div className="h-3 w-10 rounded-lg bg-default-200" />
              </Skeleton>
              <Skeleton className="ml-auto w-3/5 rounded-lg">
                <div className="h-5 w-3/5 rounded-lg bg-default-200" />
              </Skeleton>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ProductListItemLoader;
