import { settings } from "@/config";
import { MenuItems } from "@/types";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import Link from "next/link";

export const Footer = () => {
  return (
    <>
      <footer className="bg-gradient-to-b from-[#FF5425] to-[#CC2414] py-16">
        <div className="container text-white">
          <div className="grid lg:grid-cols-6">
            <div className="mb-10 lg:col-span-2 lg:mb-0">
              <Link href="/">
                <Image
                  src="/assets/images/logo-white.svg"
                  alt="Logo"
                  width={120}
                  height={37}
                />
              </Link>
              <p
                className="mb-4 mt-6 text-sm font-light leading-relaxed"
                dangerouslySetInnerHTML={{ __html: settings.footer.address }}
              />
              <p className="text-sm font-light">{settings.footer.openHours}</p>
              <div className="mt-6 flex gap-2">
                <Link
                  href={settings.socials.line}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Line"
                >
                  <Image
                    src="/assets/icons/line.svg"
                    alt="line"
                    width={40}
                    height={40}
                  />
                </Link>
                <Link
                  href={settings.socials.facebook}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <Image
                    src="/assets/icons/facebook.svg"
                    alt="facebook"
                    width={40}
                    height={40}
                  />
                </Link>
                <Link
                  href={settings.socials.tripadvisor}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Tripadvisor"
                >
                  <Image
                    src="/assets/icons/tripadvisor.svg"
                    alt="tripadvisor"
                    width={40}
                    height={40}
                  />
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 gap-y-10 sm:grid-cols-2 lg:col-span-4 xl:grid-cols-4">
              {settings.footer.menus.map((column, key) => (
                <FooterNavigation
                  key={key}
                  title={column.title}
                  links={column.links}
                />
              ))}
              <div>
                <h5 className="mb-6 text-xl text-white">Contact us</h5>
                <ul className="font-light">
                  {settings.contact.phones.map((phone, key) => (
                    <li key={key} className="mb-2">
                      {phone.numbers.map((item, key) => (
                        <a
                          key={key}
                          href={`tel:${item}`}
                          className="text-sm leading-loose hover:underline"
                        >
                          <span className="flex items-center gap-2">
                            <PhoneIcon className="h-4 w-4" /> {item}
                          </span>
                        </a>
                      ))}
                      <span className="mb-4 mt-2 block text-xs">
                        {phone.availability}
                      </span>
                    </li>
                  ))}
                  <li className="mb-2">
                    <a
                      href={`mailto:${settings.contact.email}`}
                      className="text-sm hover:underline"
                    >
                      <span className="flex items-center gap-2">
                        <EnvelopeIcon className="h-4 w-4" />{" "}
                        {settings.contact.email}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <CopyRight />
    </>
  );
};

export default Footer;

const FooterNavigation = ({
  title,
  links,
}: {
  title: string;
  links: MenuItems[];
}) => {
  return (
    <div>
      <h5 className="mb-4 text-xl text-white">{title}</h5>
      <ul className="font-light">
        {links.map((link, key) => (
          <li key={key} className="mb-2">
            <Link className="text-sm hover:underline" href={link.link}>
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CopyRight = () => {
  return (
    <div className="bg-primary py-4 text-white">
      <div className="container grid gap-4 lg:grid-cols-6">
        <div className="lg:col-span-2">
          <span className="block text-left text-sm font-light">
            {settings.footer.copyRight}
          </span>
        </div>
        <div className="mr-0 flex flex-wrap justify-start gap-x-3 lg:col-span-4 lg:justify-end">
          {settings.footer.footerLinks.map((link, key) => (
            <Link
              key={key}
              className="text-xs font-light hover:underline"
              href={link.link}
              title={link.title}
            >
              {link.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
