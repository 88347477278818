import { GetFaqPageQuery, PostTypeSeo } from "@/__generated__/graphql";
import {
  BreadCrumb,
  BreadCrumbItem,
  SimpleBanner,
} from "@/components/elements";
import { Layout } from "@/components/layout";
import { fragments } from "@/graphql/fragments";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import { Accordion, AccordionItem } from "@nextui-org/react";
import parse from "html-react-parser";
import Head from "next/head";
import sanitizeHtml from "sanitize-html";

const Component: FaustTemplate<GetFaqPageQuery> = ({ data, loading }) => {
  if (loading) {
    return <>Loading...</>;
  }

  const seo = data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    page = data?.page,
    frontendSettings = data?.frontendSettings?.generalSettings;

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: data?.page?.title as string,
      href: `/${data?.page?.slug}`,
    },
  ];

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <article className="container mb-10">
          <BreadCrumb items={breadcrumb} />
          <SimpleBanner title="FAQ" subTitle={page?.title as string} />
          <p className="mt-10">
            View answers to frequently asked questions about booking process of
            activities and transfers, online payment, e-ticket, refund and more
            important things to know for your next trip. If your question is not
            listed, please contact us.
          </p>
          {frontendSettings &&
            frontendSettings?.faq?.map((item, index) => (
              <section key={index} className="my-10">
                <h2 className="mb-6 text-2xl font-semibold text-primary">
                  {item?.topic}
                </h2>
                {item?.questions && (
                  <Accordion
                    className="px-0"
                    itemClasses={{
                      title: "font-semibold text-base",
                      content: "p-6 bg-gray-50 rounded-xl mb-6",
                    }}
                  >
                    {item?.questions?.map((question, index) => (
                      <AccordionItem
                        key={index}
                        aria-label={question?.question || ""}
                        title={question?.question || ""}
                      >
                        <div
                          className="prose min-w-full text-sm"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(question?.anwser || ""),
                          }}
                        />
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
              </section>
            ))}
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  query GetFaqPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      slug
      seo {
        ...SeoFields
      }
    }
    frontendSettings {
      generalSettings {
        faq {
          topic
          questions {
            question
            anwser
          }
        }
      }
    }
  }
`;

export default Component;
