import { settings } from "@/config";
import { ProductType } from "@/types";
import { FeaturedImage } from "@/types/featured-image";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { ItemStyles, Rating, ThinRoundedStar } from "@smastrom/react-rating";
import Image from "next/image";
import Link from "next/link";
import { FavoriteButton } from "../favorite-button/favorite-button";
import { ProductFeatures } from "../product-features/product-features";
import { ProductPrice } from "../product-price/product-price";

export interface ProductListItemProps {
  type: ProductType;
  id: string;
  title: string;
  slug: string;
  price: number;
  discountPrice?: number;
  featuredImage?: FeaturedImage;
  rating?: number;
  numReviews?: number;
  excerpt?: string;
  features?: {
    age?: string;
    capacity?: number;
    duration?: string;
    hotelPickup?: boolean;
    mobileVoucher?: boolean;
  };
}

const styles: ItemStyles = {
  itemShapes: ThinRoundedStar,
  activeFillColor: "#ff5425",
  inactiveFillColor: "#e9e9e9",
};

export function ProductListItem({
  type,
  id,
  title,
  slug,
  price,
  discountPrice,
  featuredImage,
  rating,
  numReviews,
  excerpt,
  features,
}: ProductListItemProps) {
  const url = type === "tour" ? `/tour/${slug}` : `/transport/${slug}`;

  return (
    <Card className="grid grid-cols-12">
      <CardHeader className="relative col-span-4 w-full flex-col items-start p-4">
        <Link href={url} title={title} className="h-full w-full">
          <Image
            src={
              featuredImage?.sourceUrl || settings.imagePlaceholder.sourceUrl
            }
            alt={featuredImage?.altText || settings.imagePlaceholder.altText}
            width={400}
            height={300}
            className="h-full w-full rounded-lg object-cover"
          />
        </Link>
        {type === "tour" && (
          <div className="absolute right-6 top-6">
            <FavoriteButton id={id} />
          </div>
        )}
      </CardHeader>
      <CardBody className="col-span-8 p-4 pl-0">
        <div className="grid grid-cols-8">
          <div className="col-span-6">
            <Link href={url} title={title}>
              <h3 className="mb-4 text-xl font-semibold leading-snug">
                {title}
              </h3>
            </Link>
            <p className="mb-4 text-sm">{excerpt}</p>
            <div className="mb-4 flex h-4 items-center gap-2">
              <Rating
                style={{ maxWidth: 120 }}
                value={rating || 0}
                halfFillMode="svg"
                readOnly
                itemStyles={styles}
              />
              <Link
                href={url}
                title={title}
                className="text-xs leading-none no-underline hover:underline lg:text-sm"
              >
                {numReviews}
              </Link>
            </div>
            <div className="mt-10">
              <ProductFeatures
                age={features?.age || ""}
                capacity={Number(features?.capacity)}
                duration={features?.duration || ""}
                hotelPickup={features?.hotelPickup || false}
                mobileVoucher={features?.mobileVoucher || false}
              />
            </div>
          </div>
          <div className="col-span-2 text-right">
            <ProductPrice
              price={price}
              discountPrice={discountPrice}
              discountPriceNewLine
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ProductListItem;
