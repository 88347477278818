import { FieldError, TextField } from "@/__generated__/graphql";
import { Textarea } from "@nextui-org/react";
import { useFormContext } from "react-hook-form";

export interface TextareaFieldProps {
  field: TextField;
  fieldErrors: FieldError[];
}

export function TextareaField({ field, fieldErrors }: TextareaFieldProps) {
  const {
    id,
    type,
    label,
    description,
    isRequired,
    placeholder,
    defaultValue,
  } = field;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputId = `field_${type}_${id}`,
    inputName = `field_${id}`,
    error = errors[inputName];

  return (
    <Textarea
      id={inputId}
      {...register(inputName, {
        required:
          (isRequired && `Please enter the ${label?.toLocaleLowerCase()}`) ||
          false,
      })}
      label={label}
      description={description || ""}
      placeholder={placeholder || ""}
      required={isRequired || false}
      defaultValue={defaultValue || ""}
      isInvalid={fieldErrors.length > 0 || error !== undefined}
      errorMessage={
        fieldErrors.map((error) => error.message).join(", ") ||
        (error?.message as string)
      }
    />
  );
}

export default TextareaField;
