import {
  GetDestinationTaxonomyQuery,
  PostFieldsFragment,
  PostTypeSeo,
  TourFieldsFragment,
} from "@/__generated__/graphql";
import {
  BreadCrumb,
  BreadCrumbItem,
  DestinationGallery,
  TripadvisorReviews,
} from "@/components/elements";
import { Layout } from "@/components/layout";
import { ProductList } from "@/components/product-list/product-list";
import { ArticleSlider, ProductSlider } from "@/components/shared";
import { fragments } from "@/graphql/fragments";
import { jsonParse } from "@/utils";
import seoStringParser from "@/utils/seo-string-parser";
import { gql } from "@apollo/client";
import { FaustTemplate } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";
import sanitizeHtml from "sanitize-html";

const Component: FaustTemplate<GetDestinationTaxonomyQuery> = ({ data }) => {
  const destination =
      data?.nodeByUri?.__typename === "DestinationCategory"
        ? data?.nodeByUri
        : null,
    seo = destination?.seo as PostTypeSeo,
    destinationExtra = destination?.destinationExtra,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    content = sanitizeHtml(destination?.description || "");

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Destination",
      href: "/destination",
    },
    {
      label: destination?.name || "",
      href: destination?.slug || "",
    },
  ];

  return (
    <>
      <Head>
        <title>{destination?.name}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo?.metaRobotsNoindex}, ${seo?.metaRobotsNofollow}`}
        />
        {seo?.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <div className="container">
          <BreadCrumb items={breadcrumb} />
        </div>
        {destinationExtra?.gallery &&
          destinationExtra.gallery.length > 0 && (
            <DestinationGallery
              title={destination?.name || ""}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              images={destinationExtra.gallery}
            />
          )}
        <div className="container my-16">
          <div className="prose min-w-full">
            <h1 className="text-3xl font-medium text-primary">
              About {destination?.name || ""}
            </h1>
            <div
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: content || "" }}
            />
          </div>
        </div>
        {destinationExtra?.popularTours && (
          <ProductSlider
            type="tour"
            data={destinationExtra?.popularTours as TourFieldsFragment[]}
          />
        )}
        <ProductList
          type="tour"
          defaultDestination={destination?.slug as string}
        />
        {destinationExtra?.articles && (
          <ArticleSlider
            posts={destinationExtra?.articles as PostFieldsFragment[]}
            seeMoreLink="/articles"
          />
        )}
        <TripadvisorReviews
          title="What do people say <br> about us?"
          source="travel-advisor"
          details={jsonParse(destinationExtra?.tripadvisorDetails ?? "")}
          reviews={jsonParse(destinationExtra?.tripadvisorReviews ?? "")}
          showDetails
        />
      </Layout>
    </>
  );
};

Component.variables = (seedQuery, ctx) => {
  return {
    uri: seedQuery.uri,
  };
};

Component.query = gql`
  ${fragments.seo.TAXONOMY_SEO}
  ${fragments.tours.TOUR_FIELDS}
  ${fragments.posts.POST_FIELDS}
  query GetDestinationTaxonomy($uri: String!) {
    nodeByUri(uri: $uri) {
      archiveType: __typename
      ... on DestinationCategory {
        id
        name
        slug
        description
        seo {
          ...TaxonomySeoFields
        }
        destinationExtra {
          featuredImage {
            id
            altText
            sourceUrl
          }
          gallery {
            id
            altText
            sourceUrl
          }
          popularTours {
            ... on Tour {
              ...TourFields
            }
          }
          articles {
            ... on Post {
              ...PostFields
            }
          }
          tripadvisorDetails
          tripadvisorReviews
        }
      }
    }
  }
`;

export default Component;
