import { fragments } from "@/graphql/fragments";
import { gql } from "@apollo/client";

export const PRICE_RANGE: [number, number] = [0, 100000];

const QUERY_ALL_TOURS = gql`
  ${fragments.tours.TOUR_FIELDS}
  query GetAllTours(
    $first: Int = 10
    $orderby: PostObjectsConnectionOrderbyInput = { field: REVIEW, order: DESC }
    $after: String = ""
    $before: String = ""
    $search: String = ""
    $taxArray: [TaxArray] = []
    $priceRange: String = "[0, 100000]"
    $in: [ID] = []
  ) {
    tours(
      first: $first
      where: {
        hasPassword: false
        orderby: [$orderby]
        search: $search
        taxQuery: { relation: AND, taxArray: $taxArray }
        metaQuery: {
          relation: AND
          metaArray: [
            {
              value: $priceRange
              type: NUMERIC
              key: "price"
              compare: BETWEEN
            }
          ]
        }
        in: $in
      }
      after: $after
      before: $before
    ) {
      nodes {
        ...TourFields
      }
      pageInfo {
        total
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
`;

const QUERY_TOUR_FILTERS = gql`
  query GetTourFilters {
    destinationCategories(first: 100, where: { orderby: NAME }) {
      nodes {
        id
        name
        slug
        count
      }
    }
    tourCategories(first: 100, where: { orderby: NAME }) {
      nodes {
        id
        name
        slug
        count
        parentId
        children {
          nodes {
            id
            name
            slug
            count
            parentId
          }
        }
      }
    }
  }
`;

const SEARCH_ALL_TOURS = gql`
  query SearchTours(
    $first: Int = 10
    $where: RootQueryToTourConnectionWhereArgs
    $where2: RootQueryToDestinationCategoryConnectionWhereArgs
  ) {
    tours(first: $first, where: $where) {
      nodes {
        slug
        title(format: RENDERED)
        featuredImage {
          node {
            id
            altText
            sourceUrl
          }
        }
        destinations: destinationCategories {
          nodes {
            id
            name
            slug
          }
        }
      }
    }
    destinationCategories(first: $first, where: $where2) {
      nodes {
        name
        slug
      }
    }
  }
`;

export const tours = {
  PRICE_RANGE,
  QUERY_ALL_TOURS,
  QUERY_TOUR_FILTERS,
  SEARCH_ALL_TOURS,
};

export default tours;
