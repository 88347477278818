import { PackageOptionType } from "@/types";
import { priceFormatText } from "@/utils";
import { Accordion, AccordionItem } from "@nextui-org/react";
import clsx from "clsx";

export interface ProductPackageOptionProps {
  options: PackageOptionType[];
}

export function ProductPackageOption({ options }: ProductPackageOptionProps) {
  return (
    <section id="package-options">
      <h2 className="heading-md mb-6">Package options</h2>
      <div className="grid gap-6">
        <Accordion
          variant="splitted"
          className="px-0"
          itemClasses={{
            base: "bg-primary/5 !px-0 !shadow-none",
            heading:
              "bg-primary/5 rounded-lg px-4 data-[open=true]:bg-primary border",
            title: "font-semibold text-base data-[open=true]:text-white",
            content:
              "p-6 bg-primary/5 rounded-lg border-dashed border-2 border-primary",
            indicator: "data-[open=true]:text-white",
          }}
        >
          {options?.map((item, key) => (
            <AccordionItem
              key={key}
              aria-label={item.name}
              title={item.name}
              className="bg-primary-50"
            >
              <p className="mb-4">{item.description}</p>
              <div className="grid gap-2">
                <div className="grid grid-cols-6 gap-4">
                  <span className="col-span-2 text-sm font-medium">Price:</span>
                  <div className="col-span-4 font-medium">
                    {item.prices ? (
                      item.prices.map((price, key) => (
                        <div key={key}>
                          <span
                            className={clsx("text-sm", {
                              "mr-2": price.name,
                            })}
                          >
                            {price.name}
                          </span>
                          <span>
                            {priceFormatText(price.price, "THB")}
                            <span className="ml-1 text-xs text-gray-500">
                              / {price.unit}
                            </span>
                          </span>
                        </div>
                      ))
                    ) : (
                      <span className="text-gray-500">-</span>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4">
                  <span className="col-span-2 text-sm font-medium">
                    Duration:
                  </span>
                  <span className="black col-span-4 font-medium">
                    {item.duration}{" "}
                    {item.durationUnit ? item.durationUnit : "hours"}
                  </span>
                </div>
                <div className="grid grid-cols-6 gap-4">
                  <span className="col-span-2 text-sm font-medium">
                    Departure Time:
                  </span>
                  <span className="black col-span-4 font-medium">
                    {item.departureTime}
                  </span>
                </div>
                <div className="grid grid-cols-6 gap-4">
                  <span className="col-span-2 text-sm font-medium">
                    Pick up Time:
                  </span>
                  <span className="black col-span-4 font-medium">
                    {item.pickUpTime ? item.pickUpTime : "-"}
                  </span>
                </div>
              </div>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
}

export default ProductPackageOption;
