import { acf } from "./acf";
import { jobVacancies } from "./job-vacancies";
import { pages } from "./pages";
import { posts } from "./posts";
import { seo } from "./seo";
import { tours } from "./tours";
import { transports } from "./transports";

export const fragments = {
  acf,
  posts,
  pages,
  seo,
  tours,
  jobVacancies,
  transports,
};
