import { gql } from "@apollo/client";

const ADDRESS_FIELDS = gql`
  fragment AddressFields on ACF_GoogleMap {
    latitude
    longitude
    city
    country
    countryShort
    placeId
    postCode
    state
    stateShort
    streetAddress
    streetName
    streetNumber
    zoom
  }
`;

export const acf = {
  ADDRESS_FIELDS,
};

export default acf;
