import { READ_LESS, READ_MORE } from "@/constants";
import { ItineraryType, MeetingPointType } from "@/types";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Button, Link } from "@nextui-org/react";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import { scrollToSection } from "@/utils/scroll-to-section";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { LocationPin } from "./location-pin/location-pin";
import { Marker } from "./marker/marker";

export interface ProductItineraryProps {
  meetingPoints: MeetingPointType[];
  itineraries: ItineraryType[];
}

export interface PlaceType {
  id: string | number;
  name: string;
  description?: string;
  lat: number;
  lng: number;
  type: "Address" | "Step";
}

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

const options: google.maps.MapOptions = {
  mapId: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID as string,
  minZoom: 2,
  maxZoom: 18,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  clickableIcons: false,
  scrollwheel: true,
  panControl: true,
  gestureHandling: "greedy",
};

export function ProductItinerary({
  meetingPoints,
  itineraries,
}: ProductItineraryProps) {
  const [meetingPointPlaces, setMeetingPointPlaces] = useState<PlaceType[]>([]);
  const [stepPlaces, setStepPlaces] = useState<PlaceType[]>([]);

  useEffect(() => {
    if (meetingPoints.length > 0) {
      const mapPlaces: PlaceType[] = [];
      meetingPoints.forEach((item, key) => {
        if (item.address?.latitude && item.address?.longitude) {
          mapPlaces.push({
            id: key + "_meetingPoint",
            name: item.name,
            lat: item.address?.latitude,
            lng: item.address?.longitude,
            type: "Address",
          });
        }
      });
      setMeetingPointPlaces(mapPlaces);
    }

    if (itineraries.length > 0) {
      const mapPlaces: PlaceType[] = [];
      itineraries.forEach((item, key) => {
        if (item.address?.latitude && item.address?.longitude) {
          mapPlaces.push({
            id: key + 1,
            name: item.name,
            description: item.description,
            lat: item.address?.latitude,
            lng: item.address?.longitude,
            type: "Step",
          });
        }
      });
      setStepPlaces(mapPlaces);
    }
  }, [meetingPoints, itineraries]);

  if (itineraries.length === 0) return <></>;

  return (
    <section id="itinerary">
      <h2 className="heading-md mb-6">Itinerary</h2>
      <Wrapper
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY as string}
        render={render}
        version="weekly"
        authReferrerPolicy="origin"
        libraries={["maps", "marker"]}
      >
        <MapComponent
          meetingPointPlaces={meetingPointPlaces}
          stepPlaces={stepPlaces}
        />
      </Wrapper>
    </section>
  );
}

interface MapComponentProps {
  meetingPointPlaces: PlaceType[];
  stepPlaces: PlaceType[];
}

function MapComponent({ meetingPointPlaces, stepPlaces }: MapComponentProps) {
  const [map, setMap] = useState<google.maps.Map>();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [showResetBtn, setShowResetBtn] = useState<boolean>(false);
  const [active, setActive] = useState<PlaceType | null>(null);

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    async function initMap() {
      const places = [...meetingPointPlaces, ...stepPlaces];

      if (!places || places?.length <= 0) return;
      if (!ref.current) return;

      const { Map } = (await google.maps.importLibrary(
        "maps",
      )) as google.maps.MapsLibrary;

      const map = new Map(ref.current as HTMLElement, {
        ...options,
      });

      const bounds = new google.maps.LatLngBounds();

      if (places.length > 0) {
        places.forEach((item) => {
          if (item.lat && item.lng) {
            bounds.extend(new google.maps.LatLng(item.lat, item.lng));
          }
        });
        map.fitBounds(bounds);
        setBounds(bounds);
      }

      setMap(map);
    }

    initMap();
  }, [ref, meetingPointPlaces, stepPlaces]);

  const handleClick = useCallback(
    (payload: PlaceType) => {
      if (payload.id === active?.id) {
        setActive(null);
        setShowResetBtn(false);
        map?.fitBounds(bounds as google.maps.LatLngBounds);
      }

      if (payload.id !== active?.id) {
        setActive(payload);
        map?.setZoom(18);
        map?.setCenter({
          lat: payload.lat,
          lng: payload.lng,
        });
        scrollToSection("itinerary");
      }
    },
    [active?.id, bounds, map],
  );

  const handleResetCenter = useCallback(() => {
    if (map) {
      setActive(null);
      setShowResetBtn(false);
      map.fitBounds(bounds as google.maps.LatLngBounds);
      setMap(map);
    }
  }, [bounds, map]);

  useEffect(() => {
    if (map) {
      map.addListener("zoom_changed", () => {
        if ((map.getZoom() as number) > 10 || (map.getZoom() as number) < 10) {
          setShowResetBtn(true);
        }
      });

      if (bounds) {
        map.addListener("dragend", () => {
          setShowResetBtn(true);
          if (map.getCenter()?.equals(bounds.getCenter())) {
            setShowResetBtn(false);
          }
        });
      }
    }
  }, [bounds, map]);

  return (
    <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
      <div className="mb-8 aspect-square h-full max-h-[400px] w-full overflow-hidden rounded-lg shadow-lg md:sticky md:top-6">
        {showResetBtn && (
          <Button
            radius="full"
            variant="solid"
            onClick={handleResetCenter}
            className="absolute left-0 right-0 top-4 z-10 mx-auto w-fit border-2 border-primary bg-white"
          >
            <ArrowPathIcon className="h-4 w-4" />
            Re-Center
          </Button>
        )}
        <div className="h-full grow" ref={ref as any} id="map" />
        {map && (
          <>
            {meetingPointPlaces?.map((place, index) => (
                <Marker
                  key={index}
                  map={map}
                  position={{
                    lat: place.lat as number,
                    lng: place.lng as number,
                  }}
                  onClick={(marker) => handleClick(place)}
                >
                  <LocationPin
                    icon={<MapPinIcon className="h-5 w-5" />}
                    label={place.name}
                    active={active?.id === place?.id}
                  />
                </Marker>
              ))}
            {stepPlaces?.map((place, index) => (
                <Marker
                  key={index}
                  map={map}
                  position={{
                    lat: place.lat as number,
                    lng: place.lng as number,
                  }}
                  onClick={(marker) => handleClick(place)}
                >
                  <LocationPin
                    icon={index + 1}
                    label={place.name}
                    active={active?.id === place?.id}
                  />
                </Marker>
              ))}
          </>
        )}
      </div>
      <div className="relative grid gap-6 overflow-hidden">
        <div className="flex gap-4">
          <div className="absolute left-[18px] top-0 z-10 h-full w-[3px] border-l-4 border-dotted border-primary/50" />
          <Button
            isIconOnly
            color="primary"
            className="relative z-20 rounded-full border-2 border-white"
            onClick={() => scrollToSection("meeting-point")}
          >
            <MapPinIcon className="h-5 w-5" />
          </Button>
          <div>
            <h4 className="font-semibold text-primary">
              You&apos;ll have {meetingPointPlaces?.length} starting options
            </h4>
            <p>Or, you can also get picked up</p>
            <Link
              color="primary"
              underline="always"
              size="sm"
              onClick={() => scrollToSection("meeting-point")}
            >
              See departure details
            </Link>
          </div>
        </div>
        {stepPlaces.map((place, key) => (
          <div key={key} className="flex gap-4">
            <Button
              isIconOnly
              color="primary"
              className="relative z-20 rounded-full border-2 border-white font-semibold text-white"
              onClick={() => handleClick(place)}
            >
              {key + 1}
            </Button>
            <div>
              <h4 className="font-semibold text-primary">{place.name}</h4>
              {place?.description && (
                <>
                  <p
                    className={clsx("text-sm", {
                      "line-clamp-1": active?.id !== key + 1,
                    })}
                  >
                    {place.description}
                  </p>
                  <Link
                    color="primary"
                    underline="always"
                    size="sm"
                    onClick={() => handleClick(place)}
                  >
                    {active?.id !== key + 1 ? READ_MORE : READ_LESS}
                  </Link>
                </>
              )}
            </div>
          </div>
        ))}
        <div className="flex gap-4">
          <Button
            isIconOnly
            color="primary"
            aria-label="Location Pin"
            className="relative z-20 rounded-full border-2 border-white"
            onClick={() => scrollToSection("meeting-point")}
          >
            <MapPinIcon className="h-5 w-5" />
          </Button>
          <div>
            <h4 className="mt-0">You&apos;ll return to the starting point</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
