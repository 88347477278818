import { PostTypeSeo } from "@/__generated__/graphql";
import { BreadCrumb, BreadCrumbItem } from "@/components/elements";
import { Layout } from "@/components/layout";
import { fragments } from "@/graphql/fragments";
import seoStringParser from "@/utils/seo-string-parser";
import blocks from "@/wp-blocks";
import { gql } from "@apollo/client";
import { WordPressBlocksViewer } from "@faustwp/blocks";
import { FaustTemplate, flatListToHierarchical } from "@faustwp/core";
import parse from "html-react-parser";
import Head from "next/head";

const Component: FaustTemplate<any> = ({ data, loading }) => {
  if (loading) {
    return <>Loading...</>;
  }

  const pageSettings = data?.page?.pageSettings,
    seo = data?.page?.seo as PostTypeSeo,
    fullHead = parse(seoStringParser(seo?.fullHead || "")),
    editorBlocks = data?.page?.editorBlocks;

  const breadcrumb: BreadCrumbItem[] = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: data?.page?.title as string,
      href: `/${data?.page?.slug}`,
    },
  ];

  const blockList = flatListToHierarchical(editorBlocks, {
    childrenKey: "innerBlocks",
  });

  return (
    <>
      <Head>
        <title>{data?.page?.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="robots"
          content={`${seo.metaRobotsNoindex}, ${seo.metaRobotsNofollow}`}
        />
        {seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}
        {fullHead}
      </Head>
      <Layout template="full-width">
        <article className="container mb-10">
          <BreadCrumb items={breadcrumb} />
          <div className="prose min-w-full prose-headings:mt-8 prose-headings:font-semibold prose-headings:text-primary">
            <WordPressBlocksViewer blocks={blockList} />
          </div>
        </article>
      </Layout>
    </>
  );
};

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${fragments.seo.POST_TYPE_SEO}
  ${blocks.CoreParagraph.fragments.entry}
  ${blocks.CoreColumns.fragments.entry}
  ${blocks.CoreColumn.fragments.entry}
  ${blocks.CoreCode.fragments.entry}
  ${blocks.CoreButtons.fragments.entry}
  ${blocks.CoreButton.fragments.entry}
  ${blocks.CoreQuote.fragments.entry}
  ${blocks.CoreImage.fragments.entry}
  ${blocks.CoreSeparator.fragments.entry}
  ${blocks.CoreList.fragments.entry}
  ${blocks.CoreHeading.fragments.entry}
  query GetPage($databaseId: ID!, $asPreview: Boolean = false) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      slug
      content
      pageSettings {
        bannerTitle
        bannerDescription
        bannerImage {
          id
          altText
          sourceUrl
        }
      }
      seo {
        ...SeoFields
      }
      editorBlocks(flat: true) {
        name
        __typename
        renderedHtml
        id: clientId
        parentId: parentClientId
        ...${blocks.CoreParagraph.fragments.key}
        ...${blocks.CoreColumns.fragments.key}
        ...${blocks.CoreColumn.fragments.key}
        ...${blocks.CoreCode.fragments.key}
        ...${blocks.CoreButtons.fragments.key}
        ...${blocks.CoreButton.fragments.key}
        ...${blocks.CoreQuote.fragments.key}
        ...${blocks.CoreImage.fragments.key}
        ...${blocks.CoreSeparator.fragments.key}
        ...${blocks.CoreList.fragments.key}
        ...${blocks.CoreHeading.fragments.key}
      }
    }
  }
`;

export default Component;
