export const priceFormat = (price: number) => {
  return new Intl.NumberFormat("th-TH", {
    style: "currency",
    currency: "THB",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(price);
};

export const priceFormatText = (price: number, currency: string): string => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formattedPrice = price?.toLocaleString("en-US", options);
  return formattedPrice + " " + currency.toUpperCase();
};
