export const SKIIP_LINK_SELECTORS = "main-content";
export const SEE_MORE = "See more";
export const READ_MORE = "Read more";
export const READ_LESS = "Read less";
export const LOAD_MORE = "Load more";
export const ACTIVITY = "Activity";
export const ACTIVITIES = "Activities";
export const NO_MORE_RESULTS = "No more results";
export const WRITE_REVIEW = "Write a review";
export const DEFAULT_PRICE_RANGE: [number, number] = [0, 7000];
export const CLEAR_SELECTION = "Clear selection";
export const PRICE_RANGE = "Price range";
export const RESET = "Reset";
export const CLEAR = "Clear";
export const FILTER = "Filter";
export const CHECK_AVAILABILITY = "Check availability";
