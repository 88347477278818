import { MenuItems } from "@/types";
import {
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import Image from "next/image";
import NextLink from "next/link";
import { LanguageSwitcher } from "../language-switcher/language-switcher";

export interface TopMenuProps {
  logo: string;
  menuItems: MenuItems[];
}

export function TopMenu({ logo, menuItems }: TopMenuProps) {
  return (
    <Navbar
      position="static"
      className="hidden sm:flex"
      classNames={{
        wrapper: "!container",
      }}
      isBordered
    >
      <NavbarBrand>
        <Link as={NextLink} href="/" className="text-2xl">
          <Image src={logo} width={120} height={38} alt="Logo" priority />
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden gap-8 sm:flex" justify="end">
        <NavbarItem>
          <LanguageSwitcher />
        </NavbarItem>
        {menuItems.map((item, index) => (
          <NavbarItem key={index}>
            <Link
              as={NextLink}
              color="foreground"
              href={item.link}
              className="text-xs font-medium"
            >
              {item.icon && <span className="mr-2">{item.icon}</span>}
              {item.title}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>
    </Navbar>
  );
}

export default TopMenu;
