import { ItineraryType, MeetingPointType, TourType } from "@/types";
import { scrollToSection } from "@/utils/scroll-to-section";
import { Button } from "@nextui-org/react";

import { MapPinIcon } from "@heroicons/react/24/solid";
import NextLink from "next/link";
import sanitizeHtml from "sanitize-html";

export interface ProductMeetingPointProps {
  tourType: TourType;
  meetingPoints: MeetingPointType[];
  itineraries: ItineraryType[];
  description: string;
}

export function ProductMeetingPoint({
  tourType,
  meetingPoints,
  itineraries,
  description,
}: ProductMeetingPointProps) {
  const sanitized = sanitizeHtml(description, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
  });

  return (
    <section id="meeting-point">
      <h2 className="heading-md mb-6">Meeting Point</h2>
      <div className="prose min-w-full">
        <div className="mb-4 grid gap-10">
          {(meetingPoints?.map((item, key) => (
              <div key={key} className="flex flex-wrap gap-4 sm:flex-nowrap">
                <div className="flex aspect-square h-[80px] w-[80px] items-center justify-center rounded-lg bg-primary p-4 shadow-lg shadow-primary/50 sm:m-8 sm:h-[100px] sm:w-[100px]">
                  <span className="scale-75 text-white sm:scale-100">
                    <MapPinIcon className="h-12 w-12" />
                  </span>
                </div>
                <div className="w-full">
                  <h3 className="mt-0 font-medium text-black">{item.name}</h3>
                  <p>{item.address?.streetAddress}</p>
                  {tourType === "attraction" && itineraries?.length > 0 && (
                    <Button
                      color="primary"
                      variant="solid"
                      onClick={() => scrollToSection("itinerary")}
                    >
                      View on map
                    </Button>
                  )}
                  {tourType === "attraction" && !itineraries && (
                    <OpenInGoogleMaps address={item.address?.streetAddress} />
                  )}
                  {tourType === "cooking" && (
                    <OpenInGoogleMaps address={item.address?.streetAddress} />
                  )}
                </div>
              </div>
            ))) || <></>}
        </div>
        <div dangerouslySetInnerHTML={{ __html: sanitized }} />
      </div>
    </section>
  );
}

function OpenInGoogleMaps({ address }: { address: string }) {
  return (
    <Button
      as={NextLink}
      className="no-underline"
      color="primary"
      variant="solid"
      href={`https://www.google.com/maps/search/?api=1&query=${address}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Open in Google Maps
    </Button>
  );
}

export default ProductMeetingPoint;
