import { Skeleton } from "@nextui-org/react";

export function ProductFilterLoader() {
  return (
    <div className="grid gap-6">
      <div>
        <div className="mb-4">
          <Skeleton className="w-2/5 rounded-lg">
            <div className="w-5/5 h-4 rounded-lg bg-default-200" />
          </Skeleton>
        </div>
        <div className="grid gap-3">
          {Array.from(Array(5).keys()).map((i) => (
            <CheckBoxLoader key={i} />
          ))}
        </div>
      </div>
      <div>
        <div className="mb-4">
          <Skeleton className="w-2/5 rounded-lg">
            <div className="w-5/5 h-4 rounded-lg bg-default-200" />
          </Skeleton>
        </div>
        <div className="grid gap-3">
          {Array.from(Array(5).keys()).map((i) => (
            <CheckBoxLoader key={i} />
          ))}
        </div>
      </div>
      <div>
        <div className="mb-4">
          <Skeleton className="w-2/5 rounded-lg">
            <div className="w-5/5 h-4 rounded-lg bg-default-200" />
          </Skeleton>
        </div>
        <div className="grid gap-3">
          {Array.from(Array(5).keys()).map((i) => (
            <CheckBoxLoader key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductFilterLoader;

function CheckBoxLoader() {
  return (
    <div className="flex gap-2">
      <Skeleton className="h-5 w-5 rounded-md">
        <div className="h-5 w-5 rounded-md bg-default-200" />
      </Skeleton>
      <Skeleton className="w-full rounded-md">
        <div className="w-fullrounded-md h-4 bg-default-200" />
      </Skeleton>
    </div>
  );
}
