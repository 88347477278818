import { FieldError, OptionSelectField } from "@/__generated__/graphql";
import { Select, SelectItem } from "@nextui-org/react";
import { useFormContext } from "react-hook-form";

export interface SelectFieldProps {
  field: OptionSelectField;
  fieldErrors: FieldError[];
}

export function SelectField({ field, fieldErrors }: SelectFieldProps) {
  const {
    id,
    type,
    label,
    description,
    isRequired,
    defaultValue,
    placeholder,
    choices,
  } = field;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputId = `field_${type}_${id}`,
    inputName = `field_${id}`,
    error = errors[inputName];

  return (
    <Select
      id={inputId}
      {...register(inputName, {
        required:
          (isRequired && `Please select ${label?.toLocaleLowerCase()}`) ||
          false,
      })}
      label={label}
      description={description || ""}
      placeholder={placeholder || ""}
      required={isRequired || false}
      defaultSelectedKeys={defaultValue?.split(",") || []}
      isInvalid={fieldErrors.length > 0 || error !== undefined}
      errorMessage={
        fieldErrors.map((error) => error.message).join(", ") ||
        (error?.message as string)
      }
    >
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        choices.map((choice) => (
          <SelectItem
            key={choice?.value as string}
            value={choice?.value as string}
          >
            {choice?.text}
          </SelectItem>
        ))
      }
    </Select>
  );
}

export default SelectField;
