import { READ_LESS, READ_MORE } from "@/constants";
import { Datum } from "@/types/travel-advisor-reviews";
import { formatDate } from "@/utils";
import { Card, CardBody, CardHeader, Link } from "@nextui-org/react";
import clsx from "clsx";
import Image from "next/image";
import { useState } from "react";
import { Rating } from "../rating/rating";

export interface ReviewItemProps {
  variant: "default" | "tripadvisor";
  review: Datum | any;
}

export function ReviewItem({ variant, review }: ReviewItemProps) {
  const [readMore, setReadMore] = useState<boolean>(false);

  return (
    <Card>
      <CardHeader className="p-4">
        <div className="flex items-center gap-2">
          <Image
            src={review?.user?.avatar?.large?.url}
            alt={review?.user?.username}
            width={40}
            height={40}
            className="rounded-full"
          />
          <div>
            <span className="block text-sm font-semibold leading-none">
              {review?.user?.username}
            </span>
            <span className="text-xs leading-none text-gray-400">
              {formatDate(review?.published_date)}
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-4 pt-0">
        <Rating
          variant={variant}
          rating={review?.rating}
          numReviews={review?.num_reviews}
          link={review?.web_url}
          size={89}
        />
        <h4
          className={clsx(
            "mb-1 mt-2 text-sm font-semibold text-black sm:text-base",
            {
              "line-clamp-1": !readMore,
            },
          )}
        >
          {review?.title}
        </h4>
        <p
          className={clsx("text-xs text-gray-500 sm:text-sm", {
            "line-clamp-3": !readMore,
          })}
        >
          {review?.text}
        </p>
        <Link
          href="#"
          underline="always"
          onClick={(event) => {
            event.preventDefault();
            setReadMore((prev) => !prev);
          }}
          className="mt-2 text-xs"
        >
          {readMore ? READ_LESS : READ_MORE}
        </Link>
      </CardBody>
    </Card>
  );
}

export default ReviewItem;
