import { FeaturedImage } from "@/types/featured-image";
import { MapPinIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";

export interface DestinationGalleryProps {
  title: string;
  images: FeaturedImage[];
}
export function DestinationGallery({ title, images }: DestinationGalleryProps) {
  return (
    <Swiper
      className="-my-8 !py-8"
      spaceBetween={16}
      slidesPerView={1.25}
      centeredSlides
      loop
      breakpoints={{
        768: {
          slidesPerView: 1.25,
          spaceBetween: 36,
        },
      }}
    >
      {images?.map((image, index) => (
          <SwiperSlide key={image.id} className="!h-auto">
            <div className="relative h-full overflow-hidden rounded-xl shadow-md transition-all duration-200 hover:shadow-xl">
              {image && (
                <Image
                  src={image.sourceUrl}
                  alt={image.altText}
                  width={1000}
                  height={251}
                  className="aspect-[8/9] h-full w-full object-cover sm:aspect-[16/7]"
                  priority={index <= 1 || index === images.length - 1}
                />
              )}
              <div className="absolute left-0 top-0 z-20 w-full p-6 sm:p-16">
                <h4 className="wrapper line-2 mb-2  text-2xl font-medium leading-tight text-white sm:text-4xl sm:leading-tight">
                  {title}
                </h4>
                <span className="block text-base font-light leading-snug text-white sm:text-xl">
                  Explore things to do, tips, guides in {title}
                </span>
              </div>
              <div className="absolute bottom-0 right-0 z-20 w-full p-6">
                <span className="flex items-center gap-2 text-sm leading-tight text-white sm:text-lg">
                  <MapPinIcon className="inline-block h-4 w-4" />{" "}
                  {image.altText}
                </span>
              </div>
              <div className="absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-b from-gray-900/70 to-transparent" />
              <div className="absolute bottom-0 left-0 z-10 h-1/4 w-full bg-gradient-to-b from-transparent to-gray-900/70" />
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  );
}
